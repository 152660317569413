<mat-card appearance="outlined" class="mat-elevation-z4" [matTooltip]="'Month to Date information about all New ' + (!referrerCRM() ? 'Referrer' : 'Contact') + 's compared to one month ago.'">
    <mat-card-header class="subText">
        <span *ngIf="!referrerCRM()">New Referrers (MTD)</span>
        <span *ngIf="referrerCRM()">New Contacts (MTD)</span>
    </mat-card-header>
    <mat-card-content (click)="openList()" matRipple>
        <div class="center" *ngIf="!loading">
            <mat-label>{{count}}</mat-label>
            <change-display [count]="count - prevMonth"></change-display>
        </div>
        <div class="center" *ngIf="loading">
            <load-spinner inline></load-spinner>
        </div>
    </mat-card-content>
</mat-card>