import { Injectable, Signal, WritableSignal, computed, signal } from "@angular/core";
import { Community, IUnit, OccupancyAggragate, Unit } from "../models/user.models";
import { ApiService } from "./api.service";

export interface OccupancySummary {
    bedCount:number;
    unitCount:number;
    residentCount:number;
    occupiedCount:number;
    vacancyCount:number;
    reservedCount:number;
    lockedCount:number;
    censusCount:number;
    aggregate?:OccupancyAggragate;
}

@Injectable()
export class OccupancyService {        
    loading = signal(false);
    units = signal([] as IUnit[]);
    aggregate:WritableSignal<OccupancyAggragate> = signal(this.emptyAggregate())
    monthlyAggregate:WritableSignal<OccupancyAggragate> = signal(this.emptyAggregate())
    yearlyAggregate:WritableSignal<OccupancyAggragate> = signal(this.emptyAggregate())
    occupancySummary:Signal<OccupancySummary> = computed(()=>{
        const units = this.units();
        if(units.length > 0){
            let occupied = this.getOccupiedUnits();
            let vacant = this.getVacantUnits();
            let locked = this.getLockedUnits();
            
            const occupiedSet = new Set(occupied.map(u=>u.name));
            const vacanctUnits = [...new Set(vacant.map(u=>u.name))].filter(u=>!occupiedSet.has(u));
            const lockedSet = new Set(locked.map(u=>u.name));
            // const resIds = new Set(occupied.map(u=>u.occupant).filter(p=>p. p.status == "Active"))
            
            return {
                bedCount:units.length,
                unitCount:occupiedSet.size + vacanctUnits.length + lockedSet.size,
                residentCount:0,
                occupiedCount: occupiedSet.size,
                vacancyCount:vacanctUnits.length,
                reservedCount:0,
                lockedCount:lockedSet.size,
                censusCount:occupied.length
            }
        }else{
            return {
                bedCount:0,
                unitCount:0,
                residentCount:0,
                occupiedCount:0,
                vacancyCount:0,
                reservedCount:0,
                lockedCount:0,
                censusCount:0
            }
        }
    })
    
    occupancyPct = computed(()=>{
        const summary = this.occupancySummary();
        return summary.occupiedCount / summary.unitCount * 100;
    })
    
    vacancyPct = computed(()=>{
        const summary = this.occupancySummary();
        return summary.vacancyCount / summary.unitCount * 100;
    })
    
    private isLocked(u:Unit){
        return u.snapshot && (u.snapshot.reservedOccupant || u.snapshot.locked);
    }
    
    getOccupiedUnits(){
        return this.units().filter(u=>u.snapshot && u.snapshot.occupant);
    }
    getLockedUnits(){
        return this.units().filter(u=>this.isLocked(u));
    }
    getVacantUnits(){
        return this.units().filter(u=>!(u.snapshot && u.snapshot.occupant) && !this.isLocked(u));
    }
    
    constructor(private api:ApiService) {
    }

    emptyAggregate():OccupancyAggragate {
        return {
            onAlert:0,
            onLeave:0,
            inRehab:0,
            deposits:0,
            hospitalized:0,
            moveIns:0,
            moveOuts:0,
            reservations:0,
            scheduledMoveIns:0,
            scheduledMoveOuts:0,
            onAlertMTD:0,
            onLeaveMTD:0,
            inRehabMTD:0,
            depositsMTD:0,
            hospitalizedMTD:0,
            moveInsMTD:0,
            moveOutsMTD:0,
            reservationsMTD:0,
            scheduledMoveInsMTD:0,
            scheduledMoveOutsMTD:0,
            censusCount:0,
            censusDayCount:0,
            censusToDate:0,
        }
    }
  
    sortRawUnits(arr:IUnit[]){
        arr.sort((a,b) => {
          const nameA = a.nameDisplay.toUpperCase();
          const nameB = b.nameDisplay.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }else if (nameA > nameB) {
            return 1;
          }else{
            return 0;
          }
        });
      }
  
    async updateUnits(community:Community|null, date:Date, silent:boolean = false){
        if(!silent){
            this.loading.set(true);
        }
        let data = await this.api.get('occupancy', {community:community?.communityId, date:this.formatDate(date)} as any)
        if(data?.message == null){
            let units = data.units.map((u:IUnit) => new Unit(u));
            this.sortRawUnits(units);
            this.units.set(units);
        }else{
            this.units.set([]);
        }
        if(data.aggregate){
            this.aggregate.set(data.aggregate);
        }else{
            this.aggregate.set(this.emptyAggregate());
        }
        if(data.monthlyAggregate){
            this.monthlyAggregate.set(data.monthlyAggregate);
        }else{
            this.monthlyAggregate.set(this.emptyAggregate());
        }
        if(data.yearlyAggregate){
            this.yearlyAggregate.set(data.yearlyAggregate);
        }else{
            this.yearlyAggregate.set(this.emptyAggregate());
        }
        this.loading.set(false);
    }
    
    formatDate = (d:Date|string):string => {
        if(typeof d === 'string' || d instanceof String){
            return d as string;
        }else{
            let month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
    
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
    
            return [year, month, day].join('-');
        }
    }
}