
<h1 mat-dialog-title class="flex-row flex-wrap">
    <div class="mr-auto" *ngIf="!eventMode">Send Email</div>
    <div class="mr-auto" *ngIf="eventMode">Send Invites</div>
    <div class="template-select" *ngIf="(selectTemplate && selectTemplate.length > 0) && data.email == null">
        <chip-autocomplete 
            placeholder="Choose Template"
            idField="templateId"
            emphasisField="name"
            displayField="type"
            [fullData]="selectTemplate"
            [selectedData]="selectedTemplate"
            [isSingle]="true"
            (onChange)="changeTemplate($event)">
        </chip-autocomplete>
    </div>
</h1>
<mat-dialog-content>
    <form [formGroup]="form" class="flex-form">
        <div class="form-row expanded-fill-row">
            <span class="fill-row" *ngIf="!loading && !eventMode">
                <chip-autocomplete 
                    placeholder="Choose recipients"
                    idField="personId"
                    emphasisField="name"
                    displayField="addressDisp"
                    [fullData]="selectPeople"
                    [selectedData]="people"
                    (onChange)="changeAutocomplete(form, 'to', $event)">
                </chip-autocomplete>
            </span>

            <button mat-flat-button class="light-text" (click)="showCC=!showCC" *ngIf="ccSelectPeople.length > 0 && !nurseMode && !eventMode" [class.mat-elevation-z2]="!showCC">Cc</button> 
        </div> 
        <div class="form-row expanded-fill-row" *ngIf="showCC && ccSelectPeople.length > 0 && !nurseMode && !eventMode">
            <span class="fill-row">
                <chip-autocomplete 
                    placeholder="Choose recipients"
                    idField="personId"
                    emphasisField="name"
                    displayField="addressDisp"
                    [fullData]="ccSelectPeople"
                    [selectedData]="ccPeople"
                    (onChange)="changeAutocomplete(form, 'cc', $event)">
                </chip-autocomplete>
            </span>
        </div> 
    
        <!-- <mat-form-field>
            <input matInput placeholder="To" type="text" formControlName="to" />
        </mat-form-field> -->
        <mat-form-field>
            <input matInput placeholder="Subject" type="text" formControlName="subject"  autofocus cdkFocusInitial/>
        </mat-form-field>
        <div class="ql-container">
            <input #emailAttach class="ng-hide" style="display:none;"  type="file"  multiple
                id="emailAttach" (change)="getFiles($event)" />
            <email-quill-editor (onMarketingPicked)="addMarketingFiles($event)" customToolbarPosition="bottom" formControlName="body"></email-quill-editor>
        </div>
        
        <mat-chip-listbox>
            <mat-chip-option *ngFor="let file of attachments; index as i" (removed)="removeAttachment(i)">
                <span>
                    {{file.name}} ({{file.size | filesize}})
                </span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
        </mat-chip-listbox>

        <mat-chip-listbox>
            <mat-chip-option *ngFor="let file of templateAttachments; index as i" (removed)="removeTemplateAttachment(i)">
                <span>
                    {{file.filename}}
                </span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
        </mat-chip-listbox>

        <mat-chip-listbox>
            <mat-chip-option *ngFor="let file of marketingAttachments; index as i" (removed)="removeMarketingAttachment(i)">
                <span>
                    {{file.title}}
                </span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
        </mat-chip-listbox>
    </form>
    <div [innerHTML]="signature.message" *ngIf="signature?.message"></div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
    <button *ngIf="!submitting" mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
    <button *ngIf="!submitting && !eventMode" class="mat-elevation-z2 fa-icon-fab" mat-mini-fab (click)="sendEmail()" [disabled]="!form.valid || submitting || oauthStatus.length == 0" matTooltip="Send" ><fa-icon [icon]="sendIcon"></fa-icon></button>
    <button *ngIf="!submitting && eventMode" class="mat-elevation-z2 fa-icon-fab" mat-mini-fab (click)="sendEventEmail()" [disabled]="!form.valid || submitting" matTooltip="Send" ><fa-icon [icon]="sendIcon"></fa-icon></button>
    <load-spinner inline *ngIf="submitting"></load-spinner>
</div>