import { AfterContentInit, Component, ElementRef, ViewChild } from '@angular/core';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Community, Event, MarketingMaterial, ReferrerCommunity, ReferrerStaff, User } from '../../models/user.models';
import { ApiService } from '../../services/api.service';
import { PersonService } from '../../services/person.service';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormService } from '../../services/form.service';
import { ExpandableWidget } from '../helpers/expandablewidget.component';
import { AppService } from '../../services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Address, Email, Phone, PhotoLink } from '../../models/base.models';
import { MarketingFileType } from '../../models/lookuptable.models';
import { urlValidator } from '../../validators/url.validator';
import { MatDialog } from '@angular/material/dialog';
import { FileUpload } from '../../components/file-upload/file-upload.component';
import { first, take, takeLast } from 'rxjs';
import { formatDate } from '@angular/common';
import { DataMapper } from '../../models/datamapper';
import { CompanyCfgService } from '../../services/company-cfg.service';

@UntilDestroy()
@Component({
  selector: 'event-widget',
  templateUrl: './event.widget.html',
  styleUrls: ['../../../form-styles.scss', './event.widget.scss']
})
export class EventWidget extends ExpandableWidget implements AfterContentInit {

  public user!:User;

  selectedId:string|null = null;
  event:Event;
  address:Address;

  timezones: string[] = this.lists.time_zones;

  submitting: boolean = false;
  
  eventGroup: FormGroup;
  
  selectedCommunity:Community|null = null;
    
  allowAsAttachment:boolean = false;
  addToAll:boolean = false;
  
  pageCache:number|undefined = undefined;
  
  allUsers:User[] = [];

  constructor(
    protected override app:AppService,
    protected override route:ActivatedRoute, 
    protected override router:Router,
    private personService:PersonService,
    private companyCfg:CompanyCfgService,
    private api:ApiService, 
    private dialog:MatDialog,
    protected snackbar: MatSnackBar, 
    private formBuilder:FormBuilder, 
    public lists:FormService,
  ) {
    super(app, route, router);
    this.event = new Event();
    this.address = Object.assign({}, this.event.address);

    this.eventGroup = this.buildEmptyGroup();
    this.eventGroup.controls['startTime'].valueChanges.subscribe(value=>{
      if(this.eventGroup.controls['endTime'].pristine){
        const startTime = value.split(":");
        let endDate = new Date();
        endDate.setHours(startTime[0], startTime[1], 0, 0);
        endDate.setHours(endDate.getHours() + 1);
        this.eventGroup.controls['endTime'].setValue(this.getTimeAsString(new Date(endDate), ""));
      }
    });

    route.queryParams.pipe(untilDestroyed(this)).subscribe(async (p:any) => {
      if(p.id){
        this.selectedId = p.id;
        let result = Event.fromJson(await this.api.get(`events/${p.id}`));
        if(result){
          this.event = result;
          if (this.event != undefined && this.event != null && 
            this.event.address.timezone != undefined && this.event.address.timezone != null && this.event.address.timezone.startsWith("US/")) {
              this.event.address.timezone = this.event.address.timezone.substring(3);
          }

          this.address = Object.assign({}, this.event.address);
        }
        this.updateBuildGroup(this.event, this.eventGroup);
      }
      else {
        this.personService.currentCommunity.pipe(first()).subscribe(async community=>{
          if(community && !this.selectedCommunity){
            this.selectedCommunity = community;        
            let cleanTz = this.selectedCommunity.timezone;
            if (cleanTz != undefined && cleanTz != null && cleanTz.startsWith("US/")) {
              cleanTz = cleanTz.substring(3);
            }
            let address = {is_primary:true, 
                            address_1:this.selectedCommunity.communityAddress1, 
                            address_2:this.selectedCommunity.communityAddress2, 
                            city:this.selectedCommunity.communityCity,
                            state:this.selectedCommunity.communityState,
                            zip:this.selectedCommunity.communityZip,
                            timezone:cleanTz,
                            deleted:false
                          };
            this.address = Object.assign({}, address);
          }
        });
      }
    });

    this.personService.currentUser.pipe(untilDestroyed(this)).subscribe(u=>{
      if(u){
        this.user = u;
      }
    });

    let getUserList = (e:Event|null) => {
      if(e){        
        let communityCrm = this.companyCfg.communityCRM();
        let callback;

        callback = this.api.post("user/sales-conselors");

        callback.then(users=>{
          this.allUsers = users.map((u:any)=>DataMapper.userFromData(u));
          
          // If the logged in user was found in this list of returned sales counselors, then preselect them if the value is not set yet.
          if(this.allUsers != null && this.allUsers != undefined && this.allUsers.length > 0 && 
             this.user != null && this.user != undefined && this.user.personId != null &&  this.user.personId != undefined && this.user.personId != "") {
            this.event.salesCounselor = this.user;
            // for (var i = 0; i < this.allUsers.length; i++) {
            //   if(this.allUsers[i].personId == this.user.personId && (!this.userCtrl.value || this.userCtrl.value.trim() == "")){
            //     this.userCtrl.setValue(this.user.personId);
            //     break;
            //   }
            // }
          }
    
          // this.activityCtrl.setValue(values.salesActivity.guid);
        })
      }
    }
    
    getUserList(this.event);
  }

  async ngAfterContentInit() {
    let promises:Promise<any>[] = [];
    try{
      // promises.push(this.api.getLookupTable("marketing_file_type", false));
    
      // let results = await Promise.all(promises);
    
      // this.selectFileType = results[0];
      // if(!this.eventGroup.controls["type"].value){
      //   this.eventGroup.controls["type"].setValue(this.selectFileType[0]?.guid)
      // }
    }catch(e){
      console.error(e);
    }
    // this.selectProperties(this.selectedForm);
  }
  
  buildEmptyGroup() {
    return this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(128)]],
      host: [null, [Validators.required]],
      eventDate: ["", [Validators.required]],
      startTime: ["", [Validators.required]],
      endTime: ["", [Validators.required]],
      budget: ["", [Validators.required]],
      actualCost: [""],
      location: ["", [Validators.maxLength(128)]],
      description: ["", [Validators.maxLength(4096)]],
    });
  }
  
  updateBuildGroup(item: Event, group:FormGroup) {
    let startTime = null;
    let endTime = null;
    
    if (item.startTime !=  null) {
      startTime = this.getTimeAsString(new Date(item.startTime), item.timeOnlyStartDisplay);
    }
    if (item.endTime !=  null) {
      endTime = this.getTimeAsString(new Date(item.endTime), item.timeOnlyEndDisplay);
    }
    if (endTime != "") {
      group.controls['endTime'].markAsDirty();
    }
    // group.controls[];
    group.controls['name'].setValue(item.name);
    group.controls['host'].setValue(item.salesCounselor?.personId);
    group.controls['eventDate'].setValue(item.eventDate);
    group.controls['startTime'].setValue(startTime);
    group.controls['endTime'].setValue(endTime);
    group.controls['budget'].setValue(item.budget);
    group.controls['actualCost'].setValue(item.actualCost);
    group.controls['location'].setValue(item.location);
    group.controls['description'].setValue(item.description);
  }

  getTimeAsString(date: Date, timeOnlyDisplay:string|null) {
    if (timeOnlyDisplay != null && timeOnlyDisplay != undefined && timeOnlyDisplay.length > 0 && timeOnlyDisplay.indexOf(":") > -1) {
      const removeAmPM = timeOnlyDisplay.replace(" AM", "").replace(" PM", "");
      const time = removeAmPM.split(":");
      const hours = parseInt(time[0]);
      let hoursToAddForPm = 0;
      if(timeOnlyDisplay.indexOf("PM") > -1 && hours != 12){
        hoursToAddForPm = 12;
      }else if(timeOnlyDisplay.indexOf("AM") > -1 && hours == 12){
        hoursToAddForPm = -12;
      }
      date.setHours(hours + hoursToAddForPm, parseInt(time[1]), 0, 0);
      return `${(date.getHours() < 10 ? "0" : "") + date.getHours()}:${(date.getMinutes() < 10 ? "0" : "") + date.getMinutes()}`;
    } else {
      return `${(date.getHours() < 10 ? "0" : "") + date.getHours()}:${(date.getMinutes() < 10 ? "0" : "") + date.getMinutes()}`;
    }
  }

  formatDate(date: Date, timezone: string) {
    if(date == null){
      return "N/A";
    }else{
      return formatDate(date, 'MM/dd/yyyy HH:mm', "en-US", timezone);
    }
  }


  saveEvent = async () =>  {
    if (!this.personService.hasSomePermission(['events:add/update'])) {
      return;
    }

    let badAddr = false;
    if (this.address.address_1 == undefined || this.address.address_1 == null || this.address.address_1.trim().length == 0) {
      badAddr = true;
    }
    if (this.address.city == undefined || this.address.city == null || this.address.city.trim().length == 0) {
      badAddr = true;
    }
    if (this.address.state == undefined || this.address.state == null || this.address.state.trim().length == 0) {
      badAddr = true;
    }
    if (this.address.zip == undefined || this.address.zip == null || this.address.zip.trim().length == 0) {
      badAddr = true;
    }
    if (this.address.timezone == undefined || this.address.timezone == null || this.address.timezone.trim().length == 0) {
      badAddr = true;
    }

    if (badAddr) {
      this.snackbar.open("Invalid address");
      return;
    }
    
    let item:any = new Event(this.event) as any;
    
    item.name = this.eventGroup.controls['name'].value;
    
    item.budget = this.eventGroup.controls['budget'].value;
    item.actualCost = this.eventGroup.controls['actualCost'].value;

    item.community = this.selectedCommunity;
    item.communityId = this.selectedCommunity?.communityId;

    let startDate = new Date(this.eventGroup.controls['eventDate'].value);
    let endDate = new Date(this.eventGroup.controls['eventDate'].value);
    const startTime = this.eventGroup.controls['startTime'].value.split(":");
    const endTime = this.eventGroup.controls['endTime'].value.split(":");
    startDate.setHours(startTime[0], startTime[1], 0, 0,)
    endDate.setHours(endTime[0], endTime[1], 0, 0,)
    let startDateTransport = formatDate(startDate, 'MM/dd/yyyy HH:mm', "en-US");
    let endDateTransport = formatDate(endDate, 'MM/dd/yyyy HH:mm', "en-US");

    item.eventDate = new Date(this.eventGroup.controls['eventDate'].value).getTime();
    item.startTime = startDate.getTime();
    item.endTime = endDate.getTime();
    item.startTimeTransport = startDateTransport;
    item.endTimeTransport = endDateTransport;

    item.address1 = this.address.address_1;
    item.address2 = this.address.address_2;
    item.city = this.address.city;
    item.state = this.address.state;
    item.zip = this.address.zip;
    item.timezone = this.address.timezone;

    item.salesCounselor = {};
    item.salesCounselor.personId =  this.eventGroup.controls['host'].value;

    item.location = this.eventGroup.controls['location'].value;
    item.description = this.eventGroup.controls['description'].value;


    //empty these before sending to server.
    item.creationDate = null;
    item.modifiedDate = null;

    let newRecord = !item.eventId;

    this.submitting = true;
    try{
      let newItem = await this.api.post(`events`, item);
      this.selectedId = newItem.eventId;
      newItem.pageNumber = item.pageNumber;
      this.personService.selectedEvent.next(newItem);
      this.submitting = false;
      this.closeWidget();
    }catch(e){
      this.submitting = false;
      console.error(e);
      this.snackbar.open("Failure saving Event. Please try again later");
    }
  }
}

