<mat-dialog-content>
    <div>
        <button mat-flat-button color="primary" (click)="data.filesystemCallback(); onCancel()">Attach From File System <fa-icon [icon]="linkIcon"></fa-icon></button>
    </div>
</mat-dialog-content>
<div class="less-pad">
    <h1 mat-dialog-title class="flex-row flex-wrap">
        <div class="mr-auto">Attach From Marketing Materials</div>
    </h1>
    <mat-dialog-content>
        <span class="flex-row search-row">
            <search-input [(filter)]="requestData" [matTooltip]="'Search for the title of the Marketing Material.'" [autoFocus]="true"></search-input>
        </span>
    </mat-dialog-content>
    <mat-dialog-content class="less-top">
        <div>
            <mat-label *ngIf="rawData.length <= 0">No Marketing Materials exist that match your search.</mat-label>
            <div infiniteScroll [onScroll]="onScroll" [filter]="requestData()">
                <load-spinner variant="table"></load-spinner>
                <mat-selection-list #materialList>
                    @for(material of rawData; track trackMarketingMaterial){
                        <mat-list-option [value]="material">
                            {{material.title}}
                        </mat-list-option>
                    }
                </mat-selection-list>
            </div>
        </div>
    </mat-dialog-content>
</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close (click)="onCancel()">Cancel</button>
    <button mat-button mat-dialog-close [disabled]="materialList.selectedOptions.selected.length <= 0" (click)="onSubmit()">{{submitText}}</button>
</div>