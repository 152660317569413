import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from 'src/app/core/directives/auth.guard';
import { UserListWidget } from './userslist.component';
import { UsersComponent } from './users.component';



const routes:Routes = [
  {
    path:'', component: UserListWidget, 
    children: [
      {
        path:'',
        component: UserListWidget, 
      },
      {
        path:'edit',
        component: UserListWidget
      }
    ]

  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }