import { Component, input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'icon-badge',
  template: `<div><fa-icon [icon]="icon()" [class]="'color-'+color()"></fa-icon></div>`,
  styleUrls: ['./icon-badge.component.scss']
})
export class IconBadgeComponent{
  icon = input.required<IconProp>();
  color = input<"accent"|"primary"|"warn"|"danger">("primary");
}