import { E } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { AbstractCommunity, Community, Prospect, User } from '../../models/user.models';
import { PersonService } from '../../services/person.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataMapper } from '../../models/datamapper';
import { CompanyCfgService } from '../../services/company-cfg.service';

export interface AssignProspectDialogCfg{
    prospects:Prospect[],
    ids?:string[],
}

@UntilDestroy()
@Component({
    selector: 'prospect-assign-bulk-dialog.component',
    templateUrl: 'prospect-assign-bulk-dialog.component.html',
    styleUrls: ['prospect-assign-bulk-dialog.component.scss']
})
export class AssignProspectBulkDialogComponent implements OnInit {
    public users:User[] = [];
    public currentUser!:User;
    public selectedUser:User|null = null;

    public prospects!:Prospect[];
    mode:"unassigned"|"me"|"other" = "unassigned";

    prospectCommunities:AbstractCommunity[] = [];
    
    constructor(
        public dialogRef: MatDialogRef<AssignProspectBulkDialogComponent>, 
        private api:ApiService, 
        private personService:PersonService, 
        private snackbar:MatSnackBar,
        private companyCfg:CompanyCfgService,
        @Inject(MAT_DIALOG_DATA) public data: AssignProspectDialogCfg) {
        this.prospects = data.prospects;
        var foundCommunities:any = {};
        this.prospectCommunities = this.prospects.map(p=>[...p.communitiesOfInterest]).flat().filter(c=>{
            return foundCommunities.hasOwnProperty(c.id) ? false : (foundCommunities[c.id] = true);
        });
        this.personService.currentUser.pipe(untilDestroyed(this)).subscribe(u=>{
            if(u != null){
                this.currentUser = u;
                
                // for(let p of this.prospects){
                //     if(p.assignedUserId !== this.currentUser.personId && p.assignedUserId !== null){
                //         this.dialogRef.close();
                //         this.snackbar.open("One or more selected prospects cannot be assigned by you.")
                //     }
                // }
            }
        })
    }
    
    async ngOnInit(): Promise<void> {
        let users;
        if(this.companyCfg.communityCRM()){
          users = await this.api.post("user/by-communities-active", {ids:this.prospectCommunities.map(c=>c.id)});
        }else{
          users = await this.api.post(`user/sales-conselors`);
        }
        
        this.users = users.map((u:any)=>DataMapper.userFromData(u));
        let me = this.users.find(u=>u.personId == this.currentUser.personId);
        if(me != null){
            this.selectedUser = me;
        }
    }

    onCancel(): void {
        this.dialogRef.close("cancel");
    }

    async onSubmit() {
        var saveObject:any = {};
        await this.api.post("prospect/assign", {
            ids:this.data.ids ? this.data.ids : this.prospects.map(p=>p.personId), 
            value:this.selectedUser ? this.selectedUser.personId : null
        });
        if(this.selectedUser){
            this.snackbar.open(`Prospects assigned to ${this.selectedUser.nameDisplay}.`);
        }else{
            this.snackbar.open(`Prospect assignments were cleared.`);
        }
        
        return "success";
    }

    
    isValid() {
        let valid = false;

        if(this.selectedUser != null && this.selectedUser != undefined && this.selectedUser.personId != null && this.selectedUser.personId != undefined && this.selectedUser.personId != ""){
            valid = true;
        }
        
        return valid;
    }
}