import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { PersonService } from './core/services/person.service';
import { ActivatedRoute, ActivationStart, Router, UrlSegment } from '@angular/router';
import { Subscription, first } from 'rxjs';
import { User } from './core/models/user.models';
import { ApiService } from './core/services/api.service';
import { UpdateService } from './core/services/swupdate.service';
import { SessionService } from './core/services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'MR-CRM';
  routeChange!:Subscription;

  constructor (private auth:AuthService, private personService:PersonService, 
      private router:Router, 
      private route:ActivatedRoute, 
      private authService:AuthService,
      private apiService:ApiService,
      private updateService: UpdateService,
      private sessionService: SessionService){
    
      this.routeChange = route.url.subscribe(r=>{
        if(r.length == 0){
          this.router.navigate(['prospect']);
        }        
      })

      // this.auth.authenticate(undefined, (resp:any) => {
      //   if (authService.authenticated){
      //     if(!router || !router.url || router.url == "" || router.url == "/"){
      //       this.router.navigate(['prospect']);
      //     }
          
      //   } else
      //     window.location.href = environment.LOGIN_URL;
      // });
  }
  
  async ngOnInit() {
    this.router.events.subscribe(data => {
      if (data instanceof ActivationStart) {
        let d = data.snapshot.data;
        if(!d['noAuth'] && !this.apiService.hasUser){
          this.apiService.userInfo().pipe(first()).subscribe((user: User | null) => {
            if(user){
              const usr = new User();
              usr.firstname = user.firstname;
              usr.lastname = user.lastname;
              usr.emailAddresses = user.emailAddresses;
              usr.company = user.company;
              usr.personId = user.personId;
              usr.authorities = user.authorities;
              usr.superadmin = user.superadmin;
              usr.homepage = user.homepage;
              usr.userTimezone = user.userTimezone;
              this.personService.currentUser.next(usr);
              this.personService.user.set(usr);
            }else{
              this.personService.currentUser.next(null);
              this.personService.user.set(null);
            }
          });
        }
      }
    });
    
  }

  logout() { this.authService.logoutv2(); }
  reload() { window.location.reload(); }
  clearStorage() { localStorage.clear(); }

}
