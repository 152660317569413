/* eslint-disable brace-style */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { PersonService } from './person.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(private http: HttpClient, private router: Router, private personService: PersonService) {
  }

    public logoutv2() {
      console.log('Logging out v2....')
      let headers = new HttpHeaders();
      // check because of old browsers
      if (Intl && Intl.DateTimeFormat() && Intl.DateTimeFormat().resolvedOptions()) {
        const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
        headers = headers.append('x-user-timezone', timeZone);
      }
  
      const requestOptions = {
        body: {},
        observe: 'response' as 'response',
        headers: headers,
        params: new HttpParams(),
        responseType: 'json' as 'json',
        withCredentials: true
      };
      this.http.request('POST', 'logout', requestOptions).subscribe((res: HttpResponse<any>) => {
        console.log('complete logout response ', res)
        let logoutUri = res.headers.get('Location') || '';
        if (logoutUri) {
          window.location.href = logoutUri;
        } else {
          console.warn(`Something went wrong. There is no location header in the response`)
        }
        console.log(logoutUri)
      });
    }
}


