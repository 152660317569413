
@if(display){
    <span [ngClass]="getColor(getId(display))" class="icon" [matTooltip]="display.nameDisplay">
        <span>{{initials()}}</span>
    </span>
    @if(showName){
        <span class="name" [matTooltip]="display.nameDisplay">{{display.nameDisplay}}</span>
    }
}
@else {
    <span class="icon">
        <span></span>
    </span>
    @if(showName){
        <span class="name">Unknown</span>
    }
}