<form [formGroup]="addressGroup" class="flex-form">
    <span>     
        <!-- <mat-menu #addressSelect="matMenu">
            <button mat-menu-item *ngFor="let suggestion of smartySearch" (click)="selectAddress(suggestion, 1)">{{suggestion.street_line}} {{suggestion.city}} {{suggestion.state}}</button>
        </mat-menu> -->
        
        <div *ngFor="let address of data; let last = last; let index = index" class="addEntryRow">
            <div>
                <span class="entryContent addressContent">
                    <div class="addressline1">
                        <mat-form-field ngDefaultControl appearance="fill">
                            <mat-label>Address 1</mat-label>
                            <input type="text" name="address_1" matInput placeholder="Address 1" [matAutocomplete]="autoAddress1" formControlName="address_1{{index}}" (keyup)="changeAddress1(index)">
                        </mat-form-field>
                        <mat-autocomplete #autoAddress1="matAutocomplete" (optionSelected)="selectAddress($event)">
                            <mat-option *ngFor="let suggestion of smartySearch" [value]="suggestion">
                                <span>
                                    {{buildAddressDisplay(suggestion)}}
                                </span>
                            </mat-option>
                        </mat-autocomplete>
                        <!-- <mat-form-field ngDefaultControl>
                            <input type="text" matInput placeholder="Address 1" formControlName="address_1{{index}}" (keyup)="changeAddress1(index)">
                        </mat-form-field>
                        <div style="pointer-events: none;" [matMenuTriggerFor]="addressSelect" #address1Trigger="matMenuTrigger"></div> -->
                        <!-- <mat-list *ngIf="smartySearch != null && smartySearch.length > 0" class="mat-elevation-z4">
                            <mat-list-item *ngFor="let suggestion of smartySearch" (click)="selectAddress(suggestion, index)">{{suggestion.street_line}} {{suggestion.city}} {{suggestion.state}}</mat-list-item>
                        </mat-list> -->
                    </div>
                    <mat-form-field ngDefaultControl class="addressline2" appearance="fill">
                        <mat-label>Address 2</mat-label>
                        <input type="text" name="address_2" matInput placeholder="Address 2" formControlName="address_2{{index}}" (keyup)="changeAddress2(index)">
                    </mat-form-field>
                    <mat-form-field ngDefaultControl class="addresszip" appearance="fill">
                        <mat-label>Zip</mat-label>
                        <input type="text" name="zip" matInput placeholder="Zip" formControlName="address_zip{{index}}" (keyup)="changeAddressZip(index)">
                    </mat-form-field>
                    <mat-form-field ngDefaultControl class="addresscity" appearance="fill">
                        <mat-label>City</mat-label>
                        <input type="text" name="city" matInput placeholder="City" formControlName="address_city{{index}}" (keyup)="changeAddressCity(index)">
                    </mat-form-field>
                    <mat-form-field ngDefaultControl class="addressstate" appearance="fill">
                        <mat-label>State</mat-label>
                        <input type="text" name="state" matInput placeholder="State" formControlName="address_state{{index}}" (keyup)="changeAddressState(index)">
                    </mat-form-field>
                    <div class="addresstimezone">
                        <mat-form-field ngDefaultControl appearance="fill">
                            <mat-label>Time Zone</mat-label>
                            <mat-select formControlName="address_timezone{{index}}" (selectionChange)="changeTimeZone(index)">
                                <mat-option *ngFor="let option of timeZoneList" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </span>
            </div>
            <fa-icon *ngIf="!disabled && !forSingle" [icon]="starIcon" [class.primary]="address.is_primary" (clickOrKey)="changePrimary(address)" matTooltip="Make Primary" matTooltipPosition="above"></fa-icon>
            <fa-icon *ngIf="disabled && !forSingle" [icon]="starIcon" class="primary" matTooltip="Primary" matTooltipPosition="above"></fa-icon>
            <fa-icon *ngIf="!disabled && !forSingle" [icon]="deleteIcon" (clickOrKey)="removeItem(address)" matTooltip="Remove Address"></fa-icon>
        </div>
        <div class="emptyEntryRow" *ngIf="data.length === 0">
            <mat-label>No address on file</mat-label>
        </div>
        <floating-add-button (clickOrKey)="addAddress()" class="nestedFormAdd" *ngIf="!disabled && !forSingle"></floating-add-button>
    </span>
</form>