<mat-card appearance="outlined" class="mat-elevation-z4" [matTooltip]="tooltipText()">
    <mat-card-header class="subText">
        {{temperatureDisplay()}} Prospects
    </mat-card-header>
    <mat-card-content (click)="openList()" matRipple>
        @if(!loading()){
            <div echarts [options]="chartCfg" theme="hotCold"></div>
            <div class="centerAbsolute overChart">
                <mat-label>{{top}}</mat-label>
            </div>
        }@else {
            <div class="center">
                <load-spinner inline></load-spinner>
            </div>
        }
    </mat-card-content>
</mat-card>