import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { faEnvelope, faMessage, faPhone, faStar } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PersonService } from '../../services/person.service';
import { ContactService } from '../../services/communication.service';
import { Email, Phone } from '../../models/base.models';
import { AbstractCommunity, Community, ReferrerCommunity } from '../../models/user.models';
import { ChatDialogComponent } from '../../dialog/chat-dialog/chat-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EmailSendComponent } from '../../dialog/email-send/email-send.component';

@Component({
  selector: 'community-contact',
  templateUrl: './community-contact.component.html',
  styleUrls: ['./person-contact.component.scss']
})
export class CommunityContactComponent implements OnChanges {
  @Input() community!:AbstractCommunity;
  @Input() type!:"Referrer"|"Community";
  @Input() size:"small"|"mid"|"large" = "small";
  @Input() contained?:any|undefined;

  starIcon = faStar;
  emailIcon = faEnvelope;
  messageIcon = faMessage;
  phoneIcon = faPhone;

  phones:{community:AbstractCommunity, phones:Phone[]}[] = [];
  mobiles:{community:AbstractCommunity, phones:Phone[]}[] = [];
  emails:{community:AbstractCommunity, emails:Email[]}[] = [];
  
  constructor(
    private snackbar:MatSnackBar, 
    private personService:PersonService, 
    private communication:ContactService, 
    private dialog:MatDialog){
    
  }
  
  // comingSoon(){
  //   this.snackbar.open("Coming Soon");
  // }
  
  selectPhone(community:AbstractCommunity, phone:Phone){
    // if(this.refCom instanceof ReferrerCommunity){
    //   this.personService.selectedReferrerCommunity.next(this.refCom);
    //   this.communication.selectedMobile.next({contact:refCom, phone:phone, itemType: "Community"});
    // }
    // this.comingSoon();

    if(this.type == "Community"){
      // this.personService.selectedCommunity.next(this.community as Community);
    }else{
      this.personService.selectedReferrerCommunity.next(this.community as unknown as ReferrerCommunity);
    }
      this.communication.selectedMobile.next({contact:community, phone:phone, itemType: "Referrer Community"});
      let d = this.dialog.open(ChatDialogComponent,
        { data: {contact:community, phone:phone, itemType: "Community"},
          disableClose: true, 
               width:"calc(100vw - 40px)",
               maxWidth:"900px"},
               );
  
      // On dialog close, update the First/Last Contact columns by grabbing the data.
      d.afterClosed().subscribe(result=>{
          // Not sure how to check if text sent here, since the modal closing doens't return anything. Reload data instead.
          
      });
  }

  sendEmail(community:AbstractCommunity, email:Email){
    if(this.type == "Community"){
      // this.personService.selectedCommunity.next(this.community as Community);
    }else{
      this.personService.selectedReferrerCommunity.next(this.community as unknown as ReferrerCommunity);
    }
    this.communication.selectedEmail.next({contact:community, email:email});

    let d = this.dialog.open(EmailSendComponent,
      { data: {selectedEmail: email.address, personObj:community, itemType: "Community"},
        disableClose: true, 
              width:"calc(100vw - 40px)",
              maxWidth:"900px"},
              );

    // On dialog close, update the First/Last Contact columns by grabbing the data.
    d.afterClosed().subscribe(result=>{
        // Check if email successfully sent.
        if(result != null && result == "Success") {
        
        }
    });
  }
    
  ngOnChanges(changes: SimpleChanges): void {
    if(changes["community"].currentValue != changes["community"].previousValue) {
      this.phones = [];
      this.mobiles = [];
      this.emails = [];
      
      if(this.community != null){
        let phones = this.community.primaryPhone ? [this.community.primaryPhone] : [];
        if(phones.length > 0){
          this.phones.push({community:this.community, phones:phones});
        }

        let mobiles = this.community.primaryPhone?.contact_type == "M" ? [this.community.primaryPhone] : [];
        if(mobiles.length > 0){
          this.mobiles.push({community:this.community, phones:mobiles});
        }

        let emails = this.community.primaryEmail ? [this.community.primaryEmail] : [];
        if(emails.length > 0){
          this.emails.push({community:this.community, emails:emails});
        }
      }
    }
  }
}