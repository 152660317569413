import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, WritableSignal, signal } from '@angular/core';
import { faFile, faFileDownload, faImage, faImagePortrait, faMinus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { PhotoLink } from '../../models/base.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PersonService } from '../../services/person.service';
import { sign } from 'crypto';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUpload implements OnChanges {
    @Input() photos!:PhotoLink[];
    @Input() immediateUpload!:boolean;
    @Input() originPage!:string;
    @Input() maxPhotos?:number;
    @Input() accept:string = ".png,.jpg,.jpeg";

    @Output() remove = new EventEmitter<PhotoLink>();
    uploadIcon=faUpload;
    removeIcon=faMinus;
    fileIcon=faFile;
    fileDLIcon=faFileDownload;
    imgIcon=faImagePortrait;
    public files: NgxFileDropEntry[] = [];
    @ViewChild("scrollElement") element!:ElementRef;
    @ViewChild("mainImg") mainImg!:ElementRef;
    
    selectedSrc:WritableSignal<PhotoLink|undefined> = signal(undefined);
    
    constructor(private snackbar:MatSnackBar, private personService:PersonService){
        
    }
    
    @Input() upload!: (entry:FormData)=>Promise<PhotoLink>;
    
    pendingUpload:NgxFileDropEntry[] = [];
    uploading:any[] = [];
    
    @Input() noRemove ?= false;
    
    ngOnChanges(changes: SimpleChanges): void {
        if(changes && changes["photos"] && changes["photos"].currentValue != changes["photos"].previousValue){
            if(this.photos && this.photos[0]){
                this.selectedSrc.set({...this.photos[0], loaded: (this.photos[0].filetype == "pdf" || this.photos[0].filetype == "docx" || this.photos[0].filetype == "xlsx" || this.photos[0].filetype == "doc" || this.photos[0].filetype == "xls")});
            }else{
                this.selectedSrc.set(undefined);
            }
        }
    }
    
    

    public async dropped(files: NgxFileDropEntry[]) {
        if (!this.uploadPermissionCheck()) {
            return;
        }

        this.files = files;
        for (const droppedFile of files) {

            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                if(this.immediateUpload){
                    if(this.maxPhotos == 1){
                        this.selectedSrc.set(undefined);
                    }
                    fileEntry.file(async (file: File) => {
                        const formData = new FormData()
                        formData.append('file', file, droppedFile.relativePath)
                        this.uploading.push({} as any);
                        let data = await this.upload(formData);
                        if(data){
                            if(data.stackTrace){
                                this.snackbar.open("Unable to upload file");
                                data.loaded = false;
                            } else if(data.message && data.message.includes("File must be")) {
                                this.snackbar.open(data.message);
                                data.loaded = false;
                            } else if(data.message && data.message.includes("Unable to process file")) {
                                this.snackbar.open("Unable to process file");
                                data.loaded = false;
                                this.selectedSrc.set(undefined);
                            } else{
                                if(this.photos.length == 0){
                                    this.selectedSrc.set({...data, loaded: (data.filetype == "pdf" || data.filetype == "docx" || data.filetype == "xlsx" || data.filetype == "doc" || data.filetype == "xls")});
                                    data.loaded = false;
                                    this.photos.push(data);
                                }else if(this.maxPhotos == 1){
                                    this.photos[0] = data;
                                    this.selectedSrc.set({...data, loaded: (data.filetype == "pdf" || data.filetype == "docx" || data.filetype == "xlsx" || data.filetype == "doc" || data.filetype == "xls")});
                                }else{
                                    this.photos.push(data);
                                }
                                this.snackbar.open("File uploaded");
                            }
                        }
                        this.uploading.splice(0, 1);
                        this.uploading = [...this.uploading];
                    });
                }else{
                    this.pendingUpload.push(droppedFile);
                    let upload = {src:"", type:"img"};
                    this.uploading.push(upload);
                    fileEntry.file(async (f:File)=>{
                        if(f.type == "application/pdf"){
                            upload.type = "pdf";
                        } else if(f.name != null && f.name != undefined && f.name.endsWith(".docx")){
                            upload.type = "docx";
                        } else if(f.name != null && f.name != undefined && f.name.endsWith(".xlsx")){
                            upload.type = "xlsx";
                        } else if(f.name != null && f.name != undefined && f.name.endsWith(".doc")){
                            upload.type = "doc";
                        } else if(f.name != null && f.name != undefined && f.name.endsWith(".xls")){
                            upload.type = "xls";
                        }
                        else{
                            let buff = await f.arrayBuffer()
                            let blob = new Blob( [buff], {type: f.type});
                            let urlCreator = window.URL || window.webkitURL;
                            var imageUrl = urlCreator.createObjectURL( blob );
                            upload.src = imageUrl;
                        }
                    })
                }
            } else {
            }
        }
    }
    
    public hasAnyFiles(){
        if(this.immediateUpload){
            return this.photos.length > 0
        }else{
            return this.uploading.length > 0;
        }
    }

    public fileOver(event:any){
        // console.log(event);
    }

    public fileLeave(event:any){
        // console.log(event);
    }
    
    onWheel(event:WheelEvent){
      this.element.nativeElement.scrollLeft += event.deltaY;
      event.preventDefault();
    }
    
    public async uploadFiles(){
        let promises:Promise<any>[] = [];
        for(let droppedFile of this.pendingUpload){
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            let promise = new Promise((resolve)=>{
                fileEntry.file(async (file: File) => {
                    const formData = new FormData()
                    formData.append('file', file, droppedFile.relativePath)
                    let fileResponse = await this.upload(formData);
                    resolve(fileResponse);
                });
            });
            promises.push(promise);
        }
        let files = await Promise.all(promises);
        return files;
    }
    
    finishLoad(photo:PhotoLink){
        photo.loaded = true;
    }
    
    finishSelectedLoad(){
        this.selectedSrc.update(d=>{
            if(d){
                return {...d, loaded: true}
            }else{
                return d;
            }
        });
    }
    
    loadError(){
        this.selectedSrc.update(d=>{
            if(d){
                return {...d, loaded: true}
            }else{
                return d;
            }
        });
    }
    
    selectPhoto(photo:PhotoLink){
        this.selectedSrc.set({...photo, loaded: (photo.filetype == 'pdf' || photo.filetype == 'docx' || photo.filetype == 'xlsx' || photo.filetype == 'doc' || photo.filetype == 'xls')});
    }
    
    openFile(photo:PhotoLink|undefined){
        window.open(photo?.fileId, "_blank");
    }
    
    removePhoto(photo:PhotoLink|undefined){
        this.remove.emit(photo);
    }
    
    loadImage(url:any) {
        return new Promise(r => { let i = new Image(); i.onload = (() => r(i)); i.src = url; });
    }

    uploadPermissionCheck() {
        if (this.originPage == 'floorplans') {
            return this.personService.hasSomePermission(['floor-plans:upload-photo']);
        }
        if (this.originPage == 'marketingmaterial') {
            return this.personService.hasSomePermission(['marketing-materials:add/update']);
        }
        return true;
        
    }
}