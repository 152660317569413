import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBar } from "@angular/material/snack-bar";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import { faCalendarDay, faHandsClapping, faPersonWalking, faUserTie, faPersonCircleMinus, faPeopleArrows, faCircleMinus, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FindCommunitiesList } from "./findcommunitieslist.widget";

export interface FindCommunitiesBulkSnackBarData{
  // filter:ProspectFilter,
  component:FindCommunitiesList
}

@Component({
    selector:'findcommunities-bulk-snack-bar',
    templateUrl:'findcommunities-bulk.snack-bar.html',
    styleUrls:['../../../bulk.snack-bar.scss']
  })
  export class FindCommunitiesBulkSnackBar {

    removeIcon = faUserTie;
    emailIcon = faEnvelope;
    rejectIcon = faCircleMinus;
    
    // filter:ProspectFilter;
  
    // filterTypes = ProspectFilter;s
    
    listComponent:FindCommunitiesList;
    
    constructor(private snackbar:MatSnackBar, @Inject(MAT_SNACK_BAR_DATA) config:FindCommunitiesBulkSnackBarData, @Inject(MAT_SNACK_BAR_DATA) public data: any) { 
      // this.filter = config.filter;

      this.listComponent = data.component;
    }
  
    doAction() {
        // you can do some action here
    }

    comingSoon(){
      this.listComponent.comingSoon();
    }
      
    cancel() {
      // close the snackbar or wirte another action
    }

  }