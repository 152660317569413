import { Component, Input, computed } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Community } from "src/app/core/models/user.models";
import { DashboardService } from "src/app/core/services/dashboard.service";
import { OccupancyService, OccupancySummary } from "src/app/core/services/occupancy.service";
import { PersonService } from "src/app/core/services/person.service";

@UntilDestroy()
@Component({
    selector: 'summary-grid-dash',
    templateUrl: './summary-grid.component.html',
    styleUrls: ['../graph-widget-style.scss']
  })
  export class SummaryGridDashWidget {
    @Input() topLeftTitle!:string;
    @Input() topLeftProp!:string;
    @Input() topLeftPropTwo!:string;
    @Input() topLeftPluralable:boolean = true;
    
    @Input() topRightTitle!:string;
    @Input() topRightProp!:string;
    @Input() topRightPropTwo!:string;
    @Input() topRightPluralable:boolean = true;

    @Input() bottomLeftTitle!:string;
    @Input() bottomLeftProp!:string;
    @Input() bottomLeftPropTwo!:string;
    @Input() bottomLeftPluralable:boolean = true;

    @Input() bottomRightTitle!:string;
    @Input() bottomRightProp!:string;
    @Input() bottomRightPropTwo!:string;
    @Input() bottomRightPluralable:boolean = true;
    
    @Input() aggregateField:boolean = false;
    @Input() mtdField:boolean = false;
    
    loading = computed(()=>{
      return this.occupancyService.loading()
    })
    tooltipTopLeftText = computed(()=>{
      return this.tooltipText(this.loading(), this.topLeftPluralable, this.topLeftTitle, this.topLeftValue());
    });
    tooltipTopRightText = computed(()=>{
      return this.tooltipText(this.loading(), this.topRightPluralable, this.topRightTitle, this.topRightValue());
    });
    tooltipBottomLeftText = computed(()=>{
      return this.tooltipText(this.loading(), this.bottomLeftPluralable, this.bottomLeftTitle, this.bottomLeftValue());
    });
    tooltipBottomRightText = computed(()=>{
      return this.tooltipText(this.loading(), this.bottomRightPluralable, this.bottomRightTitle, this.bottomRightValue());
    });
    
    tooltipText = (loading:boolean, pluralable:boolean, title:string, value:any)=>{
      if(this.mtdField) {
        return `Day/Month Counts`;
      } else if(loading){
        return `Total ${title}s in ${this.currentCommunity?.nameDisplay}`;
      }else{
        if(pluralable){
          return `${value} ${title}(s) are in ${this.currentCommunity?.nameDisplay}`;
        }else{
          return `${value} ${title} in ${this.currentCommunity?.nameDisplay}`;
        }
      }
    }
    
    currentCommunity:Community|null = null;
    
    topLeftValue = computed(()=>{
      const summary = this.occupancyService.occupancySummary();
      const aggregate = this.occupancyService.aggregate();
      return this.value(this.aggregateField ? aggregate : summary, this.topLeftProp, this.topLeftPropTwo);
    });
    topRightValue = computed(()=>{
      const summary = this.occupancyService.occupancySummary();
      const aggregate = this.occupancyService.aggregate();
      return this.value(this.aggregateField ? aggregate : summary, this.topRightProp, this.topRightPropTwo);
    });
    bottomLeftValue = computed(()=>{
      const summary = this.occupancyService.occupancySummary();
      const aggregate = this.occupancyService.aggregate();
      return this.value(this.aggregateField ? aggregate : summary, this.bottomLeftProp, this.bottomLeftPropTwo);
    });
    bottomRightValue = computed(()=>{
      const summary = this.occupancyService.occupancySummary();
      const aggregate = this.occupancyService.aggregate();
      return this.value(this.aggregateField ? aggregate : summary, this.bottomRightProp, this.bottomRightPropTwo);
    });
    
    value = (summary:any, prop:string, propTwo:string) => {
        const obj = (summary as any);
        var returnVal:any;
        if(obj && obj[prop] != null) {
          returnVal = obj[prop] as number;
        }else{
          returnVal = 0;
        }

        // Add the MTD values here for aggregate items.
        if(this.mtdField) {
          if(obj && obj[propTwo] != null) {
            returnVal += "/" + obj[propTwo] as unknown as number;
          }else {
            returnVal += "/" + 0;
          }
        }

        return returnVal;
    }
    
    constructor(
        personService:PersonService, 
        private occupancyService: OccupancyService){
      personService.currentCommunity.pipe(untilDestroyed(this)).subscribe(async community=>{
        this.currentCommunity = community;
      });
    }
  }