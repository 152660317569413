import { NgModule } from "@angular/core";
import * as echarts from 'echarts';
import { NgxEchartsModule } from "ngx-echarts";

export class EChartTheme{
  static hot = "#4aff25";
  static cold = "#4776a5";
  static other = "#21343c";
  static accent = "#df5092";
  static grey = '#cccccc';
  static shadow = 'rgba(0, 0, 0, 0.5)';
}

echarts.registerTheme('hotCold', {
  color: [
    EChartTheme.hot,
    EChartTheme.cold,
    EChartTheme.other
  ],
  pie:{
    itemStyle:{
      shadowBlur: 4,
      shadowColor: 'rgba(0, 0, 0, 0.5)',
      borderRadius:3
    }
  },
  bar:{
    itemStyle: {
      shadowBlur: 4,
      shadowColor: 'rgba(0, 0, 0, 0.5)',
      borderRadius: 1
    }
  }
})

@NgModule({
    declarations: [
    ],
    imports: [
      NgxEchartsModule.forRoot({
        echarts
      })
    ],
    exports: [
      NgxEchartsModule,
    ]
  })
  export class GraphModule { }