<h1 mat-dialog-title>Create Invoice for {{placement?.prospect?.nameDisplay}}</h1>
<mat-dialog-content [formGroup]="invoiceFormGroup">
    <div class="centeringContainer">
        <mat-form-field class="invoice-number flexCentering">
            <mat-label>Invoice #</mat-label>
            <input matInput placeholder="Invoice #" mask="0*" [maxLength]="16" formControlName="invoiceNumber">
        </mat-form-field>    
    </div>
    <strong class="mildBotPadding">Invoice Items</strong>
    <div formArrayName="lineItems">
    <div *ngFor="let item of lineItemsList?.controls; let index = index;" [formGroupName]="index">
        <div class="addEntryRow fill-row description-amount">
            <mat-form-field class="description">
                <mat-label>Item Description</mat-label>
                <input matInput placeholder="Item Description" [maxLength]="64" formControlName="description">
            </mat-form-field>

            <mat-form-field class="amount">
                <mat-label>Amount ($)</mat-label>
                <input matInput type="text" prefix="$ " mask="separator.2" thousandSeparator="," placeholder="Amount ($)" [maxLength]="16" formControlName="amount">
            </mat-form-field>
            <fa-icon *ngIf="hasMultipleItems" [icon]="deleteIcon" (clickOrKey)="removeItem(index)" matTooltip="Remove Line Item"></fa-icon>
        </div>
    </div>
    </div>
    <floating-add-button class="add" (clickOrKey)="addItem()"></floating-add-button>

</mat-dialog-content>
<div mat-dialog-actions align="end">
    <button *ngIf="!submitting" mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="createInvoiceDocument()" [disabled]="submitting || invoiceFormGroup.invalid">Create Invoice</button>
    <load-spinner inline *ngIf="submitting"></load-spinner>
</div>