import { AfterViewInit, Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subscription } from "rxjs";
import { User } from "src/app/core/models/user.models";
import { GraphApiService } from "src/app/core/services/graphapi.service";
import { PersonService } from "src/app/core/services/person.service";
import { ProspectFilter } from '../../../prospect/ProspectFilter';
import { DatePipe } from "@angular/common";
import { ApiService } from "src/app/core/services/api.service";

@UntilDestroy()
@Component({
    selector: 'website-inquiries-dash',
    templateUrl: './website-inquiries.component.html',
    styleUrls: ['../graph-widget-style.scss'],
    providers:[DatePipe]
  })
  export class WebsiteInquiriesDashWidget implements OnInit {
    
    count:any = "0/0";
    loading:boolean = true;
    
    
    private monthStart:Date;
    private todayEnd:Date
    currentUser:User|null = null;
    constructor(private snackbar:MatSnackBar, private graphApi:GraphApiService, private personService:PersonService, private router:Router, protected datePipe: DatePipe, private api:ApiService){
      this.monthStart = new Date();
      this.todayEnd = new Date();
      personService.currentUser.pipe(untilDestroyed(this)).subscribe(async user=>{
        this.currentUser = user;
      });
      personService.communities.pipe(untilDestroyed(this)).subscribe(async communities =>{
        if(this.currentUser?.company){
          let dateStr = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

          this.loading = true;
          let resp = await this.api.post("/graph-data/prospect/websiteInquiries/"+this.currentUser.company.companyId, {"filter": dateStr});
          this.loading = false;
          
          // Only set the data if a proper value was returned.
          if(resp != null && resp.requestedCount != null && resp.comparisonCount != null) {
            this.count = resp.requestedCount + "/" + resp.comparisonCount;
          }
        }
      });
    }
    
    ngOnInit(): void {
    }
    
    openList(){
      // KE: 08/09/2024: Bug 47978: Clicking widgets need to filter table again.
      this.router.navigate(["prospect"], {
        queryParams: {
          prospectwebsiteinquiries: true
        }
      });
      // Make small timout. So URL changes before calling the code to refresh.
      setTimeout(()=>{
        this.personService.widgetClickAction.next([]); // This will cause data to refresh.
      }, 100);
    }
  }