import { Component, computed, HostBinding, signal, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs';
import { Event, Organization, User } from 'src/app/core/models/user.models';
import { AppService } from 'src/app/core/services/app.service';
import { PersonService } from 'src/app/core/services/person.service';

@UntilDestroy()
@Component({
  selector: 'app-event',
  templateUrl: './eventsview.component.html',
  styleUrls: ['../../app.component.scss'],
  host: {'class': 'twoColumnPage'}
})
export class EventViewComponent {
  title = 'MR-Vital-CRM';
  currentEvent = signal<Event|null>(null);
  selectedEvent = computed<Event>(()=>{
    if(this.currentEvent()){
      return this.currentEvent() as Event;
    }else{
      return Event.fromJson({}) as Event;
    }
  })
  currentUser:User|null = null;

  @HostBinding('class.expand-main') expandMain:boolean = false;
  @HostBinding('class.expand-sub') expandSub:boolean = false;
  
  constructor(appService:AppService, personService:PersonService){
    personService.selectedEvent.pipe(untilDestroyed(this)).subscribe(e=>{
      this.currentEvent.set(e);
    });
    
    appService.selectWidget.pipe(untilDestroyed(this), debounceTime(100)).subscribe(widget =>{
      this.expandMain = false;
      this.expandSub = false;
      if(widget){
        let element = widget.widgetCard.nativeElement;
        let parent = element.closest('.mainContent');
        if(parent){
          this.expandMain = true;
        }else{
          parent = element.closest('.subContent');
          if(parent){
            this.expandSub = true;
          }
        }
      }
    })
  }
}
