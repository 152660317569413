import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GoogleApiService } from '../../services/google-api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersonService } from '../../services/person.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Community, Event, Organization, Prospect, Referrer, ReferrerCommunity, ReferrerStaff, ScheduledActivity, User } from '../../models/user.models';
import { ApiService } from '../../services/api.service';
import { EmailMessage } from '../../widgets/communication/email/email.component';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { CompanyCfgService } from '../../services/company-cfg.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IContactable } from '../../models/base.models';

export interface EmailSendDialogData{
  selectedPeople: IContactable[];
  eventMode: any;
  selectedEmail?:EmailMessage
  email?:EmailMessage
  oauthStatus: any[]
  personObj: any
  itemType: any
  emailNurses: boolean
  event: Event
  activityIds?: string[],
  selectedTemplateName?: string
}
@UntilDestroy()
@Component({
  selector: 'app-email-send',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.scss']
})
export class EmailSendComponent implements OnInit {
  win: Window | undefined;
  form: FormGroup = new FormGroup({
    to: new FormControl('',Validators.required),
    cc: new FormControl(''),
    subject: new FormControl('',Validators.required),
    body: new FormControl('',Validators.required)
  });
  attachments: any[] = [];
  templateAttachments: any[] = [];
  marketingAttachments: any[] = [];
  loading = false;
  sendIcon = faPaperPlane;
  people: any[] = [];
  selectPeople: {personId:string, name:string, addressDisp:string, email?:EmailMessage|string}[] = [];
  ccPeople: {personId:string, name:string, addressDisp:string, email?:EmailMessage|string}[] = [];
  ccSelectPeople: {personId:string, name:string, addressDisp:string, email?:EmailMessage|string}[] = [];
  showCC = false;
  currentUser!: User;
  oauthStatus: any[] = [];
  communities: Community[] = [];
  selectTemplate: any[] = [];
  selectedTemplate: any = [];
  signature: any = null;

  //{personId: 1, name: "Kari Zimmerman <kzimmerman@happyjacksoftware.com>", email: "kzimmerman@happyjacksoftware.com"}, {personId: 2, name: "Kari Too <kari@krazkustoms.com>", email: "kari@krazkustoms.com"}
  prospect:Prospect|null = null;

  public submitting:boolean = false;
  nurseMode: boolean = false;
  eventMode: boolean = false;
  currentRouteString: string = "";
  type: string = "Generic";

  emailLink?:"communityAttributes";
  
  constructor(private readonly http: HttpClient,
    @Inject(DOCUMENT) private document: Document, private ngZone: NgZone,
    private snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<EmailSendComponent>, 
    private personService: PersonService,
    private companyCfg: CompanyCfgService,
    @Inject(MAT_DIALOG_DATA) public data: EmailSendDialogData,
    public api: ApiService,
    protected router:Router,
    private route:ActivatedRoute) { 
      this.personService.currentUser.subscribe(async user =>{ 
        if(user){
          this.currentUser = user;
        }
      });

      //get all users to communities current user has access
      this.personService.communities.pipe(untilDestroyed(this)).subscribe(communities=>{
          this.communities = communities;
      });

      this.personService.selectedProspect.pipe(untilDestroyed(this)).subscribe(async newPerson => {
        // Make sure you're not on the Emails page, don't want to set selected prospect if you are.
        if(router == null || router == undefined || router.url == null || router.url == undefined || !router.url.endsWith("/email")){
          this.prospect = newPerson;
        }
        
        console.log(this.data);
        
        
      });

    }

  async ngOnInit() {
    this.loading = true;
    window.my = window.my || {};
    window.my.namespace = window.my.namespace || {};
    window.my.namespace.publicFunc = this.publicFunc.bind(this);
    await this.getOAuthStatus();

    if (window.location.href.indexOf("/prospect") > -1){
      this.type = "Prospect";
    }else if (window.location.href.indexOf("/organization") > -1 && !this.companyCfg.referrerCRM()){
      this.type = "Referrer";
    }else if (window.location.href.indexOf("/organization") > -1 && this.companyCfg.referrerCRM()){
      this.type = "Contact";
    }else if (window.location.href.indexOf("/events") > -1){
      this.type = "Event";
    }else if (window.location.href.indexOf("/referrercommunity") > -1){
      this.type = "Staff";
    }else if (window.location.href.indexOf("/referrerstaff") > -1){
      this.type = "Staff";
    }else if (window.location.href.indexOf("/referrer") > -1 && !this.companyCfg.referrerCRM()){
      this.type = "Referrer";
    }else if (window.location.href.indexOf("/referrer") > -1 && this.companyCfg.referrerCRM()){
      this.type = "Contact";
    }

    this.selectTemplate = await this.api.getEmailTemplates(this.type);
    let autoSelected:any = null;
    //add parenthesis to selectTemplate type
    this.selectTemplate.forEach((template) => {
      template.type = "(" + template.type + ")";
      
      if(this.data.selectedTemplateName && this.data.selectedTemplateName == template.name.trim()){
        autoSelected = template;
      }
    });
    await this.getSignature();
    if(autoSelected){
      await this.changeTemplate([autoSelected]);
    }

    let users;
    if(this.companyCfg.communityCRM()){
      users = await this.api.post("user/by-communities-active", {ids:this.communities.map(c=>c.communityId)});
    }else{
      users = await this.api.get(`user/by-company-active/${this.currentUser.company.companyId}`, {pageSize:500, pageNumber:0, search:""});
      users = users.content;
    }
      
   users
      .map((user:any) => User.fromJson(user))
      .map((user:User)=>{
        if (user.personId != this.currentUser.personId)
          this.ccSelectPeople.push({
            personId: user.personId, 
            name: user.nameDisplay, 
            addressDisp: "<" + user.email + ">",
            email: user.email});
      });
    

    if (this.data.email != null){  //replying to message
     let resp = await lastValueFrom(this.http.get<any>(environment.API_URL + "/communication/getEmailMessage/"+this.data.email.guid,));
          
        if (resp.status == 'Sent'){
            this.form.get('to')?.setValue(resp.to);
          }else{
            this.form.get('to')?.setValue(resp.from);
          }
          

          //populate selectPeople based on entityType
          if (this.data.email?.entityType === 'prospect'){
            if (this.prospect != null){
                let prospect = this.prospect;
                if (this.prospect.emailAddresses.length > 0){
                  this.prospect.emailAddresses.map((email) => {
                    this.selectPeople.push({
                      personId: prospect.personId, 
                      name: prospect.nameDisplay, 
                      addressDisp: "<" + email.address + ">",
                      email: email.address});
                  });
                  
                }
                if (this.prospect.contacts.length > 0){
                  this.prospect.contacts.map((contact) => {
                    contact.emailAddresses.map((email) => {
                      this.selectPeople.push({
                        personId: contact.personId, 
                        name: contact.nameDisplay, 
                        addressDisp: "<" + email.address + ">",
                        email: email.address});
                    });
                  });
                }
            }
            else{//get selectPeople from server for prospect
              let prospect = await this.api.get("prospect/full/"+this.data.email.personId);
              if (prospect){
                prospect = Prospect.fromJson(prospect);
                if (prospect.emailAddresses.length > 0){
                  prospect.emailAddresses.map((email:any) => {
                    this.selectPeople.push({
                      personId: prospect.personId, 
                      name: prospect.nameDisplay, 
                      addressDisp: "<" + email.address + ">",
                      email: email.address});
                  });
                }
                if (prospect.contacts.length > 0){
                  prospect.contacts.map((contact:any) => {
                    contact.emailAddresses.map((email:any) => {
                      this.selectPeople.push({
                        personId: contact.personId, 
                        name: contact.nameDisplay, 
                        addressDisp: "<" + email.address + ">",
                        email: email.address});
                    });
                  });
                }
              }
            }
          }else if (this.data.email?.entityType === 'organization'){
            let org = await this.api.get("organization/full/"+this.data.email.personId);
            if (org){
              org = Organization.fromJson(org);
              if (org.emailAddresses && org.emailAddresses.length > 0){
                this.selectPeople.push({
                  personId: org.organizationId, 
                  name: org.nameDisplay, 
                  addressDisp: "<" + org.emailAddresses[0].address + ">",
                  email: org.emailAddresses[0].address});
              }
              let referrers = await this.api.get("referrer/listorgAll/"+org.organizationId);
              if(referrers == null || referrers == undefined) {
                referrers = [];
              }
  
              // Loop through the list and add any that have proper emails.
              for(var i = 0; i < referrers.length; i++) {
                let referrerObj = Referrer.fromJson(referrers[i]);
  
                if(referrerObj != null && referrerObj != undefined && referrerObj.emailAddresses != null && referrerObj.emailAddresses != undefined && referrerObj.emailAddresses.length > 0) {
                  let emails = referrerObj.emailAddresses.filter(p=>p.is_primary && p.allowSalesContact);
                  if(emails != null && emails != undefined && emails.length > 0 && emails[0].address != null && emails[0].address != undefined && emails[0].address != "") {
                    // Just take one email if available from each of the other referrer staff in the community.
                    this.selectPeople.push({
                      personId: referrerObj.personId, 
                      name: referrerObj.nameDisplay, 
                      addressDisp: "<" + emails[0].address + ">", 
                      email: emails[0].address
                    });
                  }
                }
              }
            }
          }else if (this.data.email?.entityType === 'community'){
            let com = await this.api.get("referrercommunity/full/"+this.data.email.personId);
            if (com){
              com = ReferrerCommunity.fromJson(com);
              if (com.emailAddresses && com.emailAddresses.length > 0){
                this.selectPeople.push({
                  personId: com.id, 
                  name: com.nameDisplay, 
                  addressDisp: "<" + com.primaryEmail() + ">",
                  email: com.primaryEmail()});
              }
            }
          }else if (this.data.email?.entityType === 'referrer'){
            let referrer = await this.api.get("referrer/"+this.data.email.personId);
            let org = await this.api.get("organization/full/"+referrer.organization.organizationId);
            //add organization to selectPeople
            if (org){
              org = Organization.fromJson(org);
              if (org.emailAddresses && org.emailAddresses.length > 0){
                this.selectPeople.push({
                  personId: org.organizationId, 
                  name: org.nameDisplay, 
                  addressDisp: "<" + org.emailAddresses[0].address + ">",
                  email: org.emailAddresses[0].address});
              }
            }
          }else if (this.data.email?.entityType === 'staff'){
            let referrer = await this.api.get("referrerstaff/"+this.data.email.personId);
            // let org = await this.api.get("organization/full/"+referrer.organization.organizationId);
            //add organization to selectPeople
            // if (org){
            //   org = Organization.fromJson(org);
            //   if (org.emailAddresses && org.emailAddresses.length > 0){
            //     this.selectPeople.push({
            //       personId: org.organizationId, 
            //       name: org.nameDisplay, 
            //       addressDisp: "<" + org.emailAddresses[0].address + ">",
            //       email: org.emailAddresses[0].address});
            //   }
            // }
          }

          console.log(this.selectPeople);

          this.selectPeople.filter((person) => {
            let recipients = resp.to.split(",");
              for (let recipient of recipients){
                if (recipient.includes("<")){
                  if (recipient.match('<(.*?)>')[1] === person.email){
                    this.people.push(person);
                  }
                }else{
                  if (recipient.trim() === person.email){
                    this.people.push(person);
                  }
                }
              }

            if (resp.status === 'Received'){
              if (person.email === resp.fromAddress){
                this.people.push(person);
              }
            }

          this.changeAutocomplete(this.form, 'to', this.people);

            //select cc people too
            this.ccSelectPeople.filter((person) => {
              let recipients = resp.cc != null && resp.cc != undefined ? resp.cc.split(",") : [];
                for (let recipient of recipients){
                if (recipient.includes("<")){
                    if (recipient.match('<(.*?)>')[1] === person.email){
                      if (!this.ccPeople.some(p => p.email === person.email)) {
                        this.ccPeople.push(person);
                      }
                    }
                  }else {
                    if (recipient.trim() === person.email){
                      if (!this.ccPeople.some(p => p.email === person.email)) {
                        this.ccPeople.push(person);
                      }
                    }
                  }
                }
            });
            if (this.ccPeople.length > 0){
              this.showCC = true;
            }

            this.changeAutocomplete(this.form, 'cc', this.ccPeople);
          
          //if resp.subject starts with "Re: " then don't add "Re: " to the subject
          this.form.get('subject')?.setValue(resp.subject.startsWith("Re:") ? resp.subject : "Re: " + resp.subject);
          this.form.get('body')?.setValue("<br/><br/>On " + new Date(resp.rawDate).toLocaleString() + " " + 
              " " + resp.from.replace("<", "&lt;").replace(">", "&gt;") + " wrote: <blockquote>" + resp.message.replace("<", "&lt;").replace(">", "&gt;") + "</blockquote>");
      });
    }  
    
    else{ //new message
       
        //coming in with a specific selected email
        if (this.data.eventMode){
          this.eventMode = true;
          this.form.get('to')?.setValue(this.data.selectedPeople);
        }
        else if(this.data.emailNurses != null && this.data.emailNurses != undefined && this.data.emailNurses) {
          this.nurseMode = true;
          this.selectPeople = this.ccSelectPeople;
        }
        else if(this.prospect != null && this.data.itemType == 'Prospect'){
          let prospect = this.prospect;
          if (this.prospect.emailAddresses.length > 0){
            this.prospect.emailAddresses.map((email) => {
              if (email.allowSalesContact){
                this.selectPeople.push({
                  personId: prospect.personId, 
                  name: prospect.nameDisplay, 
                  addressDisp: "<" + email.address + ">",
                  email: email.address});
                }
            });
            
          }
          if (this.prospect.contacts.length > 0){
            this.prospect.contacts.map((contact) => {
              contact.emailAddresses.map((email) => {
                if (email.allowSalesContact){
                  this.selectPeople.push({
                    personId: contact.personId, 
                    name: contact.nameDisplay, 
                    addressDisp: "<" + email.address + ">",
                    email: email.address});
                  }
              });
            });
          }

          if (this.data.selectedEmail != null){
            this.form.get('to')?.setValue(this.data.selectedEmail);
    
            this.selectPeople.filter((person) => {
              if (person.email === this.data.selectedEmail){
                this.people.push(person);
              }
            });
          }else if(this.selectPeople.length > 0){
            this.people.push(this.selectPeople[0]);
            this.form.get('to')?.setValue(this.selectPeople[0].email);
          }

        }
      else if (this.data.itemType == 'Organization'){
      
        if(this.data.personObj != null){
          this.selectPeople.push({
            personId: this.data.personObj.organizationId, 
            name: this.data.personObj.organizationName, 
            addressDisp: "<" + this.data.selectedEmail + ">", 
            email: this.data.selectedEmail
          });
        }

        //get referrers for organization
        let referrers = await this.api.get("referrer/listorgAll/"+this.data.personObj.organizationId);
        if(referrers == null || referrers == undefined) {
          referrers = [];
        }

        // Loop through the list and add any that have proper emails.
        for(var i = 0; i < referrers.length; i++) {
          let referrerObj = Referrer.fromJson(referrers[i]);

          if(referrerObj != null && referrerObj != undefined && referrerObj.emailAddresses != null && referrerObj.emailAddresses != undefined && referrerObj.emailAddresses.length > 0) {
            let emails = referrerObj.emailAddresses.filter(p=>p.is_primary && p.allowSalesContact);
            if(emails != null && emails != undefined && emails.length > 0 && emails[0].address != null && emails[0].address != undefined && emails[0].address != "") {
              // Just take one email if available from each of the other referrer staff in the community.
              this.selectPeople.push({
                personId: referrerObj.personId, 
                name: referrerObj.nameDisplay, 
                addressDisp: "<" + emails[0].address + ">", 
                email: emails[0].address
              });
            }
          }
        }

        this.selectPeople.filter((person) => {
          if (person.email === this.data.selectedEmail){
            // Push person to people array if it doesn't exist in list yet.
            if (!this.people.some(p => p.email === person.email)) {
              this.people.push(person);
            }
          }
        });

        this.form.get('to')?.setValue(this.data.selectedEmail);

      }else if (this.data.itemType == 'Community'){
      
          if(this.data.personObj != null){
            this.selectPeople.push({
              personId: this.data.personObj.id, 
              name: this.data.personObj.nameDisplay, 
              addressDisp: "<" + this.data.selectedEmail + ">", 
              email: this.data.selectedEmail
            });
          }

          // KE: 09/27/2024: Bug 48196: Include other staff from that same Community in the Choose Receipient list.
          if(this.data.personObj != null && this.data.personObj != undefined && this.data.personObj.id != null && this.data.personObj.id != undefined && this.data.personObj.id != "") {
            let staffInReferrerCommunity = await this.api.get("referrerstaff/listcom/"+this.data.personObj.id);
            if(staffInReferrerCommunity == null || staffInReferrerCommunity == undefined) {
              staffInReferrerCommunity = [];
            }

            // Loop through the list and add any that have proper emails.
            for(var i = 0; i < staffInReferrerCommunity.length; i++) {
              let referrerObj = ReferrerStaff.fromJson(staffInReferrerCommunity[i]);

              if(referrerObj != null && referrerObj != undefined && referrerObj.emailAddresses != null && referrerObj.emailAddresses != undefined && referrerObj.emailAddresses.length > 0) {
                let emails = referrerObj.emailAddresses.filter(p=>p.is_primary && p.allowSalesContact);
                if(emails != null && emails != undefined && emails.length > 0 && emails[0].address != null && emails[0].address != undefined && emails[0].address != "") {
                  // Just take one email if available from each of the other referrer staff in the community.
                  this.selectPeople.push({
                    personId: referrerObj.personId, 
                    name: referrerObj.nameDisplay, 
                    addressDisp: "<" + emails[0].address + ">", 
                    email: emails[0].address
                  });
                }
              }
            }
          }

          this.selectPeople.filter((person) => {
            if (person.email === this.data.selectedEmail){
              // Push person to people array if it doesn't exist in list yet.
              if (!this.people.some(p => p.email === person.email)) {
                this.people.push(person);
              }
            }
          });

          this.form.get('to')?.setValue(this.data.selectedEmail);
        
      }else if (this.data.itemType == 'Referrer'){
        //this.form.get('to')?.setValue(this.data.selectedEmail);

        if (this.data.selectedEmail != null){
          let raw = await this.api.get("referrer/"+this.data.personObj?.personId);
          // Added this so Referral email will populate the Choose recipient field properly when the modal is opened.
          let referrer = Referrer.fromJson(raw);
          if(referrer != null) {
            
            let email = this.data.personObj.email;
            if(!email){
              email = referrer.emailAddresses.find(e=>e.is_primary)?.address;
            }
            // Push this.data.personObj if it doesn't exist in list yet.
            if (!this.selectPeople.some(person => person.email === email)) {
              this.selectPeople.push({
                personId: referrer.personId, 
                name: referrer.nameDisplay, 
                addressDisp: "<" + email + ">", 
                email: email
              });
            }

            // This is for when you click the email button on the referrer table, which is the person-contact component.
            // KE: 09/27/2024: Bug 48197: The referrer/contact was found, so include others from that same Organization in the Choose Receipient list.
            if(referrer.organization != null && referrer.organization != undefined && referrer.organization.organizationId != null && referrer.organization.organizationId != undefined && referrer.organization.organizationId != "") {
              let referrersInCommunity = await this.api.get("referrer/listorgAll/"+referrer.organization.organizationId);
              if(referrersInCommunity == null || referrersInCommunity == undefined) {
                referrersInCommunity = [];
              }

              // Exclude the current staff member from the list.
              referrersInCommunity = referrersInCommunity.filter((staff:any) => staff.personId != referrer.personId);

              // Loop through the list and add any that have proper emails.
              for(var i = 0; i < referrersInCommunity.length; i++) {
                let referrerObj = Referrer.fromJson(referrersInCommunity[i]);

                if(referrerObj != null && referrerObj != undefined && referrerObj.emailAddresses != null && referrerObj.emailAddresses != undefined && referrerObj.emailAddresses.length > 0) {
                  let emails = referrerObj.emailAddresses.filter(p=>p.is_primary && p.allowSalesContact);
                  if(emails != null && emails != undefined && emails.length > 0 && emails[0].address != null && emails[0].address != undefined && emails[0].address != "") {
                    // Just take one email if available from each of the other referrer staff in the community.
                    this.selectPeople.push({
                      personId: referrerObj.personId, 
                      name: referrerObj.nameDisplay, 
                      addressDisp: "<" + emails[0].address + ">", 
                      email: emails[0].address
                    });
                  }
                }
              }
            }
          }

          // Removed because we only want to select other referrers/contacts in the community when doing a referrer type email.
          // let org = await this.api.get("organization/full/"+referrer?.organization?.organizationId);
          // //add organization to selectPeople
          // if (org){
          //   org = Organization.fromJson(org);
          //   if (org.emailAddresses && org.emailAddresses.length > 0 && org.emailAddresses[0].allowSalesContact){
          //     this.selectPeople.push({
          //       personId: org.organizationId, 
          //       name: org.nameDisplay, 
          //       addressDisp: "<" + org.emailAddresses[0].address + ">",
          //       email: org.emailAddresses[0].address});
          //   }
          // }
          this.selectPeople.filter((person) => {
            if (person.email === this.data.selectedEmail){
              // Push person to people array if it doesn't exist in list yet.
              if (!this.people.some(p => p.email === person.email)) {
                this.people.push(person);
              }
            }
          });

          this.form.get('to')?.setValue(this.data.selectedEmail);
        }else{
          if (!this.selectPeople.some(person => person.email === this.data.personObj.primaryEmail?.address)) {
            let item = {
              personId: this.data.personObj.personId, 
              name: this.data.personObj.nameDisplay, 
              addressDisp: "<" + this.data.personObj.primaryEmail?.address + ">", 
              email: this.data.personObj.primaryEmail?.address
            }
            this.selectPeople.push(item);
            this.people.push(item);

            // This is for when you go to the Communication widget, the email tab, then click the add button.
            // KE: 09/27/2024: Bug 48197: The referrer/contact was found, so include other ones from that same Organization in the Choose Receipient list.
            if(this.data.personObj != null && this.data.personObj != undefined && this.data.personObj.organization != null && this.data.personObj.organization != undefined && this.data.personObj.organization.organizationId != null && this.data.personObj.organization.organizationId != undefined && this.data.personObj.organization.organizationId != "") {
              let referrersInCommunity = await this.api.get("referrer/listorgAll/"+this.data.personObj.organization.organizationId);
              if(referrersInCommunity == null || referrersInCommunity == undefined) {
                referrersInCommunity = [];
              }

              // Exclude the current staff member from the list.
              referrersInCommunity = referrersInCommunity.filter((staff:any) => staff.personId != this.data.personObj.personId);

              // Loop through the list and add any that have proper emails.
              for(var i = 0; i < referrersInCommunity.length; i++) {
                let referrerObj = Referrer.fromJson(referrersInCommunity[i]);

                if(referrerObj != null && referrerObj != undefined && referrerObj.emailAddresses != null && referrerObj.emailAddresses != undefined && referrerObj.emailAddresses.length > 0) {
                  let emails = referrerObj.emailAddresses.filter(p=>p.is_primary && p.allowSalesContact);
                  if(emails != null && emails != undefined && emails.length > 0 && emails[0].address != null && emails[0].address != undefined && emails[0].address != "") {
                    // Just take one email if available from each of the other referrer staff in the community.
                    this.selectPeople.push({
                      personId: referrerObj.personId, 
                      name: referrerObj.nameDisplay, 
                      addressDisp: "<" + emails[0].address + ">", 
                      email: emails[0].address
                    });
                  }
                }
              }
            }
          }
          this.form.get('to')?.setValue(this.data.personObj.primaryEmail?.address);
        }
      } else if(this.data.itemType == 'Staff') {
        //this.form.get('to')?.setValue(this.data.selectedEmail);

        if (this.data.selectedEmail != null){
          let raw = await this.api.get("referrerstaff/"+this.data.personObj?.personId);
          // Added this so Referral email will populate the Choose recipient field properly when the modal is opened.
          let referrer = ReferrerStaff.fromJson(raw);
          if(referrer != null) {
            
            let email = this.data.personObj.email;
            if(!email){
              email = referrer.emailAddresses.find(e=>e.is_primary)?.address;
            }
            // Push this.data.personObj if it doesn't exist in list yet.
            if (!this.selectPeople.some(person => person.email === email)) {
              this.selectPeople.push({
                personId: referrer.personId, 
                name: referrer.nameDisplay, 
                addressDisp: "<" + email + ">", 
                email: email
              });
            }

            // This is for when you click the email button on the referrer staff table, which is the person-contact component.
            // KE: 09/27/2024: Bug 48196: The referrer staff was found, so include other staff from that same Community in the Choose Receipient list.
            if(referrer.referrerCommunity != null && referrer.referrerCommunity != undefined && referrer.referrerCommunity.referrerCommunityId != null && referrer.referrerCommunity.referrerCommunityId != undefined && referrer.referrerCommunity.referrerCommunityId != "") {
              let staffInReferrerCommunity = await this.api.get("referrerstaff/listcom/"+referrer.referrerCommunity.referrerCommunityId);
              if(staffInReferrerCommunity == null || staffInReferrerCommunity == undefined) {
                staffInReferrerCommunity = [];
              }

              // Exclude the current staff member from the list.
              staffInReferrerCommunity = staffInReferrerCommunity.filter((staff:any) => staff.personId != referrer.personId);

              // Loop through the list and add any that have proper emails.
              for(var i = 0; i < staffInReferrerCommunity.length; i++) {
                let referrerObj = ReferrerStaff.fromJson(staffInReferrerCommunity[i]);

                if(referrerObj != null && referrerObj != undefined && referrerObj.emailAddresses != null && referrerObj.emailAddresses != undefined && referrerObj.emailAddresses.length > 0) {
                  let emails = referrerObj.emailAddresses.filter(p=>p.is_primary && p.allowSalesContact);
                  if(emails != null && emails != undefined && emails.length > 0 && emails[0].address != null && emails[0].address != undefined && emails[0].address != "") {
                    // Just take one email if available from each of the other referrer staff in the community.
                    this.selectPeople.push({
                      personId: referrerObj.personId, 
                      name: referrerObj.nameDisplay, 
                      addressDisp: "<" + emails[0].address + ">", 
                      email: emails[0].address
                    });
                  }
                }
              }
            }
          }

          this.selectPeople.filter((person) => {
            if (person.email === this.data.selectedEmail){
              // Push person to people array if it doesn't exist in list yet.
              if (!this.people.some(p => p.email === person.email)) {
                this.people.push(person);
              }
            }
          });

          this.form.get('to')?.setValue(this.data.selectedEmail);
        }else{
          if (!this.selectPeople.some(person => person.email === this.data.personObj.primaryEmail?.address)) {
            let item = {
              personId: this.data.personObj.personId, 
              name: this.data.personObj.nameDisplay, 
              addressDisp: "<" + this.data.personObj.primaryEmail?.address + ">", 
              email: this.data.personObj.primaryEmail?.address
            }
            this.selectPeople.push(item);
            this.people.push(item);


            // This is for when you go to the Communication widget, the email tab, then click the add button.
            // KE: 09/27/2024: Bug 48196: The referrer staff was found, so include other staff from that same Community in the Choose Receipient list.
            if(this.data.personObj != null && this.data.personObj != undefined && this.data.personObj.referrerCommunity != null && this.data.personObj.referrerCommunity != undefined && this.data.personObj.referrerCommunity.referrerCommunityId != null && this.data.personObj.referrerCommunity.referrerCommunityId != undefined && this.data.personObj.referrerCommunity.referrerCommunityId != "") {
              let staffInReferrerCommunity = await this.api.get("referrerstaff/listcom/"+this.data.personObj.referrerCommunity.referrerCommunityId);
              if(staffInReferrerCommunity == null || staffInReferrerCommunity == undefined) {
                staffInReferrerCommunity = [];
              }

              // Exclude the current staff member from the list.
              staffInReferrerCommunity = staffInReferrerCommunity.filter((staff:any) => staff.personId != this.data.personObj.personId);

              // Loop through the list and add any that have proper emails.
              for(var i = 0; i < staffInReferrerCommunity.length; i++) {
                let referrerObj = ReferrerStaff.fromJson(staffInReferrerCommunity[i]);

                if(referrerObj != null && referrerObj != undefined && referrerObj.emailAddresses != null && referrerObj.emailAddresses != undefined && referrerObj.emailAddresses.length > 0) {
                  let emails = referrerObj.emailAddresses.filter(p=>p.is_primary && p.allowSalesContact);
                  if(emails != null && emails != undefined && emails.length > 0 && emails[0].address != null && emails[0].address != undefined && emails[0].address != "") {
                    // Just take one email if available from each of the other referrer staff in the community.
                    this.selectPeople.push({
                      personId: referrerObj.personId, 
                      name: referrerObj.nameDisplay, 
                      addressDisp: "<" + emails[0].address + ">", 
                      email: emails[0].address
                    });
                  }
                }
              }
            }
          }
          this.form.get('to')?.setValue(this.data.personObj.primaryEmail?.address);
        }
      }

      
      

      // else {
      //   if (this.data.email != null){
      //     if(this.data.email.fromPerson){
      //       this.selectPeople.push({
      //         personId: this.data.email.fromPerson.personId, 
      //         name: this.data.email.fromPerson.nameDisplay, 
      //         addressDisp: "<" + this.data.email.fromAddress + ">",
      //         email: this.data.email.fromAddress
      //       });
      //     }
      //     if(this.data.email.toPerson){
      //       this.selectPeople.push({
      //         personId: this.data.email.toPerson.personId, 
      //         name: this.data.email.fromPerson.nameDisplay,
      //         addressDisp: "<" + this.data.email.fromAddress + ">",
      //         email: this.data.email.toAddress
      //       });
      //     }
      //   }
          
     // }
   }

    console.log(this.selectPeople);
    console.log(this.people);
    this.loading = false;
    
  }

  removeAttachment(index: number){
    this.attachments.splice(index, 1);
  }

  removeTemplateAttachment(index: number){
    this.templateAttachments.splice(index, 1);
  }

  removeMarketingAttachment(index: number){
    this.marketingAttachments.splice(index, 1);
  }

  addMarketingFiles(files: any){
    this.marketingAttachments = files;
  }

  async sendEmail(){
    this.submitting = true;
    let formData: any = new FormData(); 
    let bodyBackup = this.form.get('body')?.value;
    
    //if it is a <p><br/></p> then replace with just a break
    this.form.get('body')?.setValue(this.form.get('body')?.value.replace(/<p><br><\/p>/g, ""));
    
    if(this.emailLink){
      this.form.get('body')?.setValue(this.form.get('body')?.value + "<br/>{{emailLinkBlock}}");
      formData.append("generateLink", this.emailLink);
    }

    if (this.signature && this.signature.message)
      this.form.get('body')?.setValue(this.form.get('body')?.value + "<br/>" + this.signature.message);

    if(this.data.itemType != null && (this.data.itemType == "Referrer" || this.data.itemType == "Staff") && this.data.personObj != null && this.data.personObj.personId != null && this.data.personObj.personId != undefined) {
      formData.append("target", this.data.personObj.personId.toString());
    } else if(this.prospect){
      formData.append("target", this.prospect.personId.toString());  
    }else{
      formData.append("target", this.data.email?.toPerson.personId);  
    }   
    Object.keys(this.form.controls).forEach(formControlName => {          
      formData.append(formControlName,  this.form.get(formControlName)?.value);
    });  

    if (this.attachments.length > 0){
      this.attachments.forEach((file: File) => {
       
          formData.append('attachments', file, file.name);
      });
    }

    if (this.templateAttachments.length > 0){
      for (let file of this.templateAttachments)
        formData.append('templateAttachments', file.guid);
    }

    if (this.marketingAttachments.length > 0){
      for (let file of this.marketingAttachments)
        formData.append('marketingAttachments', "company/"+file.fileType.companyId+"/marketingmaterial/"+file.marketingMaterialId+"."+file.filetype);
    }
   
    if(this.data.itemType != null && this.data.itemType != undefined) {
      formData.append("itemType", this.data.itemType.toString());  
    } else {
      formData.append("itemType", "");
    }
    
    if(this.nurseMode) {
      formData.append("nurseMode", "Yes");
    } else {
      formData.append("nurseMode", "No");
    }
    
    if(this.data.activityIds && this.data.activityIds.length > 0){
      formData.append("activityIds", this.data.activityIds);
    }

    // You can send emails to multiple people for the staff and community type emails. Need all the target ids in this scenario.
    // You can send emails to multiple people for the referrer and organization type emails. Need all the target ids in this scenario.
    var targetList = [];
    if(this.data.itemType == "Staff" || this.data.itemType == "Community" || this.data.itemType == "Referrer" || this.data.itemType == "Organization") {
      if (this.people != null && this.people != undefined && this.people.length > 0) {
        for (let person of this.people){
          if(person.personId != null && person.personId != undefined && person.personId != "") {
            targetList.push(person.personId);
          }
        }
      }
    }
    formData.append("targetList", targetList);

    this.http.post<any>(environment.API_URL + "/communication/sendEmailGoogle",formData).subscribe((resp) =>
      {
        if (resp.message === "success"){
          this.snackbar.open("Email sent successfully. Your sent message will appear in the email list shortly.", "OK", {duration: 3000});
          this.submitting = false;
          this.dialogRef.close("Success");
         }
         else if (resp.status === 400 && resp.message === "Unable to refresh token."){
          this.form.get('body')?.setValue(bodyBackup);
          this.authenticateGoogle();
          this.snackbar.open("Please authenticate your email.", "OK", {duration: 3000});
          this.submitting = false;
         }else if (resp.status === 406 && resp.message){
          this.form.get('body')?.setValue(bodyBackup);
          this.snackbar.open("Email failed to send. " + resp.message, "OK", {duration: 3000});
          this.submitting = false;
         }
         else{
          console.log(resp);
          this.form.get('body')?.setValue(bodyBackup);
          this.snackbar.open("Email failed to send. Please try again.", "OK", {duration: 3000});
          this.submitting = false;
         }
      },
      (error) => {
        this.submitting = false;
        this.form.get('body')?.setValue(bodyBackup);
        this.snackbar.open("Overall file size is too large. Please reduce file sizes.", "OK", {duration: 3000});
      },
      () => {this.submitting = false;});
  }

  async sendEventEmail(){
    this.submitting = true;
    let bodyBackup = this.form.get('body')?.value;

    //if it is a <p><br/></p> then replace with just a break
    this.form.get('body')?.setValue(this.form.get('body')?.value.replace(/<p><br><\/p>/g, ""));

    if (this.signature && this.signature.message)
      this.form.get('body')?.setValue(this.form.get('body')?.value + "<br/>" + this.signature.message);

    let formData: any = new FormData(); 
    
    //loop through emails in to form field and add the email addresses and person_id to formData
    let toEmails = this.form.get('to')?.value;
    let emails = [];
    for (let email of toEmails){
      if (email.emailAddresses && email.emailAddresses.length > 0)
        emails.push({email: email.emailAddresses[0].address, guid: email.personId});
    }
    emails.forEach((email, index) => {
      formData.append(`emails[${index}].email`, email.email);
      formData.append(`emails[${index}].guid`, email.guid);
    });
    formData.append("event_id", this.data.event.eventId);
    formData.append("community_id", this.data.event.community?.communityId);

    Object.keys(this.form.controls).forEach(formControlName => {  
      if (formControlName !== 'to')        
        formData.append(formControlName,  this.form.get(formControlName)?.value);
    });  

    if (this.attachments.length > 0){
      this.attachments.forEach((file: File) => {
       
          formData.append('attachments', file, file.name);
      });
    }

    if (this.templateAttachments.length > 0){
      for (let file of this.templateAttachments)
        formData.append('templateAttachments', file.guid);
    }

    if (this.marketingAttachments.length > 0){
      for (let file of this.marketingAttachments)
        formData.append('marketingAttachments', "company/"+file.fileType.companyId+"/marketingmaterial/"+file.marketingMaterialId+"."+file.filetype);
    }

    this.http.post<any>(environment.API_URL + "/communication/sendEventEmail",formData).subscribe((resp) =>
      {
        if (resp.message === "success"){
          this.snackbar.open("Email sent successfully.", "OK", {duration: 3000});
          this.submitting = false;
          this.dialogRef.close("Success");
         }
         else{
          console.log(resp);
          this.form.get('body')?.setValue(bodyBackup);
          this.snackbar.open("Email failed to send. Please try again.", "OK", {duration: 3000});
          this.submitting = false;
         }
      });
}

  async getFiles(event: any) {
    console.log(event);
    //add files to attachments array for upload
    if (event.target.files){
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size < 5242880)
          this.attachments.push(file);
        else
          this.snackbar.open("Individual file size must be less than 5MB", "OK", {duration: 3000});
      }
    }
  }

  

  async getOAuthStatus(){
    this.http.get<any>(environment.API_URL + "/communication/getOAuthStatus").pipe(untilDestroyed(this)).subscribe((resp) => {
      this.oauthStatus = resp;
      try{
        localStorage.setItem("oauthService", resp[0].service);
      }catch(e){

      }
      
    });
  }

  changeAutocomplete(group:FormGroup, type:string, value:any[]){
    value = value.map(v=>v['email']);
    group.controls[type].setValue(value);
  }

  async changeTemplate(value:any){
    this.templateAttachments = [];
    this.form.get('body')?.setValue("");
    this.form.get('subject')?.setValue("");
    
    if (value.length > 0){
      let last = value.slice(-1);
      this.selectedTemplate = last;
      let recipients = [];
      if (this.people.length > 0){
        for (let person of this.people){
          recipients.push(person.personId);
        }
      }
      let recordType = "";
      let recordId = "";

      if(this.prospect){
        recordType = "prospect";
        recordId = this.prospect.personId;
      }
      
      if (this.data.itemType === 'Staff'){
        recordType = "staff";
        recordId = this.data.personObj?.personId;
      }

      if (this.data.eventMode == true){
        recordType = "event";
        recordId = this.data.event.eventId;
      }

      if (this.type === 'Referrer' || this.type === 'Contact'){
        recordType = "referrer";
        recordId = this.data.personObj?.personId;
        if (recordId == null || recordId == undefined){
          recordId = this.data.personObj.organizationId;
        }
      }

      console.log(this.data);
      let request = {templateId: this.selectedTemplate[0]['templateId'], recordType: recordType, recordId: recordId, recipients: recipients};
      const template = await this.api.getEmailFromTemplate(request);
      console.log(template);
      this.form.get('body')?.setValue(template.message);
      this.form.get('subject')?.setValue(template.subject);
      if (template.attachments && template.attachments.length > 0){
        for (let file of template.attachments){
          this.templateAttachments.push(file);
        }
      }
    }
    
    //TODO: there may need to be a better way to determine we want to include a generated link in the template.
    if(this.selectedTemplate && this.selectedTemplate[0]?.name?.trim() == "Community Attributes"){
      this.emailLink = "communityAttributes";
    }else{
      this.emailLink = undefined;
    }
  }

  async getSignature(){
    const sigTemplates = await this.api.getSigTemplates();
    if (sigTemplates && sigTemplates.length > 0){
      let request = {templateId: sigTemplates[0]['templateId']};
      const template = await this.api.getSigFromTemplate(request);
      this.signature = template;
    }
  }

  authenticateGoogle(){
    this.http.get(environment.API_URL + "/communication/auth/google").pipe(untilDestroyed(this)).subscribe((resp: any) =>{
      const url = resp['map']['url'];
      console.log(url);
      //window.location.href=url;
      //this.openNewWindow(url);
      let height = 849;
      let width = 560;
      var left = ( screen.width - width ) / 2;
      var top = ( screen.height - height ) / 2;
      // if (this.googleService.googleWindow) {
      //   this.googleService.googleWindow.window = window.open(url,'googleAuth','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width='+width+',height='+height+',top='+top+',left='+left)!;
      // }
      this.win = window.open(url,'googleAuth','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width='+width+',height='+height+',top='+top+',left='+left)!;
    })
  }

  async publicFunc(){
    this.ngZone.run(async() => {
      this.privateFunc();
    });
    this.snackbar.open("Email account has been authenticated.", "OK", {duration: 5000});
    this.getOAuthStatus();
  }

  privateFunc(){
    this.win?.close();
  }

}
