import { Component, computed } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompanyConfig } from 'src/app/core/models/user.models';
import { AppService } from 'src/app/core/services/app.service';
import { CompanyCfgService } from 'src/app/core/services/company-cfg.service';

export interface LookupTableCfg {
  column: string
  prop: string
  name: string
  hidden?: boolean
  prop_type?: 'boolean' | 'select' | 'lookup-table' | 'number' | 'money'
  prop_lookup_table?: string
  prop_other_table?: string
  type_sort?:boolean
  max_length?:number
}
export class LookupTableReference {
  constructor(name: string, dataUrl: string, description: string, cfg: LookupTableCfg[]) {
    this.name = name;
    this.dataUrl = dataUrl;
    this.description = description;
    this.tableCfg = cfg;
  }
  name: string
  dataUrl: string
  description: string
  tableCfg: LookupTableCfg[]
}

@UntilDestroy()
@Component({
  selector: 'app-lookupview',
  templateUrl: './lookupview.component.html',
  styleUrls: ['../../../app.component.scss'],
  host: { 'class': 'twoColumnPage rightBias' }
})
export class LookupViewComponent {
  selectedLookupTable?: LookupTableReference;
  LookupTable = LookupTableReference;

  // Zach Hansen, 09/26/24, bug 48006: added max_length of 1024 to all name components(all lookup table db name lengths have been changed to 1024).
  // cont: Added max_length of 10 to gender abbreviation(this matches the db length)
  lookupTables = computed(()=>{
    let cfg = this.companyCfg.cfg();
    let tables = [
      new LookupTableReference("Activity Completed Reason", "activity_completed_reason", "The reason an Activity is marked as completed", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
        { column: "lookup-for-prospect", prop: "isSuccess", name: "Success", prop_type: "boolean" },
      ]),
      new LookupTableReference("Care Needs", "care_needs", "The prospect's care needs", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Cities and Towns", "city_town", "Cities and Towns in Colorado", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Current Care Giver", "care_giver", "The prospect's current caregiver", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Close Prospect Reasons", "close_prospect_reason", "The reason you are closing the prospect", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
        // Bug 47434: Don't plan to preadmit through the Close Prospect action anymore.
        // { column: "lookup-preadmit", prop: "preadmit", name: "Preadmit", prop_type: "boolean" },
        { column: "lookup-required", prop: "notesRequired", name: "Notes Required", prop_type: "boolean" }
      ]),
      new LookupTableReference("Community Amenities", "community_amenity", "Amenities offered by the Community", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Community Fitness Qualifiers", "community_fitness_qualifier", "The fitness qualifiers of the prospect", [
        { column: "lookup-name", prop: "name", name: "Qualifier" },
        { column: "lookup-yes-weight", prop: "yes_weight", name: "Yes Weight", prop_type: "number", hidden: cfg?.prospect_score_type.value.includes("Temperature") },
        { column: "lookup-no-weight", prop: "no_weight", name: "No Weight", prop_type: "number", hidden: cfg?.prospect_score_type.value.includes("Temperature") },
        { column: "lookup-weight", prop: "unknown_weight", name: "Unknown Weight", prop_type: "number", hidden: cfg?.prospect_score_type.value.includes("Temperature") },
        { column: "lookup-order", prop: "lookup_order", name: "Order", prop_type: "number" }
      ]),
      new LookupTableReference("Community Room Types", "community_room_type", "Room types available in a community", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Decision Criteria", "decision_criteria", "The criteria the prospect finds important", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Devices", "device", "The devices used by the Community", [
        { column: "lookup-type", prop: "type", name: "Device Type", max_length: 1024 },
        { column: "lookup-name", prop: "name", name: "Device", max_length: 1024 },
      ]),
      new LookupTableReference("Diagnoses", "diagnosis", "Common Diagnoses that a resident may have", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Prospect Stay Expectancy", "expected_stay", "The length of time the prospect will be staying", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Familiarity", "familiarity", "The familiarity the prospect has with the communities they are researching", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
        { column: "lookup-weight", prop: "weight", name: "Weight", prop_type: "number", hidden: cfg?.prospect_score_type.value.includes("Temperature") },
      ]),
      new LookupTableReference("Funding Sources", "funding_source", "Prospect Funding Sources", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Gender", "gender", "The genders the CRM uses", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
        { column: "lookup-abbr", prop: "abbreviation", name: "Abbreviation", max_length: 10 }
      ]),
      new LookupTableReference("Geographic Location-Neighborhood", "geographic_location_neighborhood", "Geographic Locations and Neighborhoods", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      // new LookupTableReference("Inquiry Type", "inquiry_type", "The inquiry types used by the CRM", [
      //   { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      // ]),
      new LookupTableReference("Immunizations", "immunization", "Common Immunizations that a resident may have", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Interests and Hobbies", "interests_hobbies", "The interests and hobbies of the prospect", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Level Of Care", "level_of_care", "The levels of care available and the price of each one", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
        { column: "lookup-amount", prop: "amount", name: "Amount", prop_type: "money" },
      ]),
      new LookupTableReference("Living Arrangements", "living_arrangements", "The current location the prospect lives", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Marital Status", "marital_status", "The marital status of the prospect", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Marketing File Type", "marketing_file_type", "The types available for Marketing Materials", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Move In Time", "move_in_time", "The timeframe in which the prospect wants to move", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
        { column: "lookup-weight", prop: "weight", name: "Weight", prop_type: "number", hidden: cfg?.prospect_score_type.value.includes("Temperature")},
        { column: "lookup-temperature-color", prop: "temperatureColor", name: "Color", 
          prop_type: "select", prop_other_table: "temperaturecolor",
          hidden: !cfg?.prospect_score_type.value.includes("Temperature") },
        { column: "lookup-temperature", prop: "temperature", name: "Temperature", 
          prop_type: "select", prop_other_table: "temperaturevalue",
          hidden: !cfg?.prospect_score_type.value.includes("Temperature") }
      ]),
      new LookupTableReference("Move Out Reasons", "move_out_reason", "The reasons a Prospect moved out", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
      ]),
      new LookupTableReference("Organization Type", "organization_type", "The types an organization can be", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Other Contact Type", "other_contact_type", "The types available for a Prospect's 'Other' contacts", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Product Type", "product_type", "The care types your community supports", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Prospect Score Based On", "prospect_score", "The items that contribute to the Prospect's score", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024, prop_type: "lookup-table" }
      ]),
      new LookupTableReference("Prospect Readiness", "readiness", "The willingness the prospect has towards moving", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
        { column: "lookup-weight", prop: "weight", name: "Weight", prop_type: "number", hidden: cfg?.prospect_score_type.value.includes("Temperature") }
      ]),
      new LookupTableReference("Reasons and Triggers", "reasons_triggers", "The reasons or triggers as to why the prospect is reaching out", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Referral Source", "referral_source", "The way the prospect found out about the community", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
        { column: "lookup-category", prop: "referral_soure_category_id", name: "Category", prop_type: "select", prop_lookup_table: "referral_source_category", type_sort:true }
      ]),
      new LookupTableReference("Referral Source Category", "referral_source_category", "The way the prospect found out about the community", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Relationships", "relation", "The relationship an influencer or referrer has with a prospect", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 }
      ]),
      new LookupTableReference("Sales Activities", "sales_activity", "Activities that Sales may do", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
        { column: "lookup-for-prospect", prop: "forProspect", name: "For Prospects", prop_type: "boolean" },
        { column: "lookup-for-referrer", prop: "forReferrer", name: "For Referrers", prop_type: "boolean" },
        { column: "action", prop: "action", name: "Action", prop_type: "select", prop_other_table: "activitytype" },
        { column: "lookup-update-last-contact", prop: "updateLastContact", name: "Update Last Contact on Completion", prop_type: "boolean" },
        { column: "lookup-only-log", prop: "onlyLog", name: "Only Log Activity", prop_type: "boolean" }
      ]),
      new LookupTableReference("User Role", "user_role", "Roles a user can have in a company", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
        { column: "lookup-type", prop: "userTypeId", name: "Type" , prop_type: "select", prop_other_table: "usertype" },
      ]),
      new LookupTableReference("Veteran Status", "veteran_status", "The veteran status of the prospect", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
        { column: "lookup-weight", prop: "weight", name: "Weight", prop_type: "number", hidden: cfg?.prospect_score_type.value.includes("Temperature") }
      ]),
      new LookupTableReference("Workflow Stages", "workflow_stage", "The workflow stages of your community", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
        { column: "lookup-order", prop: "lookup_order", name: "Order", prop_type: "number" }
      ]),
      new LookupTableReference("Workflow Stage Objective", "workflow_stage_todo", "The workflow stage objectives of your community", [
        { column: "lookup-name", prop: "name", name: "Name", max_length: 1024 },
        { column: "lookup-stage", prop: "workflowStageId", name: "Workflow Stage", prop_type: "select", prop_lookup_table: "workflow_stage" },
        { column: "lookup-required", prop: "required", name: "Required", prop_type: "boolean" }
      ])
    ];

    // Find Sales Activities item and add a column if this is an RCRM.
    if(this.companyCfg.referrerCRM()) {
      let salesCounselor = tables.find(f=>f.name === "Sales Activities");
      if(salesCounselor) {
        salesCounselor.tableCfg.push({ column: "lookup-prospect-add-community", prop: "prospectAddCommunity", name: "Prospect Add Community", prop_type: "boolean" });
      }
    }

    return tables;
  });

  constructor(private app:AppService, private route:ActivatedRoute, private router:Router, private companyCfg:CompanyCfgService) {    
    route.queryParams.pipe(untilDestroyed(this)).subscribe(async (p:any) => {
      let table = p.table;
      if(table && table != ""){
        this.selectedLookupTable = this.lookupTables().find(f=>f.dataUrl === table);
      }else{
        this.selectedLookupTable = this.lookupTables()[0];
      }
    });
  }
  selectLookupTable(tableRef: LookupTableReference) {
    this.app.sectionViewState.next("right");
    this.router.navigate([], {
      relativeTo:this.route,
      queryParams: {
        table: tableRef.dataUrl
      }
    })
  }
}
