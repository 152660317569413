import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User, Permission, PermissionUser, Community } from '../../models/user.models';
import { ApiService } from '../../services/api.service';
import { AppService } from '../../services/app.service';
import { UserPermissionWidget } from '../user-permission/user-permission.component';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-user-autoassign',
  templateUrl: './user-autoassign.component.html',
  styleUrls: ['../../../form-styles.scss', './user-autoassign.component.scss']
})
export class UserAutoassignComponent {
    user: User;
    tempLoad: User;

    communities: Community[] = [];
    staffMap: any = {};
    staffPerCommunity: any = {};
    autoAssign: any = new Map<string, string>();

    submitting: boolean = false;
    mode: string = 'AutoAssign'
    title: string = 'Auto Assign Web Leads';

    form!:FormGroup;

    constructor(
        protected app:AppService,
        protected snackbar: MatSnackBar,
        protected dialog: MatDialog,
        public dialogRef: MatDialogRef<UserPermissionWidget>,
        private api: ApiService,
        private fb:FormBuilder,
        private crd:ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.user = this.data.user;
        this.tempLoad = this.user;
        
        this.communities = JSON.parse(JSON.stringify(this.data.communities));
        this.staffMap = JSON.parse(JSON.stringify(this.data.staffMap));
        if (this.data.mode){
            this.mode = this.data.mode;
        }

        if (this.mode != 'AutoAssign') {
            this.title = 'ReAssign Prospects';
        }

        for (let community of this.communities) {
            this.autoAssign[community.communityId] = "";
        }
        
    }

    async ngOnInit() {
        if (this.mode === 'AutoAssign') {
            let assign = await this.api.getAutoAssign();
            //array of objects to autoassign map
            for (let item of assign) {
                this.autoAssign[item.communityId] = item.personId;
            }
        }else{
            for (let item of this.communities) {
                this.autoAssign[item.communityId] = this.user;
            }
        }
    }
    
    async ngAfterContentInit() {
    }

    ngAfterContentChecked(): void {
        this.crd.detectChanges();
    }
    
    async save() {
        this.submitting = true;
        let map = new Map<string, string>();

        for (var key of Object.keys(this.autoAssign)) {
            map.set(key, this.autoAssign[key]);
        }
        console.log(map);
        let array = Array.from(map, ([communityId, personId]) => ({ communityId, personId }));
        //loop through array keys
        //create map
        try{
            if (this.mode === 'AutoAssign') {
                await this.api.updateAutoAssign(array);
                this.snackbar.open('Updated auto assign web leads');
            }else{
                await this.api.reAssign({personId: this.user, assignments: array});
                this.snackbar.open('Prospects have been ReAssigned');
            }
        }catch(e){
            if (this.mode === 'AutoAssign') {
                this.snackbar.open('Error saving auto assign web leads');
            }
            else{
                this.snackbar.open('Error ReAssigning prospects');
            }
        }
        this.submitting = false;
        this.dialogRef.close();
    }

    compareObjects(o1: any, o2: any): boolean {
        return o1.personId === o2.personId;
      }

    ngOnDestroy() {
        
    }
}
