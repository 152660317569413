<mat-card appearance="outlined" class="mat-elevation-z4" matTooltip="Month to Date information about all Prospect Inquiries compared to one month ago.">
    <mat-card-header class="subText">
        New Inquiries (MTD)
    </mat-card-header>
    <mat-card-content (click)="openList()" matRipple>
        <div class="center" *ngIf="!loading">
            <mat-label>{{count}}</mat-label>
            <change-display [count]="count - prevMonth"></change-display>
        </div>
        <div class="center" *ngIf="loading">
            <load-spinner inline></load-spinner>
        </div>
    </mat-card-content>
</mat-card>