import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
    name: 'sortAndFilterByName',
    // pure:false
})
export class SortAndFilterByNamePipe implements PipeTransform {
    transform(value: any[], reverse:boolean, search:string): any[] {
        if(search != undefined && search != null){
            search = search.trim().toLowerCase();
        }else{
            search = "";
        }
        return value
            .filter(item => {
                if(search.length > 0){
                    return item.name.toLowerCase().includes(search);
                } else {
                    return true;
                }
            })
            .sort((n1,n2) => 
            {
                if(reverse){
                    return (n1.name > n2.name ? -1 : 1)
                }else{
                    return (n1.name > n2.name ? 1 : -1)
                }
            });
    }
}