import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { MarketingMaterialViewComponent } from './marketingmaterialview.component';
import { MarketingMaterialWidget } from 'src/app/core/widgets/marketingmaterial/marketingmaterial.widget';
import { MarketingMaterialListWidget } from 'src/app/core/widgets/marketingmaterial/marketingmateriallist.widget';



const routes:Routes = [
  {
    path: '', component: MarketingMaterialViewComponent,
    children: [
      {
        path: '',
        component: MarketingMaterialListWidget,
      },
      {
        path: 'edit',
        component: MarketingMaterialWidget
      }
    ]
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketingMaterialRoutingModule { }