import { Component, AfterViewInit, ViewChild, ElementRef, OnInit, Output, WritableSignal, signal, effect } from '@angular/core';
import { faAddressCard, faCalendarDay, faExpand, faFileCircleMinus, faGaugeHigh, faGears, faHandsClapping, faMapLocationDot, faPencil, faPersonCircleMinus, faPersonWalking, faSearch, faUpRightFromSquare, faUserPlus, faUserTie, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Event, IPageable, Person, Referrer, ReferrerCommunity, ReferrerStaff, SimplePerson } from '../../models/user.models';
import { ApiService } from '../../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { PersonService } from '../../services/person.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { ExpandableWidget } from '../helpers/expandablewidget.component';
import { AppService } from '../../services/app.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericConfirmDialogComponent } from '../../dialog/generic-confirm-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSort, Sort} from '@angular/material/sort';
import { AddressMapDialog, AddressMapDialogData } from '../../dialog/address-map/address-map.dialog';
import { ContactableAddresses } from '../../models/base.models';
import { DataPosition, InfiniteRequestData } from '../../directives/infinite-scroll-table.directive';
import { Page } from 'src/app/core/models/spring.models';
import { Subject, debounceTime } from 'rxjs';


interface EventSearch extends InfiniteRequestData{
  companyId:string
}

@UntilDestroy()
@Component({
  selector: 'event-list-widget',
  templateUrl: './eventlist.widget.html',
  styleUrls: ['./eventlist.widget.scss', '../../../table.responsive.scss']
})
export class EventListWidget extends ExpandableWidget implements AfterViewInit {
  editIcon = faPencil;
  removeMaterialIcon = faFileCircleMinus;
  inviteeIcon = faUsers;

  dataSource = new MatTableDataSource<Event>();
  compressedComPageColumns: string[] = ['name', 'date', 'time', 'budget', 'table-more'];
  expandedComPageColumns: string[] = ['name', 'date', 'time', 'host', 'budget', 'actualCost', 'table-more'];
  displayedColumns!: string[];
  footerColumns: string[] = [];
  rawData:Event[] = [];
  
  searchTotal:number|null = null;

  selected = signal<Event|null>(null);
  
  findDataSubscription:any;
  findDataUserSubscription:any;
  
  allCommunities:SimplePerson = new SimplePerson("", "All", "", "Communities")
  
  filter:Subject<any> = new Subject();  

  requestData:WritableSignal<EventSearch> = signal({companyId:"", search:"", count:20, page:0, ready:false})

  searchValue = signal("");

  constructor(
      protected override app:AppService,
      protected override route:ActivatedRoute, 
      protected override router:Router,
      private api:ApiService, 
      private personService:PersonService, 
      protected dialog: MatDialog,
      private snackbar:MatSnackBar) {
    super(app, route, router);

    this.compressWidget();

    route.queryParams.pipe(untilDestroyed(this))
    .subscribe(async (p:any) => {
      this.requestData.update(d=>({...d, page:null}));
    })
    
    personService.selectedEvent.pipe(untilDestroyed(this)).subscribe(item=>{
      this.selected.set(item);
    })
    personService.currentUser.pipe(untilDestroyed(this)).subscribe(u=>{
      if(u){
        this.requestData.update(d=>({...d, companyId:u.company.companyId, ready: true}))
      }
    });
  }
  
  @ViewChild(MatSort) sort!: MatSort;

  onScroll = async (page:number, position:DataPosition):Promise<Page<Event>> => {
    let foundData = await this.findData(this.requestData(), page, position);
    
    return foundData;
  }
  
  selectEvent(item:Event) {
    this.personService.selectedEvent.next(item);
  }
  
  trackEvent(index:number, item:Event) {
    return item.eventId;
  }
  
  editEvent(item:Event) {
    if (!this.personService.hasSomePermission(['events:add/update'])) {
      return;
    }

    this.personService.selectedEvent.next(item);
    this.router.navigate(['events', 'edit'],
      { 
        queryParams:{ 
          id: item.eventId
        }
    });
  }
  
  newEvent() {
    if (!this.personService.hasSomePermission(['events:add/update'])) {
      return;
    }

    this.personService.selectedEvent.next(new Event());
    this.router.navigate(['events', 'edit']);
  }
  
  async deleteEvent(item:Event) {
    if (!this.personService.hasSomePermission(['events:delete'])) {
      return;
    }
    
    const dialogRef = this.dialog.open(GenericConfirmDialogComponent, {
      disableClose: true,
      height: 'auto',
      width: 'auto',        
      data: {
        title: 'Delete Event "' + item.name + '"',
        content: `Event '${item.name}' will be deleted from ${item.community ? item.community.nameDisplay : 'this community'}. Are you sure?`
      },
    });
  
    dialogRef.afterClosed().subscribe(async result => {
      if (result === 'submit') {
        let resp = await this.api.delete(`events/${item.eventId}`);
        if(resp.message == 'success'){
          this.snackbar.open(`'${item.name}' was deleted.`);
          this.requestData.update(d=>({...d, page:null}));
        }else{
          this.snackbar.open(`Unable to delete '${item.name}'. ${resp.message}`);
        }
      }
    });
  }

  async findData(request:EventSearch, page:number, position:DataPosition): Promise<Page<Event>>{
    // if(!request.communityId){
    //   throw new Error("No Community");
    // }
    let data = await this.api.getPage("events/list", Event.fromJson, request.companyId, page, request.count, request.search, {expandedSearch: this.isExpanded});
    // let data = await {content:[] as Event[]} as Page<Event>;
    if (data.totalElements !== undefined && data.totalElements !== null) {
      this.searchTotal = data.totalElements;
    }

    switch(position){
      case DataPosition.Top:
        this.rawData = [...data.content, ...this.rawData];
        this.dataSource.data = [...this.rawData];
        break;
      case DataPosition.Bottom:
        this.rawData = [...this.rawData, ...data.content];
        this.dataSource.data = [...this.rawData];
        break;
      case DataPosition.Clear:
        this.rawData = data.content;
        this.dataSource.data = [...this.rawData];
        if(this.dataSource.data.length > 0 && this.selected() == null){
          this.personService.selectedEvent.next(this.dataSource.data[0]);
        }
        break;
    }

    return data;
  }

  changeFilter(val:string){
    this.filter.next(val);
  }
    
  protected override expandWidget(): void {
      super.expandWidget();
      this.displayedColumns = this.expandedComPageColumns;
  }
  
  protected override compressWidget(next?: ExpandableWidget | undefined): void {
      super.compressWidget(next);
      this.displayedColumns = this.compressedComPageColumns;
  }
  
  comingSoon(){
    this.snackbar.open("Coming Soon!");
  }

  protected getPersonName (person:Person) {
    if(person != null){
        // Zach Hansen, 05/23/24, bug 47658: this seamed like the wisest place to change, as Person.getPrettyName is widely used.
        // if person is null or the pretty name comes back as "Unknown", now attribute journey event to "Docentt System".
        let retVal = Person.getPrettyName(person);
        if ("Unknown" != retVal) {
            return retVal;
        }
    }
    return "None";
  }

}
