import { Component, Input, SimpleChanges } from '@angular/core';
import { INameDisplay } from '../../models/base.models';

@Component({
  selector: 'person-chip',
  templateUrl: './person-chip.component.html',
  styleUrls: ['./person-chip.component.scss']
})
export class PersonChipComponent {
    @Input() display?:INameDisplay|null = null;
    
    @Input() size?:"xsmall"|"small"|"medium" = "medium";
    
    @Input() width?:"auto"|"small" = "small";

    @Input() header?:any|undefined;
    headerText:boolean = false;
      
    ngOnInit() {
        this.header = this.header !== undefined && this.header !== false;
    }
  
    ngOnChanges(changes: SimpleChanges): void {
        let headerChange = changes["header"];
        if(headerChange && (headerChange.firstChange || headerChange.currentValue != headerChange.previousValue)){
            this.headerText = headerChange.currentValue !== undefined && headerChange.currentValue !== false;
        }
    }
}