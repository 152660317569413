<span>
    <span>
        <button mat-stroked-button *ngIf="phones.length > 0" [matMenuTriggerFor]="phoneMenu"><fa-icon [icon]="phoneIcon"></fa-icon></button>
        <mat-menu #phoneMenu="matMenu">
            <div *ngFor="let contact of phones">
                <mat-label>{{contact.community.nameDisplay}}</mat-label>
                <ng-container *ngFor="let phone of contact.phones">
                    <!-- <button *ngIf="usesAmazonConnect()" mat-menu-item (click)="selectCall(organization, phone)">
                        {{phone.number | mask:"(000) 000-0000" : { prefix: "" } }} {{phone.extension != null && phone.extension.length > 0 ? "Ext:" + phone.extension : ""}}
                    </button> -->
                    <button mat-menu-item>
                        <!-- <fa-icon *ngIf="phone.is_primary" [icon]="starIcon" class="primary-icon"></fa-icon> -->
                        <a href="tel:+1{{phone.number}}" style="color:inherit;text-decoration:none">{{phone.number | mask:"(000) 000-0000" : { prefix: "" } }} {{phone.extension != null && phone.extension.length > 0 ? "Ext:" + phone.extension : ""}}</a>
                    </button>
                <mat-divider></mat-divider>
                </ng-container>
            </div>
        </mat-menu>
    </span>
    <span>
        <button mat-stroked-button *ngIf="mobiles.length > 0" [matMenuTriggerFor]="messageMenu"><fa-icon [icon]="messageIcon"></fa-icon></button>
        <mat-menu #messageMenu="matMenu">
            <div *ngFor="let contact of mobiles">
                <mat-label>{{contact.community.nameDisplay}}</mat-label>
                <ng-container *ngFor="let phone of contact.phones">
                    <button mat-menu-item (click)="selectPhone(contact.community, phone)">
                        <!-- <fa-icon *ngIf="phone.is_primary" [icon]="starIcon" class="primary-icon"></fa-icon> -->
                        {{phone.number | mask:"(000) 000-0000" : { prefix: "" } }} {{phone.extension != null && phone.extension.length > 0 ? "Ext:" + phone.extension : ""}}
                    </button>
                    <mat-divider></mat-divider>
                </ng-container>
            </div>
        </mat-menu>
    </span>
    <span>
        <button mat-stroked-button *ngIf="emails.length > 0" [matMenuTriggerFor]="emailMenu"><fa-icon [icon]="emailIcon"></fa-icon></button>
        <mat-menu #emailMenu="matMenu">
            <div *ngFor="let contact of emails">
                <mat-label>{{contact.community.nameDisplay}}</mat-label>
                <ng-container *ngFor="let email of contact.emails">
                    <button class="oneline" mat-menu-item (click)="sendEmail(contact.community, email)">
                        {{email.address}}
                    </button>
                    <mat-divider></mat-divider>
                </ng-container>
            </div>
        </mat-menu>
    </span>
</span>