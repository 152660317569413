import { Injectable } from "@angular/core";

@Injectable()
export class AdvancedSearchService {
    public get newSearch() {
        return {} as {[key:string]:any}
    } 
    apply(name:string, search:{[key:string]:any}) {
        if(sessionStorage.getItem(name) != null && sessionStorage.getItem(name) != undefined) {
            let advancedSearchInputs = JSON.parse(sessionStorage.getItem(name) as string);
            if(advancedSearchInputs != null && advancedSearchInputs != undefined && Object.keys(advancedSearchInputs).length > 0) {
                for(let key in advancedSearchInputs) {
                    if(key != null && key != undefined) {
                        var value = advancedSearchInputs[key];
                        if(value != null && value != undefined) {
                            search[key] = value;
                        }
                    }
                }
            }
        }
    }
    save(name:string, search:{[key:string]:any}){
        sessionStorage.setItem(name, JSON.stringify(search));
    }
}