import { Component, Input, OnChanges, SimpleChanges, ViewChild, Inject, signal, effect, WritableSignal, input, computed } from '@angular/core';
import { faArrowLeft, faExpand, faGaugeHigh, faPencilSquare, faXmarkSquare } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../services/api.service';
import { MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PersonService } from '../../services/person.service';
import { LookupTableReference } from 'src/app/view/utilities/lookuptables/lookupview.component';
import { LookupTableModel } from '../../models/lookuptable.models';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../dialog/alert-dialog.component';
import { DialogData } from '../../dialog/alert-dialog.component';
import { GenericConfirmDialogComponent } from '../../dialog/generic-confirm-dialog.component';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataPosition, InfiniteRequestData } from '../../directives/infinite-scroll-table.directive';
import { CompanyCfgService } from '../../services/company-cfg.service';
import { AggregateTableReference } from 'src/app/view/utilities/aggregates/aggregateview.component';
import { Page } from '../../models/spring.models';
import { SearchRequestData } from '../../components/search-input/search-input.component';
import { INameDisplay } from '../../models/base.models';
import { Community, IPageable } from '../../models/user.models';
import { first, lastValueFrom, take } from 'rxjs';

interface AggregateTableSearch extends InfiniteRequestData{
  url:"occupancy"|"prospect"|"ebd"
  period:"daily"|"monthly"|"yearly"
}

class AggregateMap implements IPageable {
  constructor (obj:any){
    let hasMonth:boolean = false, hasDay:boolean = false;
    for(let col of Object.keys(obj)){
      if(col == "month"){
        hasMonth = true;
      } else if(col == "day"){
        hasDay = true;
      }
      
      if(col == "prospectStageJSON"){
        let stageData = JSON.parse(obj[col]);
        let stageArr:{ stage: string; count: number; }[] = [];
        for(let stage of Object.keys(stageData)){
          stageArr.push({stage:stage, count:stageData[stage]});
        }
        this.prospectStages = stageArr;
      }else{
        this[col] = obj[col];
      }
    }
    if(hasMonth && hasDay){
      let date = new Date(obj.year, obj.month - 1, obj.day);
      this.date = date.toISOString().substring(0, 10);
    }else if(hasMonth){
      let date = new Date(obj.year, obj.month - 1, 1);
      this.date = date.toISOString().substring(0, 7);
    }else{
      let date = new Date(obj.year, 1, 1);
      this.date = date.toISOString().substring(0, 4);
    }
  }
  date:String;
  pageNumber?:number;
  prospectStages?:{stage:string, count:number}[];
  [key:string]:any;
}

@UntilDestroy()
@Component({
  selector: 'aggregate-table-widget',
  templateUrl: './aggregatetable.widget.html',
  styleUrls: ['./aggregatetable.widget.scss', '../../../table.responsive.scss']
})
export class AggregateTableWidget implements OnChanges {
  aggregateTable = input.required<AggregateTableReference>();

  rawRequest:WritableSignal<InfiniteRequestData> = signal({
    page: null,
    search: "",
    ready: false,
    count: 20
  });
  requestData = computed<AggregateTableSearch>(()=>{
    let table = this.aggregateTable();
    return {
      ...this.rawRequest(),
      ready:this.selectedCommunity != null,
      url: table.dataUrl,
      period: table.dataPeriod
    }
  })
  
  cols = signal<{prop:string, name:string, isDate:boolean, dataType:"full"|"list"|"yearMonthDay"|"yearMonth"|"year"}[]>([]);
  displayedCols = computed(()=>[...this.cols().map(v=>v.prop)])
  
  communityList:Community[] = [];
  selectedCommunity:Community|null =null;
  menuOpened:boolean = false;
  communitySelectOpen(){
    this.menuOpened = true;
  }
  communitySelectClose(){
    this.menuOpened = false;
  }
  async selectCommunity(val:Community){
    this.personService.currentCommunity.next(val);
  }
  
  tableName = computed(()=>this.aggregateTable().name);
  description = computed(()=>this.aggregateTable().description);
  dashboardIcon = faGaugeHigh;
  dataSource = new MatTableDataSource<any>();
  rawData:any[] = [];
  
  searchTotal:number|null = null;

  @ViewChild('searchInput') searchInput!: any;

  searchValueChange = effect(()=>{
    let val = this.requestData().search;
    this.dataSource.data = this.rawData.filter(obj=>obj.name.toLowerCase().includes(val.toLowerCase()));
  })
  
  constructor(private api:ApiService, 
    private personService:PersonService, 
    protected dialog: MatDialog,
    private snackbar:MatSnackBar,
    private companyCfg:CompanyCfgService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    // Update view with given values
    this.personService.communities.pipe(untilDestroyed(this)).subscribe(async cs => {
      this.communityList = cs;

      this.personService.currentCommunity.pipe(take(1)).subscribe(async community => {
        let found = community;
        if(found == null){
          found = this.communityList[0];
        }
        if(found){
          await this.selectCommunity(found);
        }
      });
      
      this.personService.currentCommunity
        .pipe(untilDestroyed(this))
        .subscribe(async c=>{
            this.selectedCommunity = c;
            this.rawRequest.update(d=>({...d, page:null}))
        })
    });
  }
  
  toCapitalizedWords = (name:string, firstWordLimit?:string) => {
    let words = name.match(/[A-Za-z][a-z]*/g) || [];
    if(firstWordLimit && words && words[0]?.toLocaleLowerCase() == firstWordLimit.toLocaleLowerCase()){
      words.splice(0, 1);
    }
    return words.map(this.capitalize).join(" ");
  }

  capitalize = (word:string) => {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }
  
  onScroll = async (page:number, position:DataPosition):Promise<Page<any>> => {
    // this.requestData.count = requestSize;
    let foundData = await this.findDataImpl(this.requestData(), page, position);
    
    return foundData;
  }
  async findDataImpl(request:AggregateTableSearch, page:number, position:DataPosition) {
    let data: Page<AggregateMap>;
    let searchId = this.aggregateTable().community ? this.selectedCommunity?.id : this.personService.user()?.company.companyId;
    data = await this.api.pageData(`aggregatedata/${request.url}/${request.period}/${searchId}`, (o) => new AggregateMap(o), page, request.count, "");
    
    if (data.totalElements !== undefined && data.totalElements !== null) {
      this.searchTotal = data.totalElements;
    }
    else {
      this.searchTotal = null;
    }

    switch (position){
      case DataPosition.Top:
        this.rawData = [...data.content, ...this.rawData];
        this.dataSource.data = [...this.rawData];
        break;
      case DataPosition.Bottom:
        this.rawData = [...this.rawData, ...data.content];
        this.dataSource.data = [...this.rawData];
        break;
      case DataPosition.Clear:
        this.rawData = data.content;
        this.dataSource.data = [...this.rawData];
        if(this.rawData.length > 0){
          let cols = [];
          let isMonth = false, isDay = false;
          for(let col of Object.keys(this.rawData[0])){
            if(col == "date" || col == "year" || col == "month" || col == "day"|| col == "summaryId" || col == "recordId" || col == "companyId" || col == "communityId" || col == "datestamp"){
              if(col == "month"){
                isMonth = true;
              }else if(col == "day"){
                isDay = true;
              }
            }else{
              let dataType:"full"|"list"|"yearMonthDay"|"yearMonth"|"year" = "full";
              if(col == "prospectStages"){
                dataType = "list";
              }
              cols.push({
                prop:col,
                name:this.toCapitalizedWords(col),
                isDate:col == "lastUpdate",
                dataType: dataType,
              });
            }
          }
          let firstCols = [{
            prop:"date",
            name:"Date",
            isDate: true,
            dataType: isDay ? "yearMonthDay" : (isMonth ? "yearMonth" : "year") as "full"|"list"|"yearMonthDay"|"yearMonth"|"year",
          }]
          this.cols.set([...firstCols, ...cols]);
        }
        break;
    }
    return data;
  }
  
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    // if(changes["lookupTable"] != null && this.aggregateTable != null
    //   && (changes["lookupTable"].previousValue == null 
    //     || changes["lookupTable"].currentValue.name != changes["lookupTable"].previousValue.name)){
    //   this.tableName = this.aggregateTable.name;
    //   this.description = this.aggregateTable.description;
    //   this.displayedColumns = this.aggregateTable.tableCfg.filter(v=>!v.hidden).map(v=>v.column);
    //   this.displayedColumns.push(...this.endColumns);
    //   this.rawData = await this.api.getLookupTable(this.aggregateTable.dataUrl, false);
    //   this.dataSource.data = [...this.rawData];
    //   this.extraColData = {};
    //   this.nameIdMap = {};
    //   this.altSortId = "";
    //   for(let cfg of this.aggregateTable.tableCfg){
    //     if (cfg.prop_type == 'select' && cfg.type_sort) {
    //       this.altSortId = cfg.prop;
    //     }

    //     if(cfg.prop_type == 'select' && cfg.prop_lookup_table){
    //       let data = await this.api.getLookupTable(cfg.prop_lookup_table, false);
    //       this.extraColData[cfg.prop_lookup_table] = data;
    //       data.forEach((i:any)=>this.nameIdMap[i.guid] = i.name);
    //     }
    //     else if (cfg.prop_type == 'select' && cfg.prop_other_table) {
    //       if(cfg.prop_other_table == "usertype"){
    //         if (this.tableName == "User Role") {
    //           let data = await this.api.getUserTypes();
    //           this.extraColData[cfg.prop_other_table] = data;
    //           data.forEach((i:any)=>{
    //             i.id = i.typeId;
    //             i.name = i.typeName;
    //             this.nameIdMap[i.id] = i.name
    //           });  
    //         }
    //       }
    //       else if(cfg.prop_other_table == "activitytype"){
    //         let data = [
    //           {id:"", name:"General"},
    //           {id:"phone", name:"Call Now"},
    //           {id:"deposit", name:"Collect Deposit and Reserve"},
    //           {id:"tourSurvey", name:"Complete Tour Survey"},
    //           {id:"email", name:"Email"},
    //           {id:"emailNurses", name:"Email Nurses"},
    //           {id:"note", name:"Document General Notes"},
    //           {id:"appointmentNote", name:"Document Appointment Notes"},
    //           {id:"visitNote", name:"Document Visit Notes"},
    //           {id:"unschVisitNote", name:"Document Unscheduled Visit Notes"},
    //           {id:"meetupNote", name:"Document Professional Meetup Notes"},
    //           {id:"text", name:"Text With"},
    //         ]
    //         this.extraColData[cfg.prop_other_table] = data;
    //         data.forEach((i:any)=>{
    //           this.nameIdMap[i.id] = i.name
    //         });  
    //       }
    //       else if(cfg.prop_other_table == "temperaturecolor"){
    //         let data = [
    //           {id:"", name:"Unset"},
    //           {id:"red", name:"Red"},
    //           {id:"orange", name:"Orange"},
    //           {id:"yellow", name:"Yellow"},
    //           {id:"lime", name:"Lime"},
    //           {id:"green", name:"Green"},
    //           {id:"teal", name:"Teal"},
    //           {id:"blue", name:"Blue"},
    //           {id:"black", name:"Black"},
    //         ]
    //         this.extraColData[cfg.prop_other_table] = data;
    //         data.forEach((i:any)=>{
    //           this.nameIdMap[i.id] = i.name
    //         });  
    //       }
    //       else if(cfg.prop_other_table == "temperaturevalue"){
    //         let data = [
    //           {id:"", name:"Unknown"},
    //           {id:"hot", name:"Hot"},
    //           {id:"warm", name:"Warm"},
    //           {id:"cold", name:"Cold"},
    //         ];
    //         this.extraColData[cfg.prop_other_table] = data;
    //         data.forEach((i:any)=>{
    //           this.nameIdMap[i.id] = i.name
    //         });  
    //       }
    //     }
    //   }

    //   if (this.altSortId.length > 0) {
    //     this.dataSource.data.forEach((i:any)=>{
    //       i.typeName = this.nameIdMap[i[this.altSortId]];
    //     });
    //   }

    // Zach Hansen, 09/26/24, bug 48006: now correctly resets search text.
    this.rawRequest.update(d=>({...d, search:""}));
    if(this.searchInput != null && this.searchInput != undefined) {
      this.searchInput.clearData();
    }
  }
  
  trackRecord(index:number, record:{daykey:string}) {
    return record.daykey;
  }
}