import { E } from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { AbstractCommunity, Community, Prospect, Unit } from '../../models/user.models';

export interface PreadmitReasonDialogCfg{
    prospect:Prospect
    units:Unit[]
}

@Component({
    selector: 'preadmit-prospect-dialog-component',
    templateUrl: 'preadmit-prospect-dialog.component.html',
    styleUrls: ['preadmit-prospect-dialog.component.scss']
})
export class PreadmitProspectDialogComponent {
    public selectedReason:any = "";

    public prospect!:Prospect;//this is the id of the prospect
    public preadmit_date:number = 0;

    public formisvalid:boolean = false;

    allCommunities:AbstractCommunity[] = [];
    selectedCommunity:AbstractCommunity[] = [];
    allUnits:Unit[] = [];
    communityReserved:Unit[] = [];
    selectedUnit:any = null;
    
    compareUnitFunction = (o1: any, o2: any)=> o1.unitId===o2.unitId;

    constructor(public dialogRef: MatDialogRef<PreadmitProspectDialogComponent>, private api:ApiService, @Inject(MAT_DIALOG_DATA) public data: PreadmitReasonDialogCfg) {
        this.allUnits = data.units;
        this.prospect = data.prospect;

        // If only 1 community of interest then auto select it.
        if(this.prospect != null && this.prospect.communitiesOfInterest != null && this.prospect.communitiesOfInterest.length == 1) {
            this.selectedCommunity = [this.prospect.communitiesOfInterest[0]];
            this.communityReserved = this.allUnits.filter(u=>u.communityId == this.selectedCommunity[0].id)
            this.sortUnits();

            if(this.communityReserved != null && this.communityReserved.length > 0) {
                this.selectedUnit = [this.communityReserved[0]];
            }
        }
    }

    sortUnits(){
        this.communityReserved.sort((a,b) => {
          const nameA = a.nameDisplay.toUpperCase();
          const nameB = b.nameDisplay.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }else if (nameA > nameB) {
            return 1;
          }else{
            return 0;
          }
        });
      }

    onCancel(): void {
        this.dialogRef.close("cancel");
    }

    async onSubmit() {
        var saveObject:any = {};
        var now = Date.now()

        saveObject.preadmit_date = now;
        saveObject.personId = this.prospect.personId;
        saveObject.preadmitCommunityId = this.selectedCommunity[0].id;
        saveObject.reservedUnitId = this.selectedUnit[0].unitId;        
        
        await this.api.preadmitProspect(saveObject);
        
        this.dialogRef.close("success");
    }

    selectCommunity(event:Community[]){
        let last = event.slice(-1);
        this.selectedCommunity = last;
        if(this.selectedCommunity != null && this.selectedCommunity.length > 0) {
            this.communityReserved = this.allUnits.filter(u=>u.communityId == this.selectedCommunity[0].id)
            this.sortUnits();
            
            if(this.communityReserved != null && this.communityReserved.length > 0) {
                this.selectedUnit = [this.communityReserved[0]];
            }
        } else {
            this.communityReserved = [];
            this.selectedUnit = null;
        }
    }
    
    isValid() {
        let valid = true;
        if(this.selectedCommunity == null || this.selectedCommunity == undefined || this.selectedCommunity.length <= 0 || this.selectedUnit == null || this.selectedUnit == undefined || this.selectedUnit.length <= 0) {
            valid = false;
        }
        return valid;
    }
}