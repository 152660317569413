import { Component, computed } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompanyConfig } from 'src/app/core/models/user.models';
import { AppService } from 'src/app/core/services/app.service';
import { CompanyCfgService } from 'src/app/core/services/company-cfg.service';

export interface LookupTableCfg {
  column: string
  prop: string
  name: string
  hidden?: boolean
  prop_type?: 'boolean' | 'select' | 'lookup-table' | 'number'
  prop_lookup_table?: string
  prop_other_table?: string
  type_sort?:boolean
  max_length?:number
}
export class AggregateTableReference {
  constructor(name: string, dataUrl: "occupancy"|"prospect"|"ebd", dataPeriod: "daily"|"monthly"|"yearly", description: string, community: boolean, cfg: LookupTableCfg[]) {
    this.name = name;
    this.dataUrl = dataUrl;
    this.dataPeriod = dataPeriod;
    this.description = description;
    this.community = community;
    this.tableCfg = cfg;
  }
  name: string
  dataUrl: "occupancy"|"prospect"|"ebd"
  dataPeriod: "daily"|"monthly"|"yearly"
  description: string
  community:boolean
  tableCfg: LookupTableCfg[]
}

@UntilDestroy()
@Component({
  selector: 'app-aggregateview',
  templateUrl: './aggregateview.component.html',
  styleUrls: ['../../../app.component.scss'],
  host: { 'class': 'twoColumnPage rightBias' }
})
export class AggregateViewComponent {
  selectedAggregateTable?: AggregateTableReference;
  aggregateTables = computed(()=>{
    let cfg = this.companyCfg.cfg();
    let tables = [
      new AggregateTableReference("Daily Occupancy Aggregate", "occupancy", "daily", "Occupancy Summary", true, [
      ]),
      new AggregateTableReference("Monthly Occupancy Aggregate", "occupancy", "monthly", "Occupancy Aggregate Monthly", true, [
      ]),
      new AggregateTableReference("Yearly Occupancy Aggregate", "occupancy", "yearly", "Occupancy Aggregate Yearly", true, [
      ]),
      new AggregateTableReference("Daily Prospect Aggregate", "prospect", "daily", "Prospect Aggregate Daily", true, [
      ]),
      new AggregateTableReference("Monthly Prospect Aggregate", "prospect", "monthly", "Prospect Aggregate Monthly", true, [
      ]),
      new AggregateTableReference("Yearly Prospect Aggregate", "prospect", "yearly", "Prospect Aggregate Yearly", true, [
      ]),
      new AggregateTableReference("Daily EBD Aggregate", "ebd", "daily", "EBD Aggregate Daily", false, [
      ]),
      new AggregateTableReference("Monthly EBD Aggregate", "ebd", "monthly", "EBD Aggregate Monthly", false, [
      ]),
      new AggregateTableReference("Yearly EBD Aggregate", "ebd", "yearly", "EBD Aggregate Yearly", false, [
      ]),
    ];

    return tables;
  });

  constructor(private app:AppService, private route:ActivatedRoute, private router:Router, private companyCfg:CompanyCfgService) {    
    route.queryParams.pipe(untilDestroyed(this)).subscribe(async (p:any) => {
      let table = p.table;
      let period = p.period;
      if(table && period){
        this.selectedAggregateTable = this.aggregateTables().find(f=>f.dataUrl === table && f.dataPeriod === period);
      }else{
        this.selectedAggregateTable = this.aggregateTables()[0];
      }
    });
  }
  selectAggregateTable(tableRef: AggregateTableReference) {
    this.app.sectionViewState.next("right");
    this.router.navigate([], {
      relativeTo:this.route,
      queryParams: {
        table: tableRef.dataUrl,
        period: tableRef.dataPeriod
      }
    })
  }
}
