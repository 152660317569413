<h1 mat-dialog-title>Companies</h1>
<mat-dialog-content>
  <mat-form-field class="theContent">
    <mat-label>Current Company</mat-label>
    <mat-select [ngModel]="companyId" (ngModelChange)="companyId = $event">
      <mat-option *ngFor="let cItem of companies" [value]="cItem.companyId">{{cItem.companyName}}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <load-spinner inline *ngIf="submitting"></load-spinner>
  <button mat-stroked-button color="primary" mat-dialog-close>Close</button>
  <button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="submitting || companyId == undefined">Update and Reload Page</button>
</div>
