import { Component, computed, effect, input, output, signal } from "@angular/core";
import { IContactable } from "../../models/base.models";
import { DataPosition, InfiniteRequestData } from "../../directives/infinite-scroll-table.directive";
import { Page } from "../../models/spring.models";
import { ApiService } from "../../services/api.service";
import { faEnvelopeCircleCheck, faEnvelopeOpen, faHandPointer, faSquare, faSquareCheck, faSquareMinus, faTruck, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { EventInvite } from "../../models/user.models";
import { CompanyCfgService } from "../../services/company-cfg.service";

export interface ContactableConfig{
    ids: string[],
    allSelected: boolean,
    name:string, 
    requestCfg:InfiniteRequestData, 
    results:IContactable[],
    selected:IContactable[],
    searchTotal:number,
    dataLoad: (page:number, position:DataPosition)=>Promise<Page<IContactable>>
}

@Component({ 
    selector: 'contactable-select',
    templateUrl: './contactable-select.component.html',
    styleUrls: ['./contactable-select.component.scss']
})
export class ContactableSelect {
    emailedIcon = faEnvelopeCircleCheck;
    checkIcon = faSquareCheck;
    boxIcon = faSquare;
    partialIcon = faSquareMinus;
    deliverIcon = faTruck;
    openIcon = faEnvelopeOpen;
    clickIcon = faHandPointer;
    bounceIcon = faXmarkCircle;

    selectedChange = output<IContactable[]>({alias:'selected'});
    filters = input.required<ContactableConfig[]>();
    alreadyContacted = input.required<EventInvite[]>();

    referrerCRM = this.companyCfg.referrerCRM;

    constructor(private companyCfg:CompanyCfgService){
    }
    
    selected = signal<IContactable[]>([]);
    selectionMap = computed(()=>{
        let map:{[key:string]:boolean} = {}
        this.selected().forEach(p=>{
            map[p.id] = true;
        })
        
        return map;
    })
    contactedMap = computed(()=>{
        let map:{[key:string]:EventInvite} = {}
        this.alreadyContacted().forEach(p=>{
            map[p.personId] = p;
        })
        return map;
    })

    filterChange = effect(()=>
    {
        this.filters().forEach(f=>{
            f.selected = [];
            f.allSelected = false;
        });
        this.selected.set([]);
    }, {allowSignalWrites:true})
    
    toggleSelect = (cfg:ContactableConfig) => {
        if(cfg.selected.length == 0){
            cfg.selected = [...cfg.results.filter(p=>!this.contactedMap()[p.id]?.sent && !p.emailAddresses[0].hardBounce)];
            cfg.allSelected = true;
        }else{
            cfg.selected = [];
            cfg.allSelected = false;
        }
        
        let allSelected:IContactable[] = [];
        this.filters().forEach(f=>{
            // If this filter has the allSelected flag set then add all the results to the selected list that haven't been loaded by the paging yet.
            // When select all is used not all the pages may be loaded, add placeholders for these with the ids so the Send Invites has the correct count.
            if(f.name != cfg.name && f.allSelected && allSelected.length != f.ids.length) {
                // loop through ids and add missing objects to allSelected.
                f.ids.forEach(id => {
                    let found = f.selected.find(p=>p.id == id);
                    if(found == null || found == undefined) {
                        let obj:IContactable = {
                            id: id,
                            mailAddresses: [],
                            emailAddresses: [],
                            phoneNumbers: [],
                            nameDisplay: "",
                            nameAbbreviation: ""
                        };
                        allSelected.push(obj);
                    }
                });
            }

            allSelected.push(...f.selected);
        });

        // When select all is used not all the pages may be loaded, add placeholders for these with the ids so the Send Invites has the correct count.
        if(cfg.allSelected && allSelected.length != cfg.ids.length) {
            // loop through ids and add missing objects to allSelected.
            cfg.ids.forEach(id => {
                /*let found = allSelected.find(p=>p.id == id);
                if(found == null || found == undefined) {
                    let obj:IContactable = {
                        id: id,
                        mailAddresses: [],
                        emailAddresses: [],
                        phoneNumbers: [],
                        nameDisplay: "",
                        nameAbbreviation: ""
                    };
                    allSelected.push(obj);
                }*/
            });
        }

        this.selected.set(allSelected);
        
        this.selectedChange.emit(this.selected());
    }
    
    checkIconDisplay = (cfg:ContactableConfig, selectMap:{[key: string]: boolean;}, contactMap:{[key: string]: EventInvite;}) => {
        // let available = cfg.results.filter(p=>!contactMap[p.id]);
        // let selected = cfg.results.filter(p=>selectMap[p.id]);
        // if(selected.length == 0){
        //     return this.boxIcon;
        // }else if(selected.length == available.length){
        //     return this.checkIcon;
        // }else{
        //     return this.partialIcon;
        // }

        let selected = cfg.results.filter(p=>selectMap[p.id]);
        if(selected.length == 0) {
            return this.boxIcon;
        } else if(cfg.allSelected) {
            return this.checkIcon;
        } else {
            return this.partialIcon;
        }
    };

    reset = () => {
       this.filters().forEach(f=>{
            f.allSelected = false;
            f.selected = [];
        })
        this.selected.set([]);
        this.selectedChange.emit(this.selected());
    }

    selectPerson = (item:IContactable, cfg:ContactableConfig) => {
        if ((this.contactedMap()[item.id] && this.contactedMap()[item.id].sent) || item.emailAddresses[0].hardBounce){
            return;
        }
        let idx = cfg.selected.findIndex(p=>p.id == item.id)
        let arr = cfg.selected;
        if(idx >= 0){
            arr.splice(idx, 1);
            cfg.selected = [...arr];
        }else{
            cfg.selected = [...arr, item];
        }
        let allSelected:IContactable[] = [];
        this.filters().forEach(f=>{
            // If this filter has the allSelected flag set then add all the results to the selected list that haven't been loaded by the paging yet.
            // When select all is used not all the pages may be loaded, add placeholders for these with the ids so the Send Invites has the correct count.
            if(f.name != cfg.name && f.allSelected && allSelected.length != f.ids.length) {
                // loop through ids and add missing objects to allSelected.
                f.ids.forEach(id => {
                    let found = f.selected.find(p=>p.id == id);
                    if(found == null || found == undefined) {
                        let obj:IContactable = {
                            id: id,
                            mailAddresses: [],
                            emailAddresses: [],
                            phoneNumbers: [],
                            nameDisplay: "",
                            nameAbbreviation: ""
                        };
                        allSelected.push(obj);
                    }
                });
            }

            allSelected.push(...f.selected);
        });

        cfg.allSelected = cfg.selected.length == cfg.results.length;

        // When select all is used not all the pages may be loaded, add placeholders for these with the ids so the Send Invites has the correct count.
        if(cfg.allSelected && allSelected.length != cfg.ids.length) {
            // loop through ids and add missing objects to allSelected.
            cfg.ids.forEach(id => {
                let found = allSelected.find(p=>p.id == id);
                if(found == null || found == undefined) {
                    let obj:IContactable = {
                        id: id,
                        mailAddresses: [],
                        emailAddresses: [],
                        phoneNumbers: [],
                        nameDisplay: "",
                        nameAbbreviation: ""
                    };
                    allSelected.push(obj);
                }
            });
        }

        this.selected.set(allSelected);
        
        this.selectedChange.emit(this.selected());
    }

    selectNone = (cfgName:any) => {
        let cfg = this.filters().find(p=>p.name == cfgName);
        if(cfg != null && cfg != undefined) {
            cfg.selected = [];
            this.selected.set([]);
            this.selectedChange.emit(this.selected());
        }
    }

    selectAllForTab = (cfgName:any) => {
        // Find the config in the filters by name.
        let cfg = this.filters().find(p=>p.name == cfgName);

        if(cfg != null && cfg != undefined && cfg.allSelected) {
            cfg.selected = [...cfg.results.filter(p=>!this.contactedMap()[p.id]?.sent)];
            
            let allSelected:IContactable[] = [];
            this.filters().forEach(f=>{
                // If this filter has the allSelected flag set then add all the results to the selected list that haven't been loaded by the paging yet.
                // When select all is used not all the pages may be loaded, add placeholders for these with the ids so the Send Invites has the correct count.
                if(f.name != cfg.name && f.allSelected && allSelected.length != f.ids.length) {
                    // loop through ids and add missing objects to allSelected.
                    f.ids.forEach(id => {
                        let found = f.selected.find(p=>p.id == id);
                        if(found == null || found == undefined) {
                            let obj:IContactable = {
                                id: id,
                                mailAddresses: [],
                                emailAddresses: [],
                                phoneNumbers: [],
                                nameDisplay: "",
                                nameAbbreviation: ""
                            };
                            allSelected.push(obj);
                        }
                    });
                }

                allSelected.push(...f.selected);
            });

            // When select all is used not all the pages may be loaded, add placeholders for these with the ids so the Send Invites has the correct count.
            if(cfg.allSelected && allSelected.length != cfg.ids.length) {
                // loop through ids and add missing objects to allSelected.
                cfg.ids.forEach(id => {
                    let found = allSelected.find(p=>p.id == id);
                    if(found == null || found == undefined) {
                        let obj:IContactable = {
                            id: id,
                            mailAddresses: [],
                            emailAddresses: [],
                            phoneNumbers: [],
                            nameDisplay: "",
                            nameAbbreviation: ""
                        };
                        allSelected.push(obj);
                    }
                });
            }

            this.selected.set(allSelected);
            
            this.selectedChange.emit(this.selected());
        }
    };
}