<form [formGroup]="phoneGroup" class="flex-form">
    <span>
        <div *ngFor="let phone of data; let last = last; let index = index" class="addEntryRow">
            <div>
                <span class="entryContent phoneContent" [ngClass]="{assessmentPhoneContent: assessmentBlock}">
                    <mat-form-field ngDefaultControl appearance="fill" class="leftPhone">
                        <input type="text" matInput mask="(000) 000-0000" placeholder="(XXX) XXX-XXXX" formControlName="phone{{index}}" (keyup)="changePhone(index)">
                    </mat-form-field>
                    <mat-form-field ngDefaultControl appearance="fill" class="middleExtension">
                        <input type="text" matInput placeholder="Ext." formControlName="phone_ext{{index}}" (keyup)="changePhoneExt(index)" [attr.maxlength]="30">
                    </mat-form-field> 
                    <mat-button-toggle-group formControlName="phone_type{{index}}" (change)="changePhoneType(index)" class="rightPhoneTypes">
                        <mat-button-toggle value="M" matTooltip="Mobile" matTooltipPosition="above">M</mat-button-toggle>
                        <mat-button-toggle *ngIf="!hideHome" value="H" matTooltip="Home" matTooltipPosition="above">H</mat-button-toggle>
                        <mat-button-toggle value="O" matTooltip="Office" matTooltipPosition="above">O</mat-button-toggle>
                        <mat-button-toggle value="F" matTooltip="Fax" matTooltipPosition="above">F</mat-button-toggle>
                    </mat-button-toggle-group>

                    <div class="mat-form-field top-checkbox phoneMarketing" *ngIf="!hideAllow">
                        <mat-label class="form-group-label">Allow <br/> Marketing</mat-label>
                        <mat-checkbox formControlName="allowMarketing{{index}}" (change)="changeAllowMarketing(index)"></mat-checkbox>
                    </div>

                    <div class="mat-form-field top-checkbox phoneSales" *ngIf="!hideAllow">
                        <mat-label class="form-group-label">Allow Sales <br/> Contact Texting</mat-label>
                        <mat-checkbox formControlName="allowSalesContact{{index}}" (change)="changeAllowSalesContact(index)"></mat-checkbox>
                    </div>

                    <div class="mat-form-field top-checkbox phoneCallsSales" *ngIf="!hideAllow">
                        <mat-label class="form-group-label">Allow Sales <br/> Contact Calls</mat-label>
                        <mat-checkbox formControlName="allowSalesContactCalls{{index}}" (change)="changeAllowSalesContactCalls(index)"></mat-checkbox>
                    </div>
                </span>
            </div>
            <fa-icon *ngIf="!disabled" [icon]="starIcon" [class.primary]="phone.is_primary" (clickOrKey)="changePrimary(phone)" matTooltip="Make Primary" matTooltipPosition="above"></fa-icon>
            <fa-icon *ngIf="disabled" [icon]="starIcon" class="primary" matTooltip="Primary" matTooltipPosition="above"></fa-icon>
            <fa-icon *ngIf="!disabled" [icon]="deleteIcon" (clickOrKey)="removeItem(phone)" matTooltip="Remove Phone" ></fa-icon>
        </div>
        <div class="emptyEntryRow" *ngIf="data.length === 0">
            <mat-label>No phone number on file</mat-label>
        </div>
        <floating-add-button (clickOrKey)="addPhone()" class="nestedFormAdd" *ngIf="!disabled"></floating-add-button>
    </span>
</form>