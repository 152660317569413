<h1 mat-dialog-title>{{title}}</h1>
    <mat-dialog-content>
    <div class="padded-group">
        <div class="mat-excavation-group mat-excavation-z4">
            <div class="expanded-row with-raised-section">
                <div *ngFor="let community of communities" class="form-row mat-elevation-z4 raisedFormSection">
                    <div class="fill-row">
                        {{community.communityName}}
                        <mat-select [(value)]="autoAssign[community.communityId]">
                            <mat-option value="" selected>Unassigned</mat-option>
                            <mat-option *ngFor="let staff of staffMap[community.communityId]" [value]="staff.personId">
                                {{staff.firstname}} {{staff.lastname}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
    </mat-dialog-content>

    <div mat-dialog-actions align="end">
        <button *ngIf="!submitting" mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
        <button mat-flat-button color="primary" (click)="save()" [disabled]="submitting">Submit</button>
        <load-spinner inline *ngIf="submitting"></load-spinner>
    </div>