import { DatePipe } from "@angular/common";
import { Component, Input, computed } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Community } from "src/app/core/models/user.models";
import { DashboardService } from "src/app/core/services/dashboard.service";
import { OccupancyService } from "src/app/core/services/occupancy.service";
import { PersonService } from "src/app/core/services/person.service";

@UntilDestroy()
@Component({
    selector: 'summary-dash',
    templateUrl: './summary.component.html',
    styleUrls: ['../graph-widget-style.scss']
  })
  export class SummaryDashWidget {
    @Input() title!:string;
    @Input() prop!:string;
    @Input() pluralable:boolean = true;
    
    // summary?:OccupancySummary;

    loading = computed(()=>{
      return this.occupancyService.loading()
    })
    tooltipText = computed(()=>{
      if(this.loading()){
        return `Total ${this.title}s in ${this.currentCommunity?.nameDisplay}`;
      }else{
        if(this.pluralable){
          return `${this.value()} ${this.title}(s) are in ${this.currentCommunity?.nameDisplay}`;
        }else{
          return `${this.value()} ${this.title} in ${this.currentCommunity?.nameDisplay}`;
        }
      }
    });
    
    currentCommunity:Community|null = null;
    
    value = computed(()=>{
      let summary = this.occupancyService.occupancySummary();
        let obj = (summary as any);
        if(obj && obj[this.prop] != null) {
          return obj[this.prop] as number;
        }else{
          return 0;
        }
    });
    
    
    constructor(
        private snackbar:MatSnackBar,
        personService:PersonService, 
        dashboard:DashboardService, 
        private occupancyService:OccupancyService){
      personService.currentCommunity.pipe(untilDestroyed(this)).subscribe(async community=>{
        this.currentCommunity = community;
      });
    }
    
  }