import { Component, computed, input, Input, InputSignal, signal } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EChartsOption } from "echarts";
import { User } from "src/app/core/models/user.models";
import { GraphApiService } from "src/app/core/services/graphapi.service";
import { PersonService } from "src/app/core/services/person.service";
import { ProspectFilter } from '../../../prospect/ProspectFilter';
import { DashboardService } from "src/app/core/services/dashboard.service";

@UntilDestroy()
@Component({
    selector: 'temperature-prospects-dash',
    templateUrl: './temperature-prospects.component.html',
    styleUrls: ['../graph-widget-style.scss']
  })
  export class TemperatureProspectsDashWidget {
    temperature = input<"hot"|"warm"|"cold"|"">("hot");
    top:number = 0;
    count:number = 0;
    
    temperatureDisplay = computed(()=>{
      switch (this.temperature()){
        case "hot":
          return "Hot";
        case "warm":
          return "Warm";
        case "cold":
          return "Cold";
        case "":
        default:
          return "Unknown";
      }
    })
    
    tooltipText = computed(()=>{
      if(this.loading()){
        return `Prospects that are ${this.temperatureDisplay()}.`;
      }else{
        return `${this.top}/${this.count} Prospects are ${this.temperatureDisplay()}.`;
      }
    })
    
    // prevMonth:number = 0;
    loading = signal(false);
    chartCfg:EChartsOption = {};
    currentUser:User|null = null;
    
    constructor(private snackbar:MatSnackBar, private graphApi:GraphApiService, private personService:PersonService, private router:Router){
      personService.currentUser.pipe(untilDestroyed(this)).subscribe(async user=>{
        this.currentUser = user;
      });
      personService.reloadProspectWidgets.pipe(untilDestroyed(this)).subscribe(async p =>{
        // Reload data when prospects are updated, since the value for this widget could change.
        if(this.currentUser?.company){
          this.loading.set(true);
          let resp = await graphApi.getTemperatureProspects(this.currentUser.company.companyId, this.temperature(), []); // Pass empty list for communities since they aren't used in server call anyway.

          // Only set the data if a proper value was returned.
          if(resp != null && resp.requestedCount != null && resp.comparisonCount != null) {
            this.top = resp.requestedCount;
            this.count = resp.comparisonCount;
          }

          this.chartCfg = DashboardService.buildPie(this.top, this.count - this.top, this.temperatureDisplay());
          this.loading.set(false);
        }
      });
      personService.communities.pipe(untilDestroyed(this)).subscribe(async communities =>{
        if(this.currentUser?.company){
          this.loading.set(true);
          let resp = await graphApi.getTemperatureProspects(this.currentUser.company.companyId, this.temperature(), communities.filter(c=>c.selected).map(c=>c.communityId));

          // Only set the data if a proper value was returned.
          if(resp != null && resp.requestedCount != null && resp.comparisonCount != null) {
            this.top = resp.requestedCount;
            this.count = resp.comparisonCount;
          }

          this.chartCfg = DashboardService.buildPie(this.top, this.count - this.top, this.temperatureDisplay());
          this.loading.set(false);
        }
      });
    }

    
    openList(){
      // Don't want to filter the prospects page anymore when this is clicked. Do nothing for now.
      // KE: 08/09/2024: Bug 47978: Clicking widgets need to filter table again.
      this.router.navigate(["prospect"], {
        queryParams: {
          prospecttop: true
        }
      });
      // Make small timout. So URL changes before calling the code to refresh.
      setTimeout(()=>{
        this.personService.widgetClickAction.next([]); // This will cause data to refresh.
      }, 100);
    }
    
    comingSoon(){
      this.snackbar.open("Coming Soon");
    }
  }