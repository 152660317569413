import { Component, AfterViewInit, WritableSignal, signal, computed } from '@angular/core';
import { CommunityOfInterest, PlacementHistory, Prospect, ReferrerCommunity } from '../../models/user.models';
import { ApiService } from '../../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { PersonService } from '../../services/person.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { AppService } from '../../services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Page } from '../../models/spring.models';
import { DataPosition, InfiniteRequestData } from '../../directives/infinite-scroll-table.directive';
import { faExclamationCircle, faFileCirclePlus, faFilePen, faHouseCircleCheck, faMoneyBillTransfer, faPeopleArrows, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { ExpandedWidget } from '../helpers/expandedwidget.component';
import { FindCommunitiesBulkSnackBar, FindCommunitiesBulkSnackBarData } from './findcommunities-bulk.snack-bar';
import { CommunitySearchDialog, CommunitySearchDialogData } from '../../dialog/community-search/community-search.dialog';
import { PersonSearchDialog } from '../../dialog/person-search/person-search.dialog';
import { ReferrerStaffSearchDialog, ReferrerStaffSearchDialogData } from '../../dialog/referrerstaff-search/referrerstaff-search.dialog';

interface CommunityOfInterestRequest extends InfiniteRequestData{
  prospectId?:string
}

@UntilDestroy()
@Component({
  selector: 'find-communities-list-widget',
  templateUrl: './findcommunitieslist.widget.html',
  styleUrls: ['./findcommunitieslist.widget.scss', '../../../table.responsive.scss'],
})
export class FindCommunitiesList extends ExpandedWidget implements AfterViewInit {  
  dataSource = new MatTableDataSource<CommunityOfInterest>();
  rawData:CommunityOfInterest[] = [];

  multiSelected:{multiple:boolean, all:boolean, [key:string]:boolean} = {multiple: false, all:false};
  selected:CommunityOfInterest|null = null;
  public chosen = signal<CommunityOfInterest|null>(null);
  public estMoveIn = signal<Date|null>(null);
  chosenId = computed(()=>{
    const chosen = this.chosen();
    return chosen ? chosen.guid : "";
  })
  displayedColumns = computed(()=>{
    if(this.chosen()){
      return ['user-icon', 'contact-method', 'associated-staff', 'product-type', 'est-move-in', 'table-more'];
    }else{
      return ['user-icon', 'contact-method', 'associated-staff', 'product-type', 'table-more'];
    }
  })

  findCommunitiesIcon = faHouseCircleCheck;
  addInvoiceIcon = faFileCirclePlus;
  depositeIcon = faMoneyBillTransfer;
  editInvoiceIcon = faFilePen;
  placeProspectIcon = faPeopleArrows;
  refStaffIcon = faUserTie;
  warnIcon = faExclamationCircle;
  
  user = this.personService.user;
  prospect = signal<Prospect|null>(null);
  submitting = signal<boolean>(false);
  
  selectedIds:string[] = [];
  
  
  constructor(
    protected override app:AppService,
    protected override route:ActivatedRoute, 
    protected override router:Router,
    private api:ApiService, 
    private personService:PersonService, 
    protected dialog: MatDialog,
    private snackbar:MatSnackBar) {
      super(app, route, router);
      route.queryParams.pipe(untilDestroyed(this)).subscribe((data:any) => {
        this.api.getFullProspect(data.id).then(p=>{
          this.prospect.set(p);
        });
        this.requestData.update(d=>({
          ...d,
          prospectId: data.id,
          ready: true
        }));
        this.api.get(`communityofinterest/communities/${data.id}`).then(ids=>{
          this.selectedIds = ids;
        });
        this.api.get(`communityofinterest/chosen/${data.id}`).then(item=>{
          if(item){
            this.chosen.set(CommunityOfInterest.fromJson(item));
          }else{
            this.chosen.set(null);
          }
        });
        this.api.get(`prospect/getReserveDeposit/${data.id}`).then(rawObj=>{
          if(rawObj && rawObj.estMoveInDate){
            this.estMoveIn.set(new Date(rawObj.estMoveInDate));
          }else{
            this.estMoveIn.set(null);
          }
        });
      });
    
      router.events.pipe(untilDestroyed(this)).subscribe((event) => {
        if(event instanceof NavigationStart){
          this.multiSelected = {multiple:false, all:false};
          this.closeBulkOptions();
        }
      });
  }
  
  requestData:WritableSignal<CommunityOfInterestRequest> = signal({page:0, count:20, search:"", ready: false});
  
  override async ngAfterViewInit(): Promise<void> {
    super.ngAfterViewInit();
    setTimeout(()=>this.expandWidget(),0);
  }
  
  selectAssociatedStaff(item:CommunityOfInterest){
    let dialogRef = this.dialog.open(ReferrerStaffSearchDialog, {
      data: {
        communityId: item.referrerCommunity.id,
        title: "Associate Staff with Community"
      } as ReferrerStaffSearchDialogData
    })
    
    dialogRef.afterClosed().subscribe(async result => {
      if(result && result.length > 0){
        const value = await this.api.post(`communityofinterest/associateStaff/${item.guid}/${result[0].personId}`);
        const updated = CommunityOfInterest.fromJson(value);
        
        const idx = this.rawData.indexOf(item);
        this.rawData[idx].associatedStaff = updated.associatedStaff;
        this.rawData = [...this.rawData];
      }
    })
  }
  
  deletedStaffTooltip(item:CommunityOfInterest){
    let name = item.associatedStaff?.nameDisplay;
    return `The current staff '${name}' has been removed and no longer contactable. Please find a new staff associated with this community.`;
  }
  
  select(item:CommunityOfInterest){
    this.selected = item;
  }
  
  async findData(request:CommunityOfInterestRequest, page:number, position:DataPosition): Promise<Page<CommunityOfInterest>> {
    try{      
      let data = await this.api.pageData<CommunityOfInterest>(`communityofinterest/${request.prospectId}`, CommunityOfInterest.fromJson, page, request.count, request.search);

      switch(position){
        case DataPosition.Top:
          this.rawData = [...data.content, ...this.rawData];
          break;
          case DataPosition.Bottom:
          this.rawData = [...this.rawData, ...data.content];
          break;
        case DataPosition.Clear:
          this.rawData = data.content;
          break;
      }
      this.dataSource.data = [...this.rawData];
    
      if(this.multiSelected.all){
        this.rawData.forEach(p=>{
          this.multiSelected[p.id] = true;
        });
      }

      return data;
    }catch(e){
      console.log(e);
      return {content:[] as CommunityOfInterest[], last:true} as Page<CommunityOfInterest>;
    }
  }
  
  onScroll = async (page:number, position:DataPosition):Promise<Page<CommunityOfInterest>> => {
    let foundData = await this.findData(this.requestData(), page, position);
    
    return foundData;
  }
  
  trackRecords(index:number, le:CommunityOfInterest) {
    return le.id;
  }
  
  async rejectInterest(item:CommunityOfInterest){
    this.submitting.set(true);
    let updated = await this.api.post(`communityofinterest/status/reject/${item.guid}`);

    if(updated){
      let i = this.rawData.findIndex(i=>i.guid == updated.guid);
      updated = CommunityOfInterest.fromJson(updated);
      this.rawData[i] = updated;
      this.dataSource.data = [...this.rawData];
      
      if(updated.rejected){
        this.snackbar.open(updated.referrerCommunity.nameDisplay + " Was Rejected");
      }else{
        this.snackbar.open(updated.referrerCommunity.nameDisplay + " Was Unrejected");
      }
    }
    this.submitting.set(false);
  }
  public async bulkReject(){
    this.submitting.set(true);
    let items:string[] = [];
    if(this.multiSelected.all){
      let ids = await this.api.get(`communityofinterest/ids/${this.prospect()?.personId}`)
      items = [ ...ids ];
    }else{
      items = [
        ...this.rawData.filter(r=>this.multiSelected[r.referrerCommunity.referrerCommunityId]).map(r=>r.guid)
      ];
    }
    let updated:{value:string, ids:string[]} = await this.api.post(`communityofinterest/bulk/${this.prospect()?.personId}`, {value:"reject", ids:items});

    if(updated && updated.ids){
      const result = updated.value == "true";
      const ids = new Set(updated.ids);
      this.rawData.forEach(d=>{
        if(ids.has(d.guid)){
          d.rejected = result;
        }
      });
      this.dataSource.data = [...this.rawData];
      if(result){
        this.snackbar.open(updated.ids.length + " Communities of Interest were Rejected");
      }else{
        this.snackbar.open(updated.ids.length + " Communities of Interest were Unrejected");
      }
    }
    this.multiSelected =  {multiple:false, all:false};;
    this.submitting.set(false);
  }
  
  async deleteInterest(item:CommunityOfInterest){
    this.submitting.set(true);
    let updated = await this.api.post(`communityofinterest/status/delete/${item.guid}`);

    if(updated){
      let i = this.rawData.findIndex(i=>i.guid == updated.guid);
      this.rawData.splice(i, 1);
      this.dataSource.data = [...this.rawData];
      
      this.selectedIds = this.selectedIds.filter(id => id != updated.referrerCommunity.referrerCommunityId)
      
      this.snackbar.open(item.referrerCommunity.nameDisplay + " Was Deleted");
    }
    this.submitting.set(false);
  }
  
  public async bulkDelete(){
    this.submitting.set(true);
    let items:string[] = [];
    if(this.multiSelected.all){
      let ids = await this.api.get(`communityofinterest/ids/${this.prospect()?.personId}`)
      items = [ ...ids ];
    }else{
      items = [
        ...this.rawData.filter(r=>this.multiSelected[r.referrerCommunity.referrerCommunityId]).map(r=>r.guid)
      ];
    }
    let updated:{value:string, ids:string[]} = await this.api.post(`communityofinterest/bulk/${this.prospect()?.personId}`, {value:"delete", ids:items});

    if(updated && updated.ids){
      const result = updated.value == "true";
      const ids = new Set(updated.ids);
      this.rawData = this.rawData.filter(d=>!ids.has(d.guid));
      this.dataSource.data = [...this.rawData];
      this.snackbar.open(updated.ids.length + " Communities of Interest were Deleted");
    }
    this.multiSelected =  {multiple:false, all:false};;
    this.submitting.set(false);
    
  }
  
  // async placeProspect(item:CommunityOfInterest){
  //   let updated = await this.api.post(`communityofinterest/choose/${item.referrerCommunity.referrerCommunityId}`);

  //   let i = this.rawData.findIndex(i=>i.guid == updated.guid);
  //   let record = CommunityOfInterest.fromJson(updated);
  //   this.rawData[i] = record;
  //   this.dataSource.data = [...this.rawData];
    
  //   let newPlacement = await this.api.post(`placement-history/${record.personId}/${record.referrerCommunity.referrerCommunityId}`);
  //   let placement = PlacementHistory.fromJson(newPlacement);
  //   this.snackbar.open(`Prospect was placed in ${placement.referrerCommunity.nameDisplay}`);
  //   this.router.navigate(['/placementhistory']);
  // }
  
  // bulkReject(item:CommunityOfInterest){
    
  // }

  goToDepositPage(item:CommunityOfInterest){
    if(item != null && item != undefined && item.referrerCommunity != null && item.referrerCommunity != undefined && item.referrerCommunity.referrerCommunityId != null && item.referrerCommunity.referrerCommunityId != undefined && item.referrerCommunity.referrerCommunityId != ""){
      this.router.navigate(['prospect', 'summary', 'reserve-deposit'],
        { 
          queryParams:{ 
            id: this.prospect()?.personId,
            communityId: item.referrerCommunity.referrerCommunityId
          }
      });
    } else {
      this.router.navigate(['prospect', 'summary', 'reserve-deposit'],
        { 
          queryParams:{ 
            id: this.prospect()?.personId,
          }
      });
    }
  }
  comingSoon(){
    let ref = this.snackbar.open("Coming Soon!");
    ref.afterDismissed().subscribe(info => {
      this.updateBulkOptionView();
    });
  }
  changeMultiSelected(allSelected:boolean) {
    if(allSelected){
      this.rawData.forEach(p=>{
        this.multiSelected[p.id] = this.multiSelected.multiple
      });
      if(this.multiSelected.multiple){
        this.multiSelected.all = true;
        this.openBulkOptions();
      }else{
        this.multiSelected.all = false;
        this.closeBulkOptions();
      }
    }else{
      let selectedCount = this.rawData.filter(p => this.multiSelected[p.id]).length;
      this.multiSelected.multiple = selectedCount > 0;
      this.multiSelected.all = false;
      if(selectedCount > 1){
        this.openBulkOptions();
      }else{
        this.closeBulkOptions();
      }
    }
    
  }
  
  searchCommunity(){

    const dialogRef = this.dialog.open(CommunitySearchDialog,{
      disableClose: true,
      height: 'auto',
      maxWidth: '900px',
      width: 'calc(100vw - 10%)',        
      data: {
        companyId: this.user()?.company.companyId,
        idFilter: this.selectedIds
      } as CommunitySearchDialogData
    });
              
    dialogRef.afterClosed().subscribe(async (result?:ReferrerCommunity[]|null) => {
      if(result && result.length > 0){
        this.submitting.set(true);
        let data = result.map(r=>({
          personId:this.prospect()?.personId, 
          referrerCommunity:r, 
          addedType:"manual",
        }));
        let promises = data.map(d => this.api.post("communityofinterest", d));
        let addedItems = await Promise.all(promises);
        let fails = addedItems.filter(d=>d.status == 409);
        let successes = addedItems.filter(d=>d.status != 409).map(s=>CommunityOfInterest.fromJson(s));
        
        if(successes.length == 0){
          this.snackbar.open(fails.length + " Communities of Interest were already on prospect");
        }else{
          this.snackbar.open(successes.length + " Communities of Interest were added");
        }
        
        this.rawData = [
          ...this.rawData,
          ...successes
        ];
        this.dataSource.data = [...this.rawData];
        
        this.selectedIds.push(...successes.map(s=>s.referrerCommunity.id));
      }
      this.submitting.set(false);
    });
  }
  
  updateBulkOptionView() {
    let count = this.rawData.filter(p => this.multiSelected[p.personId]).length;
    if(count > 1 || this.multiSelected['all'] == true){
      this.openBulkOptions();
    }else{
      this.closeBulkOptions();
    }
  }
  bulkOptions:MatSnackBarRef<FindCommunitiesBulkSnackBar> | null = null;
  openBulkOptions(){
    if(this.bulkOptions == null){
      this.bulkOptions = this.snackbar.openFromComponent(FindCommunitiesBulkSnackBar, {
        duration:undefined,
        panelClass:['light-snackbar', 'main-offset-snackbar'],
        data:{
          // filter:this.requestData().type,
          component: this
        } as FindCommunitiesBulkSnackBarData
      });
      this.bulkOptions.afterDismissed().subscribe(info=>{
        this.bulkOptions = null;
      })
    }
  }
  closeBulkOptions(){
    if(this.bulkOptions != null){
      this.bulkOptions.dismiss();
      this.bulkOptions = null;
    }
  }
}