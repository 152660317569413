<h1 mat-dialog-title>Assign Prospects to User</h1>
<mat-dialog-content class="bulkPropectCloseNames">
    <div *ngIf="data.ids == null">
        <div *ngFor="let p of prospects">
            {{p.prettyName()}}
        </div>    
    </div>
    <div *ngIf="data.ids != null">
        All Selected Prospects
    </div>
</mat-dialog-content>
<mat-dialog-content>
    <span class="flex-row">
        <mat-form-field>
            <mat-label>
                Select Sales Counselor
            </mat-label>
            <mat-select class="table-inline-select" [(ngModel)]="selectedUser">
                <mat-option [value]="null"> Unassigned</mat-option>
                <mat-option *ngFor="let lItem of users" [value]="lItem">{{lItem.nameDisplay}}</mat-option>
            </mat-select>
        </mat-form-field>
    </span>
</mat-dialog-content>


<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="onCancel()">Cancel</button>
  <button mat-button mat-dialog-close (click)="onSubmit()" [disabled]="!isValid()" cdkFocusInitial>Submit</button>
</div>
