import { Component, ElementRef, Injectable, WritableSignal, signal } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface IExpandable {
  widgetCard : ElementRef;
  isExpanded:boolean;
}

@Injectable()
export class AppService {
    private _selectedWidget:IExpandable|null = null;
    public get selectedWidget(){
      return this._selectedWidget;
    }
    private _dashWidgetsExpanded!:boolean;
    public get dashWidgetsExpanded(){
      return this._dashWidgetsExpanded;
    }
    private _sectionView!:"left"|"right";
    public get sectionView(){
      return this._sectionView;
    }
    private _phoneExpanded!:boolean;
    public get phoneExpanded(){
      return this._phoneExpanded;
    }

    public ccpDiv!: HTMLElement;
  
    openWidget:WritableSignal<string|null> = signal(null);
    selectWidget: ReplaySubject<IExpandable|null>;
    dashWidgetsState: ReplaySubject<boolean>;
    sectionViewState: ReplaySubject<"left"|"right">;
    phoneState: ReplaySubject<boolean>;
    
    constructor() {
      this.selectWidget = new ReplaySubject<IExpandable|null>(1);
      this.selectWidget.subscribe(w=>{
        this._selectedWidget = w;
      });
      
      this.dashWidgetsState = new ReplaySubject<boolean>(1);
      this.dashWidgetsState.subscribe(w=>{
        this._dashWidgetsExpanded = w;
        localStorage.setItem('dashWidgetsExpanded', w ? "true" : "false");
      });
      let expanded:boolean = localStorage.getItem('dashWidgetsExpanded') == "true";
      this.dashWidgetsState.next(expanded);

      this.phoneState = new ReplaySubject<boolean>(1);
      this.phoneState.subscribe(w=>{
        this._phoneExpanded = w;
        localStorage.setItem('phoneExpanded', w ? "true" : "false");
      });
      let pExpanded:boolean = localStorage.getItem('phoneExpanded') == "true";
      this.phoneState.next(pExpanded);
      

      this.sectionViewState = new ReplaySubject<"left"|"right">(1);
      this.sectionViewState.subscribe(w=>{
        this._sectionView = w;
      });
      this.sectionViewState.next("left");
    }
}