import { Component, Input, WritableSignal, signal } from '@angular/core';

@Component({
  selector: 'load-spinner,load-spinner[inline]',
  template: `@if(!hidden()){<div [class]="variant != undefined ? 'var-' + variant : ''"><mat-spinner diameter="36"></mat-spinner></div>}`,
  styleUrls: ['./load-spinner.component.scss']
})
export class LoadSpinnerComponent{
  @Input() variant:"table"|undefined;
  @Input() public hidden:WritableSignal<boolean> = signal(false);
}