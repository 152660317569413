import { Component, AfterViewInit, Output, EventEmitter, Input, ɵunwrapWritableSignal, signal, WritableSignal } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { PersonService } from '../../services/person.service';
import { LookupTableReference } from 'src/app/view/utilities/lookuptables/lookupview.component';
import { InfiniteRequestData } from '../../directives/infinite-scroll-table.directive';
import { SearchRequestData } from '../../components/search-input/search-input.component';
import { AggregateTableReference } from 'src/app/view/utilities/aggregates/aggregateview.component';

interface AggregateTableSearch extends SearchRequestData{
  reverse:boolean
}
@Component({
  selector: 'aggregate-list-widget',
  templateUrl: './aggregatelist.widget.html',
  styleUrls: ['./aggregatelist.widget.scss']
})
export class AggregateListWidget {
  @Input() lookupTables !: AggregateTableReference[];
  selected:AggregateTableReference|null = null;

  // @Output() filterChange =  new EventEmitter<LookupTableReference>();
  @Output() selectedTableChanged=  new EventEmitter<AggregateTableReference>();
  requestData:WritableSignal<AggregateTableSearch> = signal({ search: "", reverse: false, page:null, ready: true});
  
  viewTable(tableRef:AggregateTableReference){
    this.selected = tableRef;
    this.selectedTableChanged.emit(tableRef);
  }
  
  isSelected(tableRef:AggregateTableReference){
    return this.selected != null && this.selected.name === tableRef.name;
  }
}