<mat-card appearance="outlined" class="mat-elevation-z4 grid4x4">
    <mat-card-header class="subText" [matTooltip]="tooltipTopLeftText()">
        {{topLeftTitle}}
    </mat-card-header>
    <mat-card-header class="subText" [matTooltip]="tooltipTopRightText()">
        {{topRightTitle}}
    </mat-card-header>
    <mat-card-content matRipple [matTooltip]="tooltipTopLeftText()"> 
        <div class="center" *ngIf="!loading()">
            <mat-label>
                {{topLeftValue()}}
            </mat-label>
        </div>
        <div class="center" *ngIf="loading()">
            <load-spinner inline></load-spinner>
        </div>
    </mat-card-content>
    <mat-card-content matRipple [matTooltip]="tooltipTopRightText()"> 
        <div class="center" *ngIf="!loading()">
            <mat-label>
                {{topRightValue()}}
            </mat-label>
        </div>
        <div class="center" *ngIf="loading()">
            <load-spinner inline></load-spinner>
        </div>
    </mat-card-content>
    <mat-card-header class="subText" [matTooltip]="tooltipBottomLeftText()">
        {{bottomLeftTitle}}
    </mat-card-header>
    <mat-card-header class="subText" [matTooltip]="tooltipBottomRightText()">
        {{bottomRightTitle}}
    </mat-card-header>
    <mat-card-content matRipple [matTooltip]="tooltipBottomLeftText()"> 
        <div class="center" *ngIf="!loading()">
            <mat-label>
                {{bottomLeftValue()}}
            </mat-label>
        </div>
        <div class="center" *ngIf="loading()">
            <load-spinner inline></load-spinner>
        </div>
    </mat-card-content>
    <mat-card-content matRipple [matTooltip]="tooltipBottomRightText()"> 
        <div class="center" *ngIf="!loading()">
            <mat-label>
                {{bottomRightValue()}}
            </mat-label>
        </div>
        <div class="center" *ngIf="loading()">
            <load-spinner inline></load-spinner>
        </div>
    </mat-card-content>
</mat-card>