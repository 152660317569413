<h1 mat-dialog-title>Permissions for {{tempLoad.prettyName()}}</h1>
    <mat-dialog-content>
        <form [formGroup]="form" class="flex-form">
            <div class="">
                <mat-card appearance="outlined">
                    <mat-card-content>
                        <div>
                            <div *ngFor="let cGroup of allCategoryGroups;"> <!---->
                                <span class="fill-row">
                                    <span class="all">
                                        <mat-checkbox labelPosition="before" formControlName="{{cGroup.categoryName}}All" [checked]="cGroup.selected" (change)="selectAll(cGroup.categoryName, $event)">
                                            <mat-label class="">{{cGroup.categoryName}}</mat-label>
                                        </mat-checkbox>
                                    </span>

                                    <span class="">
                                        <mat-selection-list class="list-horizontal" formControlName="{{cGroup.categoryName}}" (selectionChange)="selectOne(cGroup.categoryName, $event)">
                                            <mat-list-option class="list-horizontal-item" *ngFor="let permission of cGroup.list" [value]="permission.name" [selected]="permission.selected" togglePosition="before">
                                                {{permission.permissionName}}
                                            </mat-list-option>
                                        </mat-selection-list>
                                    </span>                
                                </span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </form>
    </mat-dialog-content>

    <div mat-dialog-actions align="end">
        <button *ngIf="!submitting" mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
        <button mat-flat-button color="primary" (click)="savePermissions()" [disabled]="submitting">Submit</button>
        <load-spinner inline *ngIf="submitting"></load-spinner>
    </div>