import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Person } from '../../models/user.models';
import { IContactable, INameDisplay } from '../../models/base.models';

@Component({
  selector: 'person-icon,person-icon[name]',
  templateUrl: './person-icon.component.html',
  styleUrls: ['./person-icon.component.scss']
})
export class PersonIconComponent implements OnInit, OnChanges {
    @Input() display?:INameDisplay|null = null;
    
    @Input() name?:any|undefined;
    showName:boolean = false;
    
    @Input() size?:"xsmall"|"small"|"medium" = "medium";
    
    ngOnInit() {
        this.showName = this.name !== undefined && this.name !== false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        let nameChange = changes["name"];
        if(nameChange && (nameChange.firstChange || nameChange.currentValue != nameChange.previousValue)){
            this.showName = nameChange.currentValue !== undefined && nameChange.currentValue !== false;
        }
    }
    
    initials () : string{
        if(this.display){
            return this.display.nameAbbreviation;
        }else{
            return "";
        }
    }
    
    getId(person?:INameDisplay|null){
        if(person && person.id){
            return person.id;
        }else{
            return "";
        }
    }
    
    getColor (input:string) {
        if(!input || input == ""){
          return "";  
        }
        return "type" + Person.colorIndex(input);
    }
}