import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControlOptions } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs';
import { ValidationSnackBar } from '../components/validation-snack-bar/validation.snack-bar';
import { ApiService } from '../services/api.service';
import { fieldsMatch } from '../validators/field-match.validator';
import { Patterns } from '../validators/patterns.validator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from './alert-dialog.component';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialog  {

  hide1:boolean = true;
    hide2:boolean = true;
    
    focus:"code"|"user"|"pwd" = "pwd";
    
    form:FormGroup;
    
    passwordMinLength = Patterns.minPasswordLength;
    passwordPattern = Patterns.passwordPattern;
    
    showPasswordErrors = false;
    submitting = false;

    codeCtrl:FormControl;
    pwdCtrl:FormControl;
    pwdConfirmCtrl:FormControl;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialogRef: MatDialogRef<ChangePasswordDialog>,
        route:ActivatedRoute,
        formBuilder:FormBuilder,
        private api:ApiService,
        private snackbar:MatSnackBar
        ) {

        this.codeCtrl = new FormControl("", [Validators.required]),
        this.pwdCtrl = new FormControl("", Patterns.validatePasswordComponents),
        this.pwdConfirmCtrl = new FormControl("", [Validators.required]),
            
        this.form = formBuilder.group(
            {
                code:this.codeCtrl,
                password:this.pwdCtrl,
                passwordConfirm:this.pwdConfirmCtrl
            }, 
            {
                validators:[fieldsMatch("password", "passwordConfirm")]
            } as AbstractControlOptions
        );
  
        this.form.controls["password"].valueChanges.subscribe(v=>{
            if(v.length > 3){
                this.showPasswordErrors = true;
            }
        })
    }
    
    getConfirmPwdErrorMessage() {
        console.log(this.pwdCtrl.errors);
      if (this.pwdConfirmCtrl.hasError('required')) {
        return 'You must enter a value';
      }

      return this.pwdConfirmCtrl.hasError('fieldsMismatch') ? 'Passwords do not match' : '';
    }
    
    onStrengthChanged(event: any) {
    }
    
    async submitPassword(){
        this.submitting = true;
        let obj = {
            userPassword:this.pwdCtrl.value,
            activationCode:this.codeCtrl.value
        }
        let resp = await this.api.post("user/changepassword", obj);
        this.submitting = false;
        if(resp.message != "success"){
            let ref = this.snackbar.openFromComponent(ValidationSnackBar, {duration:7500});
            ref.instance.title = resp.message;
            ref.instance.validation = resp.errors.map((e:{user:String, message:String}) => e.message);
        } else {
            let ref = this.snackbar.open("Password changed successfully", "OK", {duration:7500});
            this.dialogRef.close();
        }
    }

}
