import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[clickOrKey]'
})
export class ClickOrKeyDirective {
    @Output('clickOrKey') clickOrKey: EventEmitter<any> = new EventEmitter();

    constructor(elRef:ElementRef<HTMLElement>, ) {
        if(!elRef.nativeElement.hasAttribute('tabindex')){
            elRef.nativeElement.setAttribute('tabindex', "0"); // It can be checked that element has `tabindex` already
        }
        // elRef.nativeElement.addEventListener('keypress.enter',e=>console.log(e));
    }
    
    @HostListener('click') onClick() {
        this.clickOrKey.emit();
    }
    
    @HostListener('keydown.enter') onEnterPress() {
        this.clickOrKey.emit();
    }
    @HostListener('keydown.space', ['$event']) onSpacePress(e:any) {
        this.clickOrKey.emit();
        e.preventDefault();
    }
}