<mat-card appearance="outlined" class="mat-elevation-z4" [matTooltip]="tooltipText()">
    <mat-card-header class="subText">
        Occupancy Rate
    </mat-card-header>
    <mat-card-content matRipple class="twoCol"> 
        <div echarts [options]="chartCfg()" theme="hotCold" *ngIf="!loading()"></div>
        <div class="center" *ngIf="!loading()">
            <mat-label class="pctLabel">
                <!-- <div class="top">{{percent() | number:'1.0-2'}}%</div>
                <mat-divider></mat-divider>
                <div class="subText bottom">{{count()}}</div> -->
                <div>{{percent() | number:'1.0-2'}}%</div>
            </mat-label>
        </div>
        <div class="center" *ngIf="loading()">
            <load-spinner inline></load-spinner>
        </div>
    </mat-card-content>
</mat-card>