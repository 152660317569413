import { ValidatorFn, Validators } from "@angular/forms";

export class Patterns{
    private static readonly lengthTester = 12;
    private static readonly pwdTester = /(?=.{12,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()\-_+={}|\\:\'<,>.?\/ "]).*/;
    private static readonly symbolTester = /([`~!@#$%^&*()\-_+={}|\\:\'<,>.?\/ "])/;
    private static readonly lowerTester = /([a-z])/;
    private static readonly upperTester = /([A-Z])/;
    private static readonly digitTester = /(\d)/;
    
    public static get passwordPattern(){
        return this.pwdTester;
    }
    
    public static get minPasswordLength(){
        return this.lengthTester;
    }
    
    public static get validatePasswordComponents(): ValidatorFn[] {
        return [
            Validators.minLength(this.lengthTester),
            Validators.pattern(Patterns.symbolTester),
            Validators.pattern(Patterns.lowerTester),
            Validators.pattern(Patterns.upperTester),
            Validators.pattern(Patterns.digitTester),
            Validators.pattern(Patterns.pwdTester)//Sanity test, other tests should cover this already.
        ]
    }
    // public static get validatePassword(): ValidatorFn {
    //     return Validators.pattern(Patterns.pwdTester);
    // }
    
    // public static getPasswordProblems(check:string): string[]{
        
    // }
}