<h1 mat-dialog-title>Close Prospect {{prospect.prettyName()}}</h1>
<mat-dialog-content>
    <span class="flex-row">
        <mat-form-field>
            <mat-label>
                Please select a reason
            </mat-label>
            <mat-select class="table-inline-select" [(ngModel)]="selectedReason">
                <mat-option  *ngFor="let lItem of closeReasons" [value]="lItem">{{lItem.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- <span class="center" *ngIf="selectedReason.preadmit" matTooltip="Should this prospect be preadmitted to a community.">
            <div class="subText">
                Preadmit
            </div>
            <mat-slide-toggle [(ngModel)]="preadmit"></mat-slide-toggle>
        </span> -->
    </span>
    <span>
        <mat-form-field>
            <mat-label>Notes</mat-label>
            <textarea matInput [maxLength]="textAreaMaxLength" cdkTextareaAutosize cdkAutosizeMinRows="2" [(ngModel)]="close_comments" [required]="selectedReason?.notesRequired"></textarea>
            <mat-hint align="end" >Characters: {{close_comments.length}}/{{textAreaMaxLength}}</mat-hint>
        </mat-form-field>    
    </span>
    <!-- <span *ngIf="selectedReason.preadmit && preadmit">
        <chip-autocomplete class="fill"
            label="Select a Community"
            placeholder="Community"
            idField="communityId"
            displayField="nameDisplay"
            [fullData]="prospect.communitiesOfInterest"
            [selectedData]="selectedCommunity"
            (onChange)="selectCommunity($event)">
        </chip-autocomplete>
    </span>
    <span *ngIf="selectedReason.preadmit && preadmit && selectedCommunity.length > 0">
        <mat-selection-list #units [multiple]="false" [(ngModel)]="selectedUnit" [compareWith]="compareUnitFunction" >
            <mat-list-option *ngFor="let unit of communityReserved" [value]="unit">
              {{unit.nameDisplay}}
            </mat-list-option>
        </mat-selection-list>
        <span *ngIf="communityReserved == null || communityReserved == undefined || communityReserved.length == 0">No unoccupied units were found. To reserve an occupied unit first do so on the occupancy page before preadmit.</span>
    </span> -->
</mat-dialog-content>


<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="onCancel()">Cancel</button>
  <button mat-button mat-dialog-close (click)="onSubmit()" [disabled]="!isValid()" cdkFocusInitial>Submit</button>
</div>
