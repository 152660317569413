import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { OccupancyService } from "./occupancy.service";
import { EChartsOption } from "echarts";

export interface DashboardBannerCfg {
    view:"none"|"prospects"|"occupancy"|"referrer";
}

@Injectable()
export class DashboardService {
    public static NoDashboard:DashboardBannerCfg = {
        view:"none"
    }
    public static ProspectDashboard:DashboardBannerCfg = {
        view:"prospects"
    }
    public static OccupancyDashboard:DashboardBannerCfg = {
        view:"occupancy"
    }
    public static ReferrerDashboard:DashboardBannerCfg = {
        view:"referrer"
    }
    
    dashboardDate: ReplaySubject<Date>;
    // occupancySummary: ReplaySubject<OccupancySummaryOld|null>;
    cfg:ReplaySubject<DashboardBannerCfg>;
    
    public static buildPie = (sliceValue:number, fullValue:number, name:string, invert:boolean = false):EChartsOption => {
        return {
            toolbox: {
                show: false
            },
            height:60,
            series: [
                {
                    type:'pie',
                    radius: 26,
                    center: ['50%', '50%'],
                    startAngle: 45,
                    data: invert ? 
                    [
                        { value: fullValue, label: {show:false}, itemStyle: {borderRadius: 0}, emphasis:{disabled:true} },
                        { value: sliceValue, name: name, label: {show:false}, itemStyle: {borderRadius: 0}, selected:true, emphasis:{disabled:true}},
                    ] 
                    :
                    [
                        { value: sliceValue, name: name, label: {show:false}, itemStyle: {borderRadius: 0}, selected:true, emphasis:{disabled:true}},
                        { value:fullValue, label: {show:false}, itemStyle: {borderRadius: 0}, emphasis:{disabled:true} },
                    ]
                }
            ]
        };
    }
    
    constructor() {
        this.dashboardDate = new ReplaySubject<Date>(1);
        this.dashboardDate.next(new Date());
        
        // this.occupancySummary = new ReplaySubject<OccupancySummaryOld|null>(1);
        this.cfg = new ReplaySubject<DashboardBannerCfg>(1);
        this.cfg.next({view:"none"});
    }
}