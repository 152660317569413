import { faCompress, faExpand, faX } from '@fortawesome/free-solid-svg-icons';
import { AppService } from '../../services/app.service';
import { AfterViewInit, Directive, ElementRef, ViewChild } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class ExpandableWidget implements AfterViewInit {
    expandIcon = faExpand;
    compressIcon = faCompress;
    closeIcon = faX;
    @ViewChild(MatCard, {read: ElementRef }) public widgetCard !: ElementRef;
    constructor(
            protected app:AppService, 
            protected route:ActivatedRoute, 
            protected router:Router
        ) {
    }
    
    ngAfterViewInit(): void {
        if(this.app.selectedWidget != null && this.app.selectedWidget != this) {
          this.app.selectWidget.next(null);
        }
        this.app.selectWidget.pipe(untilDestroyed(this)).subscribe(widg =>{
            if(widg == this){
                this.isExpanded = true;
                this.widgetCard.nativeElement?.parentElement.classList.add('expandedWidget');
                this.widgetCard.nativeElement?.classList.add('expandedComponent');
            }else{
                this.isExpanded = false;
                this.widgetCard?.nativeElement?.parentElement.classList.remove('expandedWidget');
                this.widgetCard?.nativeElement?.classList.remove('expandedComponent');
            }
        });
    }
    
    public isExpanded:boolean = false;
    
    protected expandWidget() {
        this.app.selectWidget.next(this);
    }
    
    protected compressWidget(next?:ExpandableWidget) {
        this.app.selectWidget.next(next ? next : null);
    }
    
    protected closeWidget(next?:ExpandableWidget) {
        this.app.selectWidget.next(next ? next : null);
        // console.log(this.route);
        // let url = this.route.parent?.snapshot.url.map(u=>u.path) as string[];
        // this.router.navigate(url, {queryParams:{}});
        this.router.navigate(['../'], { relativeTo: this.route })
    }
}