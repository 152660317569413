import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { PlacementHistoryViewComponent } from './placementhistoryview.component';
import { ReferrerStaffListWidget } from 'src/app/core/widgets/referrerstaff/referrerstafflist.widget';
import { ReferrerStaffWidget } from 'src/app/core/widgets/referrerstaff/referrerstaff.widget';
import { PlacementHistoryListWidget } from 'src/app/core/widgets/placementhistory/placementhistorylist.widget';


const routes:Routes = [
  { path: '', component: PlacementHistoryViewComponent,
  children: [
    {
      path: '',
      component: PlacementHistoryListWidget,
      data:{showAll:true}
    }
  ]}
]


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlacementHistoryRoutingModule { }