<mat-dialog-content>
    @if(data.itemType == "Prospect"){
        <chat-widget [prospect]="data.contact" [type]="data.itemType" [activityIds]="data.activityIds" (onSend)="messageSent($event)"></chat-widget>
    }@else {
        <chat-widget [referrer]="data.contact" [type]="data.itemType" [activityIds]="data.activityIds" (onSend)="messageSent($event)"></chat-widget>
    }
</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button  mat-stroked-button color="primary" (click)="onSubmit()" >Close</button>
</div>