import { Component, AfterViewInit, ViewChild, ElementRef, OnInit, Output, WritableSignal, signal, effect } from '@angular/core';
import { faAddressCard, faCalendarDay, faExpand, faFileCircleMinus, faGaugeHigh, faGears, faHandsClapping, faMapLocationDot, faPencil, faPersonCircleMinus, faPersonWalking, faSearch, faUpRightFromSquare, faUserPlus, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { Community, IPageable, MarketingMaterial, Referrer, ReferrerCommunity, ReferrerStaff, SimplePerson } from '../../models/user.models';
import { ApiService } from '../../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { PersonService } from '../../services/person.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { ExpandableWidget } from '../helpers/expandablewidget.component';
import { AppService } from '../../services/app.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericConfirmDialogComponent } from '../../dialog/generic-confirm-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSort, Sort} from '@angular/material/sort';
import { AddressMapDialog, AddressMapDialogData } from '../../dialog/address-map/address-map.dialog';
import { ContactableAddresses } from '../../models/base.models';
import { DataPosition, InfiniteRequestData } from '../../directives/infinite-scroll-table.directive';
import { Page } from 'src/app/core/models/spring.models';
import { Subject, debounceTime } from 'rxjs';
import { CompanyCfgService } from '../../services/company-cfg.service';


interface MarketingMaterialSearch extends InfiniteRequestData{
  companyId:string
}

@UntilDestroy()
@Component({
  selector: 'marketingmaterial-list-widget',
  templateUrl: './marketingmateriallist.widget.html',
  styleUrls: ['./marketingmateriallist.widget.scss', '../../../table.responsive.scss']
})
export class MarketingMaterialListWidget extends ExpandableWidget implements AfterViewInit {
  editIcon = faPencil;
  removeMaterialIcon = faFileCircleMinus;
  
  dataSource = new MatTableDataSource<MarketingMaterial>();
  compressedComPageColumns: string[] = ['title', 'fileType', 'uploadUser', 'lastUpdate', 'table-more'];
  expandedComPageColumns: string[] = ['title', 'fileType', 'uploadUser', 'lastUpdate', 'allowAsEmailAttachment', 'table-more'];
  displayedColumns!: string[];
  footerColumns: string[] = [];
  rawData:MarketingMaterial[] = [];
  communityNames:WritableSignal<{[key:string]:string}> = signal({});
  
  searchTotal:number|null = null;

  selected = signal<MarketingMaterial|null>(null);
  
  findDataSubscription:any;
  findDataUserSubscription:any;
  
  allCommunities:SimplePerson = new SimplePerson("", "All", "", "Communities")
  
  filter:Subject<any> = new Subject();  

  requestData:WritableSignal<MarketingMaterialSearch> = signal({companyId:"", search:"", count:20, page:0, ready:false})

  searchValue = signal("");
  
  changeRequest = effect(()=>{
    let typeOfCRM = this.companyCfg.typeOfCRM();
    if(typeOfCRM == "Community CRM"){
      
      this.api.get("community/for/company").then(communities=>{
        let map:{[key:string]:string} = {}
        communities.map((c:any)=>Community.fromJson(c) as Community).forEach((c:Community)=>{
          map[c.id] = c.nameDisplay;
        });
        this.communityNames.set(map);
      })
    }else if(typeOfCRM == "Referrer CRM"){
      this.api.get("referrercommunity/list").then(communities=>{
        let map:{[key:string]:string} = {}
        communities.map((c:any)=>ReferrerCommunity.fromJson(c) as ReferrerCommunity).forEach((c:ReferrerCommunity)=>{
          map[c.id] = c.nameDisplay;
        });
        this.communityNames.set(map);
      })
    }
    // const search = this.requestData().search;
    // sessionStorage.setItem('prospectSearchBox', JSON.stringify({search: search}));
  })

  constructor(
      protected override app:AppService,
      protected override route:ActivatedRoute, 
      protected override router:Router,
      private api:ApiService, 
      private personService:PersonService, 
      protected dialog: MatDialog,
      private snackbar:MatSnackBar,
      private companyCfg:CompanyCfgService) {
    super(app, route, router);

    this.compressWidget();

    route.queryParams.pipe(untilDestroyed(this))
    .subscribe(async (p:any) => {
      this.requestData.update(d=>({...d, page:null}));
    })
    
    personService.selectedMarketingMaterial.pipe(untilDestroyed(this)).subscribe(item=>{
      this.selected.set(item);
    })
    personService.currentUser.pipe(untilDestroyed(this)).subscribe(u=>{
      if(u){
        this.requestData.update(d=>({...d, companyId:u.company.companyId, ready: true}))
      }
    });
  }
  
  @ViewChild(MatSort) sort!: MatSort;

  onScroll = async (page:number, position:DataPosition):Promise<Page<MarketingMaterial>> => {
    let foundData = await this.findData(this.requestData(), page, position);
    
    return foundData;
  }
  
  communityName(communityId:string|null){
    if(communityId != null){
      let name = this.communityNames()[communityId];
      if(name){
        return name;
      }
    }
    return "All Communities";
  }
  
  selectMarketingMaterial(item:MarketingMaterial) {
    this.personService.selectedMarketingMaterial.next(item);
  }
  
  trackMarketingMaterial(index:number, item:MarketingMaterial) {
    return item.marketingMaterialId;
  }
  
  editMarketingMaterial(item:MarketingMaterial) {
    if (!this.personService.hasSomePermission(['marketing-materials:add/update'])) {
      return;
    }

    this.personService.selectedMarketingMaterial.next(item);
    this.router.navigate(['marketingmaterial', 'edit'],
      { 
        queryParams:{ 
          id: item.marketingMaterialId
        }
    });
  }
  
  newMarketingMaterial() {
    if (!this.personService.hasSomePermission(['marketing-materials:add/update'])) {
      return;
    }

    this.personService.selectedMarketingMaterial.next(new MarketingMaterial());
    this.router.navigate(['marketingmaterial', 'edit']);
  }
  
  async deleteMarketingMaterial(item:MarketingMaterial) {
    if (!this.personService.hasSomePermission(['marketing-materials:delete'])) {
      return;
    }
    
    const dialogRef = this.dialog.open(GenericConfirmDialogComponent, {
      disableClose: true,
      height: 'auto',
      width: 'auto',        
      data: {
        title: 'Delete "' + item.title + '"',
        content: `'${item.title}' and its file will be deleted from ${this.communityName(item.communityId)}. Are you sure?`
      },
    });
  
    dialogRef.afterClosed().subscribe(async result => {
      if (result === 'submit') {
        let resp = await this.api.delete(`marketingmaterial/${item.marketingMaterialId}`);
        if(resp.message == 'success'){
          this.snackbar.open(`'${item.title}' was deleted.`);
          this.requestData.update(d=>({...d, page:null}));
        }else{
          this.snackbar.open(`Unable to delete '${item.title}'. ${resp.message}`);
        }
      }
    });
  }

  async findData(request:MarketingMaterialSearch, page:number, position:DataPosition): Promise<Page<MarketingMaterial>>{
    // if(!request.communityId){
    //   throw new Error("No Community");
    // }
    let data = await this.api.getPage("marketingmaterial/list", MarketingMaterial.fromJson, request.companyId, page, request.count, request.search, {expandedSearch: this.isExpanded});
    // let data = await {content:[] as MarketingMaterial[]} as Page<MarketingMaterial>;
    if (data.totalElements !== undefined && data.totalElements !== null) {
      this.searchTotal = data.totalElements;
    }

    switch(position){
      case DataPosition.Top:
        this.rawData = [...data.content, ...this.rawData];
        this.dataSource.data = [...this.rawData];
        break;
      case DataPosition.Bottom:
        this.rawData = [...this.rawData, ...data.content];
        this.dataSource.data = [...this.rawData];
        break;
      case DataPosition.Clear:
        this.rawData = data.content;
        this.dataSource.data = [...this.rawData];
        if(this.dataSource.data.length > 0 && this.selected == null){
          // this.personService.selectedReferrerStaff.next(this.dataSource.data[0]);
        }
        break;
    }

    return data;
  }

  changeFilter(val:string){
    this.filter.next(val);
  }
    
  protected override expandWidget(): void {
      super.expandWidget();
      this.displayedColumns = this.expandedComPageColumns;
  }
  
  protected override compressWidget(next?: ExpandableWidget | undefined): void {
      super.compressWidget(next);
      this.displayedColumns = this.compressedComPageColumns;
  }
  
  comingSoon(){
    this.snackbar.open("Coming Soon!");
  }
}
