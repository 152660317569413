import { E } from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ApiService } from '../services/api.service';


@Component({
    selector: 'generic-confirm-dialog-component',
    templateUrl: 'generic-confirm-dialog.component.html',
    styleUrls: ['generic-confirm-dialog.component.scss']
  })
  
  
  export class GenericConfirmDialogComponent {
    public title: string = "";
    public content: string = "";
    public submitText: string = "Submit";

    constructor(public dialogRef: MatDialogRef<GenericConfirmDialogComponent>, private api:ApiService, @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.title !== undefined) {
            this.title = data.title;
        }

        if (data.content !== undefined) {
            this.content = data.content;
        }

        if (data.submitText !== undefined) {
            this.submitText = data.submitText;
        }
    }

    onCancel(): void {
        this.dialogRef.close("cancel");
    }

    async onSubmit() {
        this.dialogRef.close("submit");
    }
}