<div class="center">
    <div class="upload-table" #scrollElement (wheel)="onWheel($event)">
        <div class="scroll-container">
            @if(maxPhotos == undefined || maxPhotos > 1){
                <div *ngFor="let photo of photos;">
                    @if(photo.filetype == 'pdf' || photo.filetype == 'docx' || photo.filetype == 'xlsx' || photo.filetype == 'doc' || photo.filetype == 'xls'){
                        <fa-icon class="fileIcon" [icon]="fileDLIcon" (click)="openFile(photo)"></fa-icon>
                    }@else {
                        <img [src]="photo.fileId" (click)="selectPhoto(photo)" (load)="finishLoad(photo)">
                    }
                    <load-spinner *ngIf="!photo.loaded"></load-spinner>
                </div>
            }
            <div *ngFor="let upload of uploading" [matTooltip]="immediateUpload ? 'Uploading' : 'File will be uploaded on submit.'">
                @if(upload.src != ""){
                    <img [src]="upload.src">
                }@else if(upload.type == "pdf" || upload.type == "docx" || upload.type == "xlsx" || upload.type == "doc" || upload.type == "xls"){
                    <fa-icon [icon]="fileIcon" class="uploadFileIcon"></fa-icon>
                }@else {
                    <load-spinner inline></load-spinner>
                }
            </div>
        
            <div class="drop-area">
                <ngx-file-drop dropZoneLabel="Drop Images" (onFileDrop)="dropped($event)" 
                        (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" 
                        [directory]="false" [multiple]="maxPhotos != 1" [accept]="accept">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <div class="file-area">
                            <span>
                                Upload New File
                            </span>
                            <span>
                                <button mat-raised-button (click)="uploadPermissionCheck() && openFileSelector()">
                                    <fa-icon [icon]="uploadIcon" ></fa-icon>
                                </button>
                            </span>
                        </div>
                    </ng-template>
                </ngx-file-drop>
            </div>
        </div>
    </div>
    <div class="main-image" *ngIf="immediateUpload">
        @if(selectedSrc() != undefined){
            <div>
                <div class="img-box">
                    @if(selectedSrc()?.filetype == 'pdf' || selectedSrc()?.filetype == 'docx' || selectedSrc()?.filetype == 'xlsx' || selectedSrc()?.filetype == 'doc' || selectedSrc()?.filetype == 'xls'){
                        <div class="flex">
                            <fa-icon class="fileIcon mx-auto" [icon]="fileDLIcon" (click)="openFile(selectedSrc())"></fa-icon>
                        </div>
                    }@else {
                        <img #mainImg [src]="selectedSrc()?.fileId" (load)="finishSelectedLoad()" (error)="loadError()"> 
                    }
                </div>
                <load-spinner *ngIf="!selectedSrc()?.loaded"></load-spinner>
                <div *ngIf="selectedSrc()?.loaded && (maxPhotos == undefined || maxPhotos > 1)" class="removeIcon">
                    <button mat-mini-fab color="primary" (click)="removePhoto(selectedSrc())"><fa-icon [icon]="removeIcon"></fa-icon></button>
                </div>
            </div>
        }
    </div>
</div>