import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ContactableEmail, ContactablePhone } from '../models/base.models';
import { Community } from '../models/user.models';

@Injectable()
export class ContactService {
  selectedPhone: ReplaySubject<ContactablePhone|null>;
  selectedMobile: ReplaySubject<ContactablePhone|null>;
  selectedEmail: ReplaySubject<ContactableEmail|null>;
  selectedCommunity: ReplaySubject<Community|null>;

  private events = new BehaviorSubject<String>("");
  
  constructor() {
    this.selectedPhone = new ReplaySubject<ContactablePhone|null>(1);
    this.selectedMobile = new ReplaySubject<ContactablePhone|null>(1);
    this.selectedEmail = new ReplaySubject<ContactableEmail|null>(1);
    this.selectedCommunity = new ReplaySubject<Community|null>(1);
  }

  public showCallDialog(phoneNumber: string){
    this.events.next(phoneNumber);
  }

  public hideCallDialog(){
    this.events.next("");
  }

  public callStatus(){
    return this.events.asObservable();
  }
  
}