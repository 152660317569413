import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterContentInit, Component } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment'

@Component({
    standalone: true,
    selector: 'example-prospect-form',
    templateUrl: './example-prospect.form.html',
    imports: [CommonModule, FormsModule, NgxMaskDirective, NgxMaskPipe],
    styleUrls: []
})
export class ExampleProspectForm implements AfterContentInit {    
    communities:any[] = [];
    relations:any[] = [];
    objData:string = "";
    headers = {};
    
    selectedCommunities:string[] = [];
    constructor(private http:HttpClient) {
    }
    
    async ngAfterContentInit(): Promise<void> {
        await this.init();
    }
    
    onCommunityChange(event:any){
        if(event.target.checked){
            this.selectedCommunities.push(event.target.value);
        }else{
            this.selectedCommunities = this.selectedCommunities.filter(s => s != event.target.value);
        }
    }
    
    async init(){
        let resp = await lastValueFrom(this.http.get(environment.API_URL + "/ext/company/data", {headers:this.headers})) as any;
        
        this.relations = resp.relations;
        this.communities = resp.communities;
    }
    
    async submit(f:NgForm){
        let data = f.value;
        data.communityIds = this.selectedCommunities;
        
        let resp = await lastValueFrom(this.http.put(environment.API_URL + "/ext/prospect", data, {headers:this.headers})) as any;
        this.objData = JSON.stringify(resp).split(',"').join(', "');
    }
}