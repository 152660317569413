<mat-card appearance="outlined" class="mat-mr mr-accent">
    <mat-card-title class="titleRow">
        <span>{{tableName()}}</span>
        <span class="mr-auto">
            @if(aggregateTable().dataUrl != "ebd"){
                <mat-select [(ngModel)]="selectedCommunity" >
                    <mat-option *ngFor="let item of communityList" [value]="item">
                        {{item.nameDisplay}}
                    </mat-option>
                </mat-select>
            }
        </span>
    </mat-card-title>
    <mat-card-title class="lookupDescription">
        <span class="subText">({{description()}})</span>
    </mat-card-title>
    <mat-card-content>
        <!-- <search-input [(filter)]="requestData" #searchInput></search-input> -->
        <div infiniteScroll [onScroll]="onScroll" [filter]="requestData()">
            <load-spinner variant="table"></load-spinner>
            <table mat-table [dataSource]="dataSource" class="mat-mr" [trackBy]="trackRecord">
        <!-- <div>
            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear class="mat-mr"> -->
                @for(col of cols(); track col.prop){
                    @if(col.isDate){
                        <ng-container [matColumnDef]="col.prop" [sticky]="col.prop == 'date'">
                            <th mat-header-cell *matHeaderCellDef id="col.prop" [class.rightBorder]="col.prop == 'date'"> {{col.name}} </th>
                            <td mat-cell *matCellDef="let item" [class.rightBorder]="col.prop == 'date'">
                                <div class="mobile-label">
                                    {{col.name}}
                                </div>
                                <div class="multiline">
                                    @if(col.dataType == "full"){
                                        <span>
                                            {{item[col.prop] | date : 'shortDate'}}
                                        </span>
                                        <span class="subText">
                                            {{item[col.prop] | date : 'shortTime'}}
                                        </span>
                                    }@else if(col.dataType == "year"){
                                        <span>
                                            {{item[col.prop] | date : 'yyyy'}}
                                        </span>
                                    }@else if(col.dataType == "yearMonth"){
                                        <span>
                                            {{item[col.prop] | date : 'MM/yyyy'}}
                                        </span>
                                    }@else {
                                        <span>
                                            {{item[col.prop] | date : 'MM/dd/yyyy'}}
                                        </span>
                                    }
                                </div>
                            </td>
                        </ng-container>
                    }@else if(col.dataType == 'list') {
                        <ng-container [matColumnDef]="col.prop">
                            <th mat-header-cell *matHeaderCellDef id="col.prop"> {{col.name}} </th>
                            <td mat-cell *matCellDef="let item">
                                <div class="mobile-label">
                                    {{col.name}}
                                </div>
                                <div class="multiline">
                                    @if(item.prospectStages){
                                        @for(stage of item.prospectStages; track stage.stage){
                                            <div class="subText no-overflow">{{stage.stage}}:&nbsp;{{stage.count}}</div>
                                        }
                                    }@else {
                                        <div class="subText">No Stage Data</div>
                                    }
                                </div>
                            </td>
                        </ng-container>
                    }@else {
                        <ng-container [matColumnDef]="col.prop">
                            <th mat-header-cell *matHeaderCellDef id="col.prop"> {{col.name}} </th>
                            <td mat-cell *matCellDef="let item">
                                <div class="mobile-label">
                                    {{col.name}}
                                </div>
                                <div>
                                    {{item[col.prop]}}
                                </div>
                            </td>
                        </ng-container>       
                    }
                }        
                <tr mat-header-row *matHeaderRowDef="displayedCols(); sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedCols();"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedCols().length">
                        <div>
                            There are currently no items in this list
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </mat-card-content>
    
</mat-card>