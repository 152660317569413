<div class="mainContent">
    <!-- <cdk-virtual-scroll-viewport appendOnly> -->
      <!-- <prospect-widget *ngIf="selectedProspect != null"></prospect-widget>
      <prospect-list-widget ></prospect-list-widget> -->
    <!-- </cdk-virtual-scroll-viewport> -->
    <router-outlet></router-outlet>
</div>
<div class="subContent">
  
  <!-- <referrer-list-widget></referrer-list-widget> -->
  <!-- <cdk-virtual-scroll-viewport appendOnly> -->
  <!--  <communication-widget></communication-widget> -->
  <!--  <journey-widget></journey-widget> -->
  <!-- </cdk-virtual-scroll-viewport> -->
</div>