import { Component } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector:'validation-snack-bar',
    templateUrl:'validation.snack-bar.html',
    styleUrls:['validation.snack-bar.scss']
  })
  export class ValidationSnackBar {
    public title:string = "";
    public validation:string[] = [];
    
    constructor() { }
  }