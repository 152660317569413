import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, fromEvent, Subscription, filter, EMPTY } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private activityTimeout: any;
  private activityDelay = 60 * 1000; // 60 seconds
  private keepAliveEndpoint = '/api/v1/user/session/keep-alive'; // The keep-alive endpoint
  private subscriptions: Subscription[] = [];


  public disable(){
    this.keepaliveActive = false;
  }
  public enable(){
    this.keepaliveActive = true;
  }
  private keepaliveActive = true;
  constructor(private http: HttpClient) {
    this.monitorUserActivity();
  }

  // Function to send a keep-alive request to the server
  sendKeepAliveRequest(): Observable<any> {
    if(!this.keepaliveActive){
      return EMPTY;
    }else{
      return this.http.get(this.keepAliveEndpoint, { withCredentials: true });
    }
  }

  // Function to reset the activity timeout
  resetActivityTimeout(): void {
    clearTimeout(this.activityTimeout);
    this.activityTimeout = setTimeout(() => this.sendKeepAlive(), this.activityDelay);
  }

  // Set up event listeners for mouse and keyboard activity
  monitorUserActivity(): void {
    const mouseMove = fromEvent(window, 'mousemove');
    const keyDown = fromEvent(window, 'keydown');

    this.subscriptions.push(
      mouseMove.subscribe(() => this.resetActivityTimeout()),
      keyDown.subscribe(() => this.resetActivityTimeout())
    );

    // Start the timeout initially
    this.resetActivityTimeout();
  }

  // Function to send keep-alive if no activity for the defined period
  private sendKeepAlive(): void {
    this.sendKeepAliveRequest().subscribe({
      next: (response) => console.log('Session is alive'),
      error: (error) => {
        console.log('Session expired or keep-alive request failed', error);
        // Handle session expiration logic here, like redirecting to the login page
      }
    });
  }

  checkSessionStatus(): void {
    this.sendKeepAliveRequest().subscribe({
      next: (response) => console.log('Session is active'),
      error: (error) => {
        console.log('Session expired, redirecting to login');
        window.location.href = '/login';  // Redirect if session expired
      }
    });
  }
  
  // Slightly more than total session timeout - 245 minutes
  private sessionCheckInterval: any = setInterval(() => this.checkSessionStatus(), 245 * 60 * 1000);

  // Unsubscribe from event listeners to avoid memory leaks
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
