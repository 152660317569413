<mat-card appearance="outlined" class="mat-mr mr-accent" [class.expanded-card]="isExpanded">
    <mat-card-title class="titleRow">
        <!-- <person-chip [display]="tempLoad" size="small" header></person-chip> -->
        <span>
            @if(!material.marketingMaterialId){
                Upload Marketing Material
            } @else {
                {{material.title}}
            }
        </span>
        <span></span>
        <!-- <person-icon [display]="tempLoad"></person-icon>
        <span>{{tempLoad.prettyName()}}</span> -->
        <div class="widgetIcons">
            <fa-icon class="resize" [icon]="compressIcon" *ngIf="isExpanded" (click)="compressWidget()"></fa-icon>
            <fa-icon class="resize" [icon]="expandIcon" *ngIf="!isExpanded" (click)="expandWidget()"></fa-icon>
            <fa-icon [icon]="closeIcon" (click)="closeWidget()"></fa-icon>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="mat-vertical-content">
            <form [formGroup]="materialGroup" class="flex-form">
                <div class="form-row">
                    <span class="fill-row">
                        <mat-form-field appearance="fill">
                            <mat-label>Title</mat-label>
                            <input matInput placeholder="File Name" formControlName="title">
                        </mat-form-field>
                    </span>
                </div>
                <div class="form-row">
                    <span class="fill-row ">
                        <mat-form-field appearance="fill">
                            <mat-label>File Type</mat-label>
                            <mat-select formControlName="type">
                                <mat-option *ngFor="let item of selectFileType" [value]="item.guid">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </span> 
                </div>
                <div class="form-row expanded-fill-row">
                    <span class="fill-row">
                        <file-upload class="fileZone" [photos]="file" [originPage]="'marketingmaterial'"
                            [upload]="uploadPhoto" [maxPhotos]="1" accept=".png,.jpg,.jpeg,.pdf"
                            [immediateUpload]="material.marketingMaterialId != null && material.marketingMaterialId != ''"></file-upload>
                    </span>
                </div>
            </form>
            <div class="form-row flex">
                <span class="mr-auto"><!-- [appearance]="'none'"-->
                    <mat-slide-toggle [(ngModel)]="allowAsAttachment">Allow as email attachment</mat-slide-toggle>
                </span>
                @if(companyCfg.communityCRM()){
                    <span class="mr-auto"><!-- [appearance]="'none'"-->
                        <mat-slide-toggle [(ngModel)]="addToAll">Add to All Communities</mat-slide-toggle>
                    </span>
                }
            </div>
            <div class="fill" *ngIf="!addToAll">
                <mat-form-field>
                    <!-- <input matInput placeholder="Gender" formControlName="gender"> -->
                    <mat-select [(ngModel)]="selectedCommunity">
                        <mat-option value="" selected>--</mat-option>
                        <mat-option *ngFor="let item of communities" [value]="item">
                            {{item.nameDisplay}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <load-spinner inline *ngIf="submitting"></load-spinner>
        <button mat-stroked-button color="primary" (click)="closeWidget()">Cancel</button>
        <button mat-flat-button color="primary" (click)="saveMaterial()" [disabled]="submitting || !materialGroup.valid || (!fileUpload.hasAnyFiles()) || (!addToAll && !selectedCommunity)">Submit</button>
    </mat-card-actions>
</mat-card>