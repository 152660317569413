import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable({
    providedIn: 'root'
  })
  export class UserAuthGuard  {
    constructor(private auth:AuthService, private router:Router){
      
    }
    async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      /*if(this.auth.isAuth){
        return true;
      }else{
        let usr = await this.auth.getAuthenticatedUser();
        if(this.auth.isAuth){
          return true;
        }else{
          this.router.navigate(['login']);
          return false;
        }
      }*/
      return true;
    }
    
    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      /*if(this.auth.isAuth){
        return true;
      }else{
        let usr = await this.auth.getAuthenticatedUser();
        if(this.auth.isAuth){
          return true;
        }else{
          this.router.navigate(['login']);
          return false;
        }
      }*/
      return true;
    };
  }