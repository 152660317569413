<mat-card appearance="outlined" class="mat-elevation-z4">
    <mat-card-header class="subText" [matTooltip]="'Day/Month Counts'">
        Website Inquiries
    </mat-card-header>
    <mat-card-content (click)="openList()" matRipple [matTooltip]="'Day/Month Counts'"> 
        <div class="center" *ngIf="!loading">
            <mat-label>
                {{count}}
            </mat-label>
        </div>
        <div class="center" *ngIf="loading">
            <load-spinner inline></load-spinner>
        </div>
    </mat-card-content>
</mat-card>