import { AfterContentInit , Component, Inject, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyConfigMap, Permission, PermissionUser, User } from 'src/app/core/models/user.models';
import { AppService } from 'src/app/core/services/app.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { PersonService } from '../../services/person.service';

@UntilDestroy()
@Component({
    selector: 'user-permission',
    templateUrl: './user-permission.component.html',
    styleUrls: ['../../../form-styles.scss', './user-permission.component.scss']
})

export class UserPermissionWidget implements AfterContentInit {

    user: User;
    tempLoad: User;

    allPermissions:Permission[];
    userPermissions:PermissionUser[];

    allCategoryGroups:any[];

    submitting: boolean = false;

    companyCfg:CompanyConfigMap|null;

    form!:FormGroup;
    
    constructor(
        protected app:AppService,
        protected snackbar: MatSnackBar,
        protected dialog: MatDialog,
        public dialogRef: MatDialogRef<UserPermissionWidget>,
        private api: ApiService,
        private fb:FormBuilder,
        private crd:ChangeDetectorRef,
        private personService: PersonService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.companyCfg = null;
        let isReferrerCrm = false;
        this.personService.companyCfg.pipe(untilDestroyed(this)).subscribe(cfg=>{
          this.companyCfg = cfg;
          isReferrerCrm = (this.companyCfg?.company_crm_type.value == 'Referrer CRM');
        })

        this.user = this.data.user;
        this.tempLoad = this.user;

        this.allPermissions = this.data.allPermissions;
        this.userPermissions = this.data.userPermissions;

        let myPerms = new Map();
        for(let userPermItem of this.userPermissions){
            myPerms.set(userPermItem.permissionName, true);
        }
            
        let categoryName = null;
        let categorySelected = true;
        let categoryPermList = [];
        this.allCategoryGroups = [];
        for(let allPermItem of this.allPermissions){
            if (categoryName != allPermItem.categoryName) {
                if (categoryName != null) {
                    let categoryGroup:any = {};
                    categoryGroup.categoryName = categoryName;
                    categoryGroup.list = categoryPermList;
                    categoryGroup.selected = categorySelected;
                    if (!(!isReferrerCrm && (categoryGroup.categoryName == "Referrer Communities" || categoryGroup.categoryName == "Referrer Staff"))) {
                        this.allCategoryGroups.push(categoryGroup);    
                    }        
                }

                categoryName = allPermItem.categoryName;
                categorySelected = true;
                categoryPermList = [];
            }
            if (myPerms.get(allPermItem.name) != null) {
                allPermItem.selected = true;

            } else if (categorySelected) {
                categorySelected = false;
            }
            categoryPermList.push(allPermItem);
        }

        if (categoryName != null) {
            let categoryGroup:any = {};
            categoryGroup.categoryName = categoryName;
            categoryGroup.list = categoryPermList;
            categoryGroup.selected = categorySelected;
            if (!(!isReferrerCrm && (categoryGroup.categoryName == "Referrer Communities" || categoryGroup.categoryName == "Referrer Staff"))) {
                this.allCategoryGroups.push(categoryGroup);    
            }
        }
        
        let formCategories:any = {};
        for(let category of this.allCategoryGroups){
            formCategories[category.categoryName] = new FormControl<any>([]);
            formCategories[category.categoryName+'All'] = new FormControl(category.selected);
        }

        this.form = this.fb.group(formCategories);    
    }
    
    async ngAfterContentInit() {
    }

    ngAfterContentChecked(): void {
        this.crd.detectChanges();
    }
    
    async savePermissions() {
        this.submitting = true;

        let group = this.form;


        let newPermissions = [];
        this.allCategoryGroups;
        for(let category of this.allCategoryGroups){
            let thisCategory = group.controls[category.categoryName];
            
            for (let permissionName of thisCategory.value) {
                newPermissions.push(permissionName)
            }
        }

        await this.api.updatePermissions(this.user.personId, newPermissions);
        
        this.submitting = false;
        this.dialogRef.close();
    }

    selectAll(categoryName:string, event:any){
        let thisCategory:any = null;
        this.allCategoryGroups.forEach(cg => {
            if (cg.categoryName == categoryName) {
                thisCategory = cg;
            }
        });

        if (thisCategory) {
            let myControls = this.form.controls[categoryName].value;
            let updateListVals:any = [];
            thisCategory.list.forEach((cb:any) => {
                cb.selected = event.checked;
                if (cb.selected) {
                    updateListVals.push(cb.name);
                }
            });
            this.form.controls[categoryName].patchValue(updateListVals);
            this.form.controls[categoryName+'All'].patchValue(event.checked);
            let stop = "here";
        }
    }

    selectOne(categoryName:string, event:any) {
        let eventChecked = event.options[0]._selected;
        let permissionName = event.options[0]._value;
        let updateCheckbox = true;
        let updateListVals:any = [];
        this.allCategoryGroups.forEach(cg => {
            if (cg.categoryName == categoryName) {
                cg.list.forEach((cb:any) => {
                    if (cb.name == permissionName) {
                        cb.selected = eventChecked;
                    }
                    if (cb.selected) {
                        updateListVals.push(cb.name);
                    }
                    else {
                        updateCheckbox = false;
                    }
                });
            }
        });
        this.form.controls[categoryName].patchValue(updateListVals);
        this.form.controls[categoryName+'All'].patchValue(updateCheckbox);
        let stop = "here";
    }

    ngOnDestroy() {
        
    }
}