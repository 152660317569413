<mat-card appearance="outlined" class="mat-elevation-z4 stack">
    <mat-card-header class="subText" [matTooltip]="tooltipTopText()">
        {{topTitle}}
    </mat-card-header>
    <mat-card-content matRipple [matTooltip]="tooltipTopText()"> 
        <div class="center" *ngIf="!loading()">
            <mat-label>
                @if(topFormat()){
                    {{topValue() | number:topFormat()}}
                }@else{
                    {{topValue()}}
                }
            </mat-label>
        </div>
        <div class="center" *ngIf="loading()">
            <load-spinner inline></load-spinner>
        </div>
    </mat-card-content>
    <mat-card-header class="subText" [matTooltip]="tooltipBottomText()">
        {{bottomTitle}}
    </mat-card-header>
    <mat-card-content matRipple [matTooltip]="tooltipBottomText()"> 
        <div class="center" *ngIf="!loading()">
            <mat-label>
                @if(bottomFormat()){
                    {{bottomValue() | number:bottomFormat()}}
                }@else{
                    {{bottomValue()}}
                }
            </mat-label>
        </div>
        <div class="center" *ngIf="loading()">
            <load-spinner inline></load-spinner>
        </div>
    </mat-card-content>
</mat-card>