<h1 mat-dialog-title class="flex row">
    {{tempLoad.prettyName()}}
    <div class="ml-auto lastLogin">
        <div class="subText">Last Login:</div>
        <div class="subText">{{tempLoad.lastLogin ? (tempLoad.lastLogin| date : 'short') : 'Never'}}</div>
    </div>
</h1>
    <mat-dialog-content>
        <form [formGroup]="form" class="flex-form">
                <div class="form-row">
                        <span class="fill-row name">
                        <mat-card appearance="outlined">
                        <mat-card-content>
                            <span class="username">
                                <span>
                                    <mat-form-field appearance="fill">
                                        <mat-label>First Name</mat-label>
                                        <input matInput placeholder="First Name" formControlName="firstname" (keyup)="changeName()">
                                    </mat-form-field>
                                </span>
                    
                                <span>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Last Name</mat-label>
                                        <input matInput placeholder="Last Name" formControlName="lastname" (keyup)="changeName()">
                                    </mat-form-field>
                                </span>
                        
                    
                                <span>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Email</mat-label>
                                        <input matInput placeholder="Email" formControlName="email">
                                    </mat-form-field>
                                </span>
                               

                            </span>
                        </mat-card-content>
                        </mat-card>
                       
                        <mat-card appearance="outlined">
                        <mat-card-content>
                            <span>
                                <mat-form-field appearance="fill">
                                    <mat-label>Title</mat-label>
                                    <input matInput placeholder="Title" formControlName="userTitle" maxlength="32">
                                </mat-form-field>
                            </span>
                            <span class="role">
                                <mat-form-field>
                                    <mat-label>Role</mat-label>
                                    <mat-select formControlName="userRole">
                                        <mat-option value="" selected>--</mat-option>
                                        <mat-option *ngFor="let item of roles" [value]="item.guid">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Timezone</mat-label>
                                    <mat-select formControlName="userTimezone">
                                        <mat-option value="" selected>--</mat-option>
                                        <mat-option *ngFor="let item of timezones" [value]="item">
                                            {{item}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </span>
                            <span *ngIf="activationDate != null && activationDate != ''">
                                    Last Activated: {{activationDate}}
                                </span>
                        </mat-card-content>
                        </mat-card>
                        </span>
                </div>

            <div class="form-row">
                <span class="fill-row">
                            <div class="mat-elevation-z4 raisedFormSection phones">
                                <span class="fill-row">
                                    <mat-label class="form-group-label">Phone Numbers</mat-label>
                                </span>
                                <phone-entry-list [phoneList]="phones" [hideAllow]="hideAllow" [hideHome]="hideHome" #phoneEntry></phone-entry-list>
                            </div>
                </span>
            </div>
               
            <div class="form-row">  
                
                <span class="fill-row community-homepage">
                    <mat-card appearance="outlined" *ngIf="isCommunityCrm">
                    <mat-card-content>
                        <span class="community">
                            <span class="fill-row commlabel-all">
                                <span class="commlabel">
                                    <mat-label>Communities<sup>*</sup></mat-label>
                                </span>
                                <span class="all">
                                    <mat-checkbox formControlName="all" labelPosition="before" (change)="selectAll($event)"></mat-checkbox>
                                </span>
                            </span>
                            <mat-selection-list formControlName="communities">
                            <mat-list-option *ngFor="let community of communities" [value]="community.communityId" 
                                [selected]="community.selected" [disabled]="community.disabled">
                                {{community.communityName}}
                            </mat-list-option>
                            </mat-selection-list>
                        </span>
                    </mat-card-content>
                    </mat-card>
                    <mat-card appearance="outlined">
                    <mat-card-content>
                        <span class="homepage">
                            <div>
                                <mat-label>Default Homepage<sup>*</sup></mat-label>
                            </div>
                            <mat-radio-group class="example-radio-group" formControlName="homepage">
                                <div *ngFor="let page of pages">
                                    <mat-radio-button class="example-radio-button"  [value]="page">{{page}}</mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </span>
                    </mat-card-content>
                    </mat-card>
                </span>
            </div>
    
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions align="end">
        <load-spinner inline *ngIf="submitting"></load-spinner>
        <button *ngIf="!submitting" mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
        <button mat-flat-button color="primary" (click)="saveUser()" [disabled]="!form.valid || submitting">Submit</button>
    </div>