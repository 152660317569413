import { E } from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { CloseReason } from '../../models/lookuptable.models';
import { Community, Prospect, Unit } from '../../models/user.models';
import { PersonService } from '../../services/person.service';

export interface BulkCloseReasonDialogCfg{
    prospects:Prospect[]
    closeReasons:CloseReason[]
}

@Component({
    selector: 'close-prospect-bulk-dialog-component',
    templateUrl: 'close-prospect-bulk-dialog.component.html',
    styleUrls: ['close-prospect-bulk-dialog.component.scss']
})
export class CloseProspectBulkDialogComponent {
    public selectedReason:any = "";
    public closeReasons:CloseReason[] = [];

    public prospects!:Prospect[];//this is the id of the prospect
    public close_reason_id:string = "";
    public close_reason:string = "";
    public close_date:number = 0;
    public close_comments:string = "";

    public formisvalid:boolean = false;

    allCommunities:Community[] = [];
    selectedCommunity:Community[] = [];
    communityReserved:Unit[] = [];
    
    prospectNames:string = "";

    preadmit:boolean = true;
    compareUnitFunction = (o1: any, o2: any)=> o1.unitId===o2.unitId;

    textAreaMaxLength = 256;
    constructor(public dialogRef: MatDialogRef<CloseProspectBulkDialogComponent>, private api:ApiService, @Inject(MAT_DIALOG_DATA) public data: BulkCloseReasonDialogCfg) {
        this.closeReasons = data.closeReasons;

        if(data.closeReasons != null && data.closeReasons != undefined && data.closeReasons.length > 0) {
            this.selectedReason = data.closeReasons[0];
        }
        this.prospects = data.prospects;
        if (this.prospects == null || this.prospects == undefined) {
            this.prospects = [];
        }
    }

    onCancel(): void {
        this.dialogRef.close("cancel");
    }

    async onSubmit() {
        var saveObjects:any = [];
        var now = Date.now()

        this.prospects.forEach((p: any)=>{
            var saveObject:any = {};
            saveObject.personId = p.personId;
            saveObject.close_reason_id = this.selectedReason.guid
            saveObject.close_reason = this.selectedReason.name;
            saveObject.communityId = null;
    
            saveObject.close_date = now;
            saveObject.close_comments = this.close_comments;
    
            saveObjects.push(saveObject);
          });
        
        //await this.api.closeProspect(saveObject); //ZH, 06/10,24, bug 47741: now make this call after confirmatioon in the "Are you sure?" dialog
        this.dialogRef.close(saveObjects); //ZH, 06/10,24, bug 47741: now return saveObject.  This allows a second, "Are you sure?" dialog to appear after this one is closed.
    }
    
    isValid() {
        let valid = true;
        if(this.selectedReason.notesRequired && this.close_comments.trim().length <= 0){
            valid = false;
        }
                
        return valid;
    }
}