import { Component, ElementRef, EventEmitter, forwardRef, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { VideoHandler, ImageHandler, Options} from 'ngx-quill-upload';
import Quill from 'quill'; // Import the Quill class from the correct location
import { GenericConfirmDialogComponent } from '../../dialog/generic-confirm-dialog.component';
import { EmailAttachDialogComponent, EmailAttachDialogData } from '../../dialog/email-send/email-attach.dialog';

//Quill.register('modules/mention', Mention);

@Component({
  selector: 'email-quill-editor',
  template: `<div id="quill-editor">
  </div><div id="toolbar"></div>`,
  styleUrls: ['./email-quill-wrapper.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuillEditorWrapperComponent),
      multi: true
    }
  ]
})
export class QuillEditorWrapperComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private quillEditor!: Quill;
  private onChangeCallback!: ((value: string) => void);
  private onTouchedCallback!: (() => void);
  @ViewChild('emailAttach') emailAttach: ElementRef | undefined;
  @Output() onMarketingPicked = new EventEmitter<any>();
  
  private attachments: any[] = [];


  constructor(private elem: ElementRef, private dialog:MatDialog) {
    
  }


 

  ngOnInit(): void {
    const atValues = [
      { id: 1, value: "prospect_first_name" },
      { id: 2, value: "first_names" }
    ];
    const hashValues = [
      { id: 1, value: "prospect_first_name" },
      { id: 2, value: "first_names" }
    ];

    this.quillEditor = new Quill('#quill-editor', {
      theme: 'snow',
      modules: {
        toolbar: {
          container:
          [
            ['bold', 'italic', 'underline',{ 'list': 'bullet'}, 'link','image','attachment','placeholder']
          ],
          /*imageHandler: {
            upload: (file) => {
              this.attachments.push(file)
             //return // your uploaded image URL as Promise<string>
            },
            accepts: ['png', 'jpg', 'jpeg', 'jfif'] // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
          } as Options,*/
          handlers: {
            "attachment": (value: any) => { 
              if (value) {
                const dialogRef = this.dialog.open(EmailAttachDialogComponent, {
                    disableClose: true,
                    height: 'auto',
                    width: 'auto',        
                    data: {
                      filesystemCallback: ()=>{
                        const input: HTMLDivElement = this.elem.nativeElement;
                        //const input = document.querySelector('input[type=file]') as HTMLInputElement;
                        if (input) {
                          input.click();
                        }
                      }
                    } as EmailAttachDialogData
                });
                // let callback = new Subject<any>();
                
                dialogRef.afterClosed().subscribe(async result => {
                  if(result !== "cancel"){
                    console.log(result)
                    this.onMarketingPicked.emit(result);
                  }
                });
              }
            }
          }
        },
        // placeholder: {
        //   delimiters: ['{{', '}}'],  // default
        //   placeholders: [
        //     {id: 'foo', label: 'Foo'},
        //     {id: 'required', label: 'Required', required: true}
        //   ]
        // }
          
      }
    });

    

    this.quillEditor?.on('text-change', () => {
      this.onChangeCallback?.(this.quillEditor.root.innerHTML);
      this.onTouchedCallback?.();
    });

    const attachmentButton = document.querySelector('.ql-attachment');
    if (attachmentButton) {
      attachmentButton.innerHTML = `<svg fill="#000000" height="14px" width="14px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 30.34 30.34" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M22.562,12.491c0,0,1.227-0.933,0.293-1.866c-0.934-0.933-1.842,0.271-1.842,0.271l-9.389,9.391 c0,0-2.199,2.838-3.871,1.122c-1.67-1.718,1.121-3.872,1.121-3.872l12.311-12.31c0,0,2.873-3.165,5.574-0.466 c2.697,2.7-0.477,5.579-0.477,5.579L12.449,24.173c0,0-4.426,5.113-8.523,1.015s1.066-8.474,1.066-8.474L15.494,6.209 c0,0,1.176-0.982,0.295-1.866c-0.885-0.883-1.865,0.295-1.865,0.295L1.873,16.689c0,0-4.549,4.989,0.531,10.068 c5.08,5.082,10.072,0.533,10.072,0.533l16.563-16.565c0,0,3.314-3.655-0.637-7.608s-7.607-0.639-7.607-0.639L6.543,16.728 c0,0-3.65,2.969-0.338,6.279c3.312,3.314,6.227-0.39,6.227-0.39L22.562,12.491z"></path> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g></svg>`;
    }
   
  }


  getFiles(event: any) {
    console.log(event);
  }

  ngAfterViewInit() {
   
    this.elem.nativeElement = document.querySelector('input[type=file]');
    
  }

  textChanged(): void {
    this.onChangeCallback(this.quillEditor.root.innerHTML);
    this.onTouchedCallback();
  }

  ngOnDestroy(): void {
   // this.quillEditor.off('text-change');
  }

  writeValue(value: string): void {
    this.quillEditor.root.innerHTML = value || '';
  }

  registerOnChange(callback: (value: string) => void): void {
    this.onChangeCallback = callback;
  }

  registerOnTouched(callback: () => void): void {
    this.onTouchedCallback = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.quillEditor.enable(!isDisabled);
  }
}