<h1 mat-dialog-title>Preadmit this Prospect</h1>
<mat-dialog-content>
    <span>
        <chip-autocomplete class="fill"
            label="Select a Community"
            placeholder="Community"
            idField="communityId"
            displayField="nameDisplay"
            [fullData]="prospect.communitiesOfInterest"
            [selectedData]="selectedCommunity"
            (onChange)="selectCommunity($event)">
        </chip-autocomplete>
    </span>
    <span *ngIf="selectedCommunity && selectedCommunity.length > 0">
        <mat-selection-list #units [multiple]="false" [(ngModel)]="selectedUnit" [compareWith]="compareUnitFunction" >
            <mat-list-option *ngFor="let unit of communityReserved" [value]="unit">
              {{unit.nameDisplay}}
            </mat-list-option>
        </mat-selection-list>
        <span *ngIf="communityReserved == null || communityReserved == undefined || communityReserved.length == 0">No unoccupied or non-reserved units were found.</span>
    </span>
</mat-dialog-content>


<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="onCancel()">Cancel</button>
  <button mat-button mat-dialog-close (click)="onSubmit()" [disabled]="!isValid()" cdkFocusInitial>Submit</button>
</div>
