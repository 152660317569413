
<div>
    {{referrer()?.nameDisplay}}
    <form [formGroup]="chatForm">  
        <div>
            <input type="hidden" formControlName="mobileNumber" placeholder="+13077453810" name="mobileNumber"/>
            <div class="selectionBoxes" >
                <mat-list class="contactSelect">
                    <mat-list-item *ngIf="selectedPhone == null" [matMenuTriggerFor]="messageMenu">
                        <span>
                            <span>Select a Conversation</span>
                            <span class="subText"></span>
                            <mat-icon matListItemIcon>arrow_drop_down</mat-icon>
                        </span>
                    </mat-list-item>
                    <mat-list-item *ngIf="selectedPhone != null && selectedPhone.itemType != 'Referrer' && selectedPhone.itemType != 'Organization'" [matMenuTriggerFor]="messageMenu">
                        <span>
                            <span>{{selectedPhone.contact.nameDisplay}}</span>
                            <span class="subText">{{selectedPhone.phone.number | mask:"(000) 000-0000" : { prefix: "" } }}</span>
                            <mat-icon matListItemIcon>arrow_drop_down</mat-icon>
                        </span>
                    </mat-list-item>
                    <mat-list-item *ngIf="selectedPhone != null && !(selectedPhone.itemType != 'Referrer' && selectedPhone.itemType != 'Organization')">
                        <span>
                            <span>{{selectedPhone.contact.nameDisplay}}</span>
                            <span class="subText">{{selectedPhone.phone.number | mask:"(000) 000-0000" : { prefix: "" } }}</span>
                        </span>
                    </mat-list-item>
                </mat-list>
                <mat-menu #messageMenu="matMenu">
                    <div *ngFor="let contact of mobiles">
                        <mat-label>{{contact.contact.nameDisplay}}</mat-label>
                        <ng-container *ngFor="let phone of contact.phones">
                            <button mat-menu-item (click)="selectPhone(contact.contact, phone, selectedPhone?.itemType)">
                                <!-- <fa-icon *ngIf="phone.is_primary" [icon]="starIcon" class="primary-icon"></fa-icon> -->
                                {{phone.number | mask:"(000) 000-0000" : { prefix: "" } }} {{phone.extension != null && phone.extension.length > 0 ? "Ext:" + phone.extension : ""}}
                            </button>
                            <mat-divider></mat-divider>
                        </ng-container>
                    </div>
                    <div *ngIf="mobiles.length == 0">
                        <button mat-menu-item>
                            No mobile numbers found
                        </button>
                    </div>
                </mat-menu>
                <!-- <mat-list class="communitySelect">
                    <mat-list-item *ngIf="selectedCommunity == null" [matMenuTriggerFor]="communityMenu">
                        <span>
                            <span>Select a Community</span>
                            <span class="subText"></span>
                            <mat-icon matListItemIcon>arrow_drop_down</mat-icon>
                        </span>
                    </mat-list-item>
                    <mat-list-item *ngIf="selectedCommunity != null" [matMenuTriggerFor]="communityMenu">
                        <span>
                            <span>{{selectedCommunity.communityName}}</span>
                            <mat-icon matListItemIcon>arrow_drop_down</mat-icon>
                        </span>
                    </mat-list-item>
                </mat-list>
                <mat-menu #communityMenu="matMenu" xPosition="after">
                    <button mat-menu-item *ngFor="let community of communityList" (click)="selectCommunity(community)">{{community.communityName}}</button>
                </mat-menu> -->
            </div>
            <!-- <mat-form-field appearance="fill">
                <mat-label>Mobile Number</mat-label>
                <mat-select formControlName="mobileNumber">
                  <mat-optgroup *ngFor="let group of prospect" [label]="group.name"
                                [disabled]="group.disabled">
                    <mat-option *ngFor="let pokemon of group.pokemon" [value]="pokemon.value">
                        {{pokemon.viewValue}}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
            </mat-form-field> -->
        </div>
        <div class="mat-excavation-z4 chatHistory" #chatHistory>
            <div class="message" *ngFor="let msg of chatMsgs; let last = last; let index = index" [class.person]="msg.status === 'Received'" [class.me]="msg.status === 'Sent'" [ngClass]="getColor(msg.status)">
                <div>
                    {{msg.message}}
                </div>
                <div class="dateItem subText" *ngIf="last || chatMsgs[index].status != chatMsgs[index + 1].status">{{msg.rawDate | date:'short'}}</div>
            </div>
            <load-spinner *ngIf="loading"></load-spinner>
        </div>
        <div class="chatMessage">
            <mat-form-field>
                <textarea matInput placeholder="Text message" formControlName="message" cdkTextareaAutosize cdkAutosizeMinRows="1" (keydown.enter)="$event.preventDefault()" (keyup.enter)="sendChat()"></textarea>
            </mat-form-field>
            <button class="mat-elevation-z2 fa-icon-fab" mat-mini-fab (click)="sendChat()" matTooltip="Send" [disabled]="selectedPhone == null"><fa-icon [icon]="sendIcon"></fa-icon></button>
        </div> 
    </form>
</div>
