import { E } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewChild, WritableSignal, signal } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { MarketingMaterial } from '../../models/user.models';
import { PersonService } from '../../services/person.service';
import { DataPosition, InfiniteRequestData } from '../../directives/infinite-scroll-table.directive';
import { MatTableDataSource } from '@angular/material/table';
import { Page } from '../../models/spring.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSelectionList } from '@angular/material/list';
import { Router } from '@angular/router';


interface MarketingMaterialSearch extends InfiniteRequestData{
    companyId:string
    communityId:string
  }
export interface EmailAttachDialogData {
    filesystemCallback: () => void
    companyId:string
}
@UntilDestroy()
@Component({
    selector: 'email-attach-dialog-component',
    templateUrl: 'email-attach.dialog.html',
    styleUrls: ['email-attach.dialog.scss', '../../../table.responsive.scss']
  })
  export class EmailAttachDialogComponent implements OnInit{
    linkIcon = faLink;
    
    public title: string = "";
    public content: string = "";
    public submitText: string = "Submit";
    rawData:MarketingMaterial[] = [];
    searchTotal:number|null = null;
    selected = signal<MarketingMaterial|null>(null);
    
    limitCommunities:boolean = false;
    
    @ViewChild(MatSelectionList) materialList!:MatSelectionList;
    
    private companyId:string;

    requestData:WritableSignal<MarketingMaterialSearch> = signal({companyId:"", communityId:"", search:"", count:20, page:0, ready:false})

    constructor(
        public dialogRef: MatDialogRef<EmailAttachDialogComponent>, 
        protected router:Router,
        private api:ApiService, 
        private personService:PersonService, 
        @Inject(MAT_DIALOG_DATA) public data: EmailAttachDialogData) {
      this.companyId = personService.user()?.company.companyId as string;


      if(this.router.url?.startsWith('/prospect')){
        personService.selectedProspect.pipe(untilDestroyed(this)).subscribe(p=>{
            let communityId = p?.communitiesOfInterest[0]?.id;
            if(!communityId){
              communityId = "";
            }
            this.requestData.update(d=>({...d, companyId:this.companyId, communityId: communityId, ready: true}));
        })
      }else{
        this.requestData.update(d=>({...d, companyId:this.companyId, communityId: "", ready: true}));
      }
      
    }
    
    async ngOnInit(){
    }
    
    onCancel(): void {
        this.dialogRef.close("cancel");
    }

    async onSubmit() {
        this.dialogRef.close(this.materialList.selectedOptions.selected.map(o=>o.value as MarketingMaterial));
    }

    onScroll = async (page:number, position:DataPosition):Promise<Page<MarketingMaterial>> => {
      let foundData = await this.findData(this.requestData(), page, position);
  
      return foundData;
    }
    async findData(request:MarketingMaterialSearch, page:number, position:DataPosition): Promise<Page<MarketingMaterial>>{
      let data = await this.api.getPage("marketingmaterial/list", MarketingMaterial.fromJson, request.companyId, page, request.count, request.search, {"communityId":request.communityId});
      // let data = await {content:[] as MarketingMaterial[]} as Page<MarketingMaterial>;
      if (data.totalElements !== undefined && data.totalElements !== null) {
        this.searchTotal = data.totalElements;
      }

      switch(position){
        case DataPosition.Top:
          this.rawData = [...data.content, ...this.rawData];
          break;
        case DataPosition.Bottom:
          this.rawData = [...this.rawData, ...data.content];
          break;
        case DataPosition.Clear:
          this.rawData = data.content;
          break;
      }

      return data;
    }

    trackMarketingMaterial(index:number, item:MarketingMaterial) {
      return item.marketingMaterialId;
    }
}