import { ProductType } from "./lookuptable.models";
import { Community, Company, Floorplan, Influencer, Notification, LoggedEvent, MarketRate, Organization, Permission, PermissionUser, Person, Prospect, Referrer, ReferrerCommunity, ReferrerStaff, ScheduledActivity, TodoTask, User } from "./user.models";

export class DataMapper{
    static activityFromData(obj:any):ScheduledActivity{
        let data:ScheduledActivity = {...obj} as ScheduledActivity;
        data.startTime = new Date(obj.startTime);
        data.endTime = new Date(obj.endTime);
        if(obj.completeTime){
            data.completeTime = new Date(obj.completeTime);
        }
        data.person = DataMapper.personFromData(obj.person) as Person;
        data.salesCounselor = DataMapper.personFromData(obj.salesCounselor) as Person;
        data.people = obj.people.map(DataMapper.personFromData);
        if(data.type == "Prospect"){
            data.personType = "Prospect";
        }else if(data.type == "Referrer"){
            data.stage = undefined;
        }
        
        if(obj.savedNote){
            data.savedNote = {
                datestamp: new Date(obj.savedNote.datestamp),
                note: obj.savedNote.note
            }
        }
        return data;
    }
    static todoFromData(obj:any):TodoTask{
        let data:TodoTask = {...obj} as TodoTask;
        data.completionTime = new Date(obj.completionTime);
        data.prospect = DataMapper.personFromData(obj.prospect) as Person;
        data.salesCounselor = DataMapper.personFromData(obj.salesCounselor) as Person;
        return data;
    }
    
    //Wrapper for compatibility, use Person.fromJson instead.
    static personFromData(obj:any):Person|null{
        return Person.fromJson(obj);
    }
    
    static userFromData(obj:any):User|null{
        return User.fromJson(obj);
    }
        
    static prospectFromData(obj:any):Prospect|null{
        return Prospect.fromJson(obj);
    }
    
    static influencerFromData(obj:any):Influencer{
        return Influencer.fromJson(obj);
    }

    static referrerStaffFromData(obj:any):ReferrerStaff{
        return ReferrerStaff.fromJson(obj);
    }

    static organizationFromData(obj:any):Organization{
        return Organization.fromJson(obj);
    }

    static referrerCommunityFromData = (obj:any):ReferrerCommunity => ReferrerCommunity.fromJson(obj);


    static loggedEventFromData(obj:any):LoggedEvent{
        let loggedEvent = new LoggedEvent();
        
        loggedEvent.eventId = obj.eventId;
        loggedEvent.targetId = obj.targetId;
        loggedEvent.type = obj.type;
        loggedEvent.subtype = obj.subtype;
        loggedEvent.description = obj.description;

        if (obj.datestamp != null) {
            loggedEvent.datestamp = new Date(obj.datestamp);
        }
        
        var myUser = this.userFromData(obj.user);
        if (myUser != null) {
            loggedEvent.user = myUser;
        }else{
            loggedEvent.user = this.userFromData({"firstname":"Docentt", "lastname":"System"}) as User;
        }
        
        if (obj.organization == null) {
            loggedEvent.organization = new Organization();
        } else {
            loggedEvent.organization = this.organizationFromData(obj.organization);  
        }
        loggedEvent.company = obj.company;
        loggedEvent.deleted = obj.deleted;

        return loggedEvent;
    }

    static notificationFromData(obj:any):Notification{
        let notification = new Notification();
        
        notification.eventId = obj.eventId;
        notification.targetId = obj.targetId;
        notification.type = obj.type;
        notification.description = obj.description;

        if (obj.datestamp != null) {
            notification.datestamp = new Date(obj.datestamp);
        }
        
        var myUser = this.userFromData(obj.user);
        if (myUser != null) {
            notification.user = myUser;
        }else{
            notification.user = this.userFromData({"firstname":"Docentt", "lastname":"System"}) as User;
        }
        
        notification.company = obj.company;
        return notification;
    }

    static floorplanFromData(obj:any):Floorplan|null{
        if(obj == null){
            return null;
        }
        let fp = new Floorplan();
        fp.floorplanId = obj.floorplanId;
        fp.name = obj.name;
        fp.bedCount = obj.bedCount;
        fp.bathCount = obj.bathCount;
        fp.squareFeet = obj.squareFeet;
        fp.privacy = obj.privacy;
        fp.occupancyCount = obj.occupancyCount;
        fp.community = DataMapper.communityFromData(obj.community);
        fp.companyId = obj.companyId;
        fp.deleted = obj.deleted;
    
        fp.amenities = obj.amenities ? obj.amenities : [];
        fp.marketRates = obj.marketRates ? obj.marketRates : [];
        for(let mk of fp.marketRates as MarketRate[]){
            if (mk.startDate !== undefined && mk.startDate !== null) {
                mk.startDate = new Date(mk.startDate);
            }
        }
        fp.photoIds = obj.photoIds ? obj.photoIds : [];
        return fp;
    }

    static companyFromData(obj:any):Company{
        let company = {} as Company;
        if(obj == null){
            return company;
        }else{
            company.companyId = obj.companyId;
            company.companyName = obj.companyName;
            company.deleted = obj.deleted;
            
            return company;
        }
    }

    static communityFromData(obj:any):Community{
        return Community.fromJson(obj);
    }

    static permissionFromData(obj:any):Permission{
        let permission = new Permission();
        
        permission.name = obj.name
        permission.superadmin = obj.superadmin;
        permission.hidden = obj.hidden;
        permission.label = obj.label;
        permission.description = obj.description;

        if (!(!permission.name) && permission.name.includes(":") && permission.name.length > 2) {
            let namesplit = permission.name.split(":");   
            permission.categoryNameRaw = namesplit[0];
            permission.categoryName = namesplit[0][0].toUpperCase() + namesplit[0].slice(1);
            if (permission.categoryName.includes("-") && permission.categoryName.length > 2) {
                let catNameSplit = permission.categoryName.split("-");
                permission.categoryName = "";
                for (let i=0; i<catNameSplit.length; i++) {
                    if (permission.categoryName.length > 0) {
                        permission.categoryName +=  " ";
                    }
                    permission.categoryName += catNameSplit[i][0].toUpperCase() + catNameSplit[i].slice(1)
                }
            }

            permission.permissionName = namesplit[1][0].toUpperCase() + namesplit[1].slice(1);  
            if (permission.permissionName.includes("-") && permission.permissionName.length > 2) {
                let permNameSplit = permission.permissionName.split("-");
                permission.permissionName = "";
                for (let i=0; i<permNameSplit.length; i++) {
                    if (permission.permissionName.length > 0) {
                        permission.permissionName +=  " ";
                    }
                    permission.permissionName += permNameSplit[i][0].toUpperCase() + permNameSplit[i].slice(1);
                }
            }
            
            if (permission.permissionName.includes("/") && permission.permissionName.length > 2) {
                let permNameSplit = permission.permissionName.split("/");
                permission.permissionName = "";
                for (let i=0; i<permNameSplit.length; i++) {
                    if (permission.permissionName.length > 0) {
                        permission.permissionName +=  "/";
                    }
                    permission.permissionName += permNameSplit[i][0].toUpperCase() + permNameSplit[i].slice(1);
                }
            }
        }
        
        return permission;
    }

    static permissionUserFromData(obj:any):PermissionUser{
        let permissionUser = new PermissionUser();
        
        permissionUser.personId = obj.id.personId;
        permissionUser.permissionName = obj.id.permissionName;

        return permissionUser;
    }

}