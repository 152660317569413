<mat-card appearance="outlined" class="mat-mr">
    <mat-card-title class="titleRow">
        <span>
            <span>User Management<span *ngIf="searchTotal != null"> ({{searchTotal}})</span></span>
        </span>
        <span>
        </span>
        <div class="widgetIcons">
            <!-- <fa-icon class="resize" [icon]="compressIcon" *ngIf="isExpanded" (click)="compressWidget()"></fa-icon>
            <fa-icon class="resize" [icon]="expandIcon" *ngIf="!isExpanded" (click)="expandWidget()"></fa-icon> -->
        </div>
    </mat-card-title>
    <mat-card-content>
        <span class="flex-row search-row">
            <search-input [(filter)]="requestData" [matTooltip]="'Search for a User.'"></search-input>
            <button class="ml-auto" mat-raised-button (click)="editUserAutoAssign()">Web Lead Auto Assign</button>
        </span>
        <div infiniteScroll [onScroll]="onScroll" [filter]="requestData()">
            <load-spinner variant="table"></load-spinner>
            <table mat-table [dataSource]="dataSource" class="mat-mr" [trackBy]="trackUser">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="multiLine">
                            <span>User&nbsp;Name</span>
                            <span class="subText">Last&nbsp;Login</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let user" (click)="editUser(user)">
                        <div class="mobile-label">
                            <div class="multiLine">
                                <span>User&nbsp;Name</span>
                                <span class="subText">Last&nbsp;Login</span>
                            </div>
                        </div>
                        <div>
                            <div class="multiLine">
                                <div class="linkItem">{{user.prettyName()}}</div>
                                <span class="subText">{{user.lastLogin ? (user.lastLogin| date : 'short') : 'Never'}}</span>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let user">
                        <div class="mobile-label"> Email </div>
                        <div>{{user.emailDisplay}}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef> Role </th>
                    <td mat-cell *matCellDef="let user">
                        <div class="mobile-label"> Role </div>
                        <div>{{user.userRoleDisplay}}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status</th>
                    <td mat-cell *matCellDef="let user">
                        <div class="mobile-label">  </div>
                        <div>{{user.status}}</div>
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="activation">
                    <th mat-header-cell *matHeaderCellDef> Activated </th>
                    <td mat-cell *matCellDef="let user">
                        <div class="mobile-label"> Activated </div>
                        <div>{{user.activationDateDisplay}}</div>
                    </td>
                </ng-container> -->
                <ng-container matColumnDef="community-name">
                    <th mat-header-cell *matHeaderCellDef> Communities </th>
                    <td mat-cell *matCellDef="let user">
                        <div class="mobile-label"> Communities </div>
                        <div [innerHtml]="user.communityDisplay"></div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="table-more">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let user">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="inactivateUser(user)">
                            <fa-icon [icon]="user.active ? onIcon : offIcon"></fa-icon>
                                <span *ngIf="user.active">Inactivate</span>
                                <span *ngIf="!user.active">Activate</span>
                            </button>
                            <button mat-menu-item (click)="blockUser(user)">
                            <fa-icon [icon]="user.locked ? unbanIcon : banIcon"></fa-icon>
                                <span *ngIf="!user.locked">Block</span>
                                <span *ngIf="user.locked">Unblock</span>
                            </button>
                            <button mat-menu-item (click)="editUserPermissions(user)">
                                <fa-icon [icon]="lockIcon"></fa-icon>
                                <span>Permissions</span>
                            </button>
                            <button mat-menu-item *ngIf="currentUser()?.superadmin" (click)="toggleSuperadmin(user)">
                                <fa-icon [icon]="user.superadmin ? demoteIcon : promoteIcon"></fa-icon>
                                <span *ngIf="!user.superadmin">Grant Superadmin Status</span>
                                <span *ngIf="user.superadmin">Revoke Superadmin Status</span>
                            </button>
                            <button mat-menu-item (click)="reassignUser(user)">
                                <fa-icon [icon]="arrowIcon"></fa-icon>
                                <span>Reassign</span>
                            </button>
                            <button mat-menu-item *ngIf="!user.activationDateDisplay" (click)="resendInvite(user)">
                                <fa-icon [icon]="resendIcon"></fa-icon>
                                <span>Resend Invite</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let user;" [class.selected]="selected && selected.personId == user.personId"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedColumns.length">There are currently no Users</td>
                </tr>
            </table>
        </div>
        <floating-add-button (clickOrKey)="newUser()"></floating-add-button>
    </mat-card-content>
    
</mat-card>