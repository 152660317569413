import { Injectable } from '@angular/core';
import { Prospect, User } from '../models/user.models';

@Injectable()
export class FormService {
    public eye_colors = [
        "Black",
        "Blue",
        "Brown",
        "Green",
        "Hazel",
        "Mixed"
    ]
    
    public race = [
        "American Indian or Alaska Native", 
        "Asian/Asian-American",
        "Black or African American", 
        "Hispanic or Latino", 
        "Native Hawaiian or Other Pacific Islander", 
        "White/Caucasian", 
        "Other"
    ]
    
    public religions = [
        "Adventist", "Atheist", "Baha'i Faith", "Baptist", "Buddhist", "Catholic", "Christian",
        "Church of God", "Church of Nazarene", "Episcopalian", "Evangelical", "Friends", "Greek Orthodox", 
        "Hindu", "Jehovah's Witness", "Jewish", "Latter Day Saints", "Lutheran", "Mennonite", "Methodist",
        "Muslim", "Non Denominational", "Non Designated", "Pentecostal", "Presbyterian", "Protestant", 
        "Russian Orthodox", "Scientology", "Shinto", "Unitarian", "Unknown", "Wiccan", "Other"
    ];

    public education_amounts = [
        "None", 
        "Elementary", 
        "High School", 
        "Some College", 
        "Bachelor's Degree", 
        "Master's Degree", 
        "Doctorate Degree"
    ];
    
    public pet_types = [
        "Amphibian", 
        "Bird", 
        "Cat", 
        "Dog", 
        "Fish", 
        "Hermit Crab", 
        "Insect", 
        "Reptile", 
        "Small Mammal", 
        "Spider", 
        "Other"
    ];
    
    public time_zones = [
        'US/Eastern', 
        'US/Central', 
        'US/Mountain', 
        'US/Pacific', 
        'US/Arizona'
    ]
  
    constructor() {}
  
}