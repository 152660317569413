import { Injectable } from '@angular/core';
import { IUnit, Prospect, Unit } from '../models/user.models';
import { ApiService } from './api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CloseProspectDialogComponent, CloseReasonDialogCfg as CloseProspectDialogCfg } from '../dialog/prospect-close/close-prospect-dialog.component';
import { PreadmitProspectDialogComponent, PreadmitReasonDialogCfg as PreadmitProspectDialogCfg } from '../dialog/prospect-preadmit/preadmit-prospect-dialog.component';
import { CloseReason } from '../models/lookuptable.models';
import { BulkCloseReasonDialogCfg, CloseProspectBulkDialogComponent } from '../dialog/prospect-close/close-prospect-bulk-dialog.component';
import { AssignProspectDialogCfg, AssignProspectDialogComponent } from '../dialog/prospect-assign/prospect-assign-dialog.component';
import { AssignProspectBulkDialogComponent } from '../dialog/prospect-assign/prospect-assign-bulk-dialog.component';

@Injectable()
export class ProspectService {
    
    constructor(
        private api:ApiService, 
        private dialog: MatDialog,
        private snackbar:MatSnackBar) {
    }
  
    async assignProspect(prospect:Prospect){
        let dialogRef = this.dialog.open(AssignProspectDialogComponent, {
          disableClose: true,
          height: 'auto',
          width: '450px',
          data: {
              prospects:[prospect],
          } as AssignProspectDialogCfg
      });
      return dialogRef;
    }

    async assignBulkProspects(prospects:Prospect[], ids?:string[]){
      let dialogRef = this.dialog.open(AssignProspectBulkDialogComponent, {
        disableClose: true,
        height: 'auto',
        width: '450px',
        data: {
            prospects:prospects,
            ids:ids
        } as AssignProspectDialogCfg
    });
    return dialogRef;
  }


    async closeProspect(prospect:Prospect, preadmit?:boolean){
      let closeReasons:CloseReason[] = [];
      let units:Unit[] = [];
      try {
        let promises = [
          this.api.getLookupTable("close_prospect_reason", false),
          this.api.get(`prospect/reservation/${prospect.personId}`)
        ]
        
        let results = await Promise.all(promises);
        closeReasons = results[0];
        units = (results[1] as IUnit[]).map(o=>new Unit(o));
      } catch(e){
        console.error(e);
      }

      const dialogRef = this.dialog.open(CloseProspectDialogComponent, {
        disableClose: true,
        height: 'auto',
        width: '450px',        
        data: {
            closeReasons: closeReasons, 
            prospect: prospect,
            units: units,
            defaultToPreadmit:preadmit
        } as CloseProspectDialogCfg,
      });

      return dialogRef;
    }

    async closeBulkProspects(prospects:Prospect[]){
      let closeReasons:CloseReason[] = [];
      let units:Unit[] = [];
      try {
        let promises = [
          this.api.getLookupTable("close_prospect_reason", false),
        ];
        
        let results = await Promise.all(promises);
        closeReasons = results[0];
      } catch(e){
        console.error(e);
      }

      const dialogRef = this.dialog.open(CloseProspectBulkDialogComponent, {
        disableClose: true,
        height: 'auto',
        width: '450px',        
        data: {
            closeReasons: closeReasons, 
            prospects: prospects
        } as BulkCloseReasonDialogCfg,
      });

      return dialogRef;
    }

    async preadmitProspect(prospect:Prospect, preadmit?:boolean){
      let units:Unit[] = [];
      try {
        let promises = [
          this.api.get(`prospect/reservation/${prospect.personId}`)
        ]
        
        let results = await Promise.all(promises);
        units = (results[0] as IUnit[]).map(o=>new Unit(o));
      } catch(e){
        console.error(e);
      }

      const dialogRef = this.dialog.open(PreadmitProspectDialogComponent, {
        disableClose: true,
        height: 'auto',
        width: '450px',        
        data: {
            prospect: prospect,
            units: units
        } as PreadmitProspectDialogCfg,
      });

      return dialogRef;
    }
}