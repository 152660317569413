<mat-card appearance="outlined" class="mat-mr mr-accent" [class.expanded-card]="isExpanded">
    <mat-card-title class="titleRow">
        <!-- <person-chip [display]="tempLoad" size="small" header></person-chip> -->
        <span>
            @if(!event.eventId){
                New Event
            } @else {
                {{event.name}}
            }
        </span>
        <span></span>
        <!-- <person-icon [display]="tempLoad"></person-icon>
        <span>{{tempLoad.prettyName()}}</span> -->
        <div class="widgetIcons">
            <fa-icon class="resize" [icon]="compressIcon" *ngIf="isExpanded" (click)="compressWidget()"></fa-icon>
            <fa-icon class="resize" [icon]="expandIcon" *ngIf="!isExpanded" (click)="expandWidget()"></fa-icon>
            <fa-icon [icon]="closeIcon" (click)="closeWidget()"></fa-icon>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="mat-vertical-content">
            <form [formGroup]="eventGroup" class="flex-form">
                <div class="form-row">
                    <span class="fill-row">
                        <mat-form-field appearance="fill">
                            <mat-label>Name</mat-label>
                            <input matInput placeholder="Name" formControlName="name" [maxLength]="128" >
                            <mat-hint align="end" *ngIf="eventGroup.controls['name'].value != null">Characters: {{eventGroup.controls['name'].value.length}}/128</mat-hint>
                        </mat-form-field>
                    </span>
                </div>
                <div class="form-row">
                    <span class="flex-row fill">
                        <mat-form-field appearance="fill">
                            <mat-label>Date</mat-label>
                            <input matInput [matDatepicker]="eventDatePicker" formControlName="eventDate">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="eventDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #eventDatePicker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="flex-time">
                            <mat-label>Start Time</mat-label>
                            <input matInput type="time" formControlName="startTime">
                        </mat-form-field>
                        <mat-form-field class="flex-time">
                            <mat-label>End Time</mat-label>
                            <input matInput type="time" formControlName="endTime">
                        </mat-form-field>
                    </span>
                </div>
                <!-- <div class="form-row">
                    <span class="fill-row">
                        <div class="flex-row flex-items fill">
                        </div>
                    </span>
                </div> -->
                <div class="form-row">
                    <span class="fill-row">
                        <span class="fill-row flex-items">
                            <span class="grow">
                              <mat-form-field>
                                  <mat-label>Host</mat-label>
                                  <mat-select formControlName="host">
                                      <mat-option value="" selected>--</mat-option>
                                      <mat-option *ngFor="let item of allUsers" [value]="item.personId">
                                          {{item.prettyName()}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field>
                            </span>
                        </span>
                    </span>
                </div>

                <div class="form-row">
                    <div class="fill-row">
                        <address-entry-list [addressList]="[address]" [forSingle]="true" [disabled]="false"></address-entry-list>
                    </div>    
                </div>
                
                <div class="form-row">
                    <span class="fill-row">
                        <mat-form-field appearance="fill">
                            <mat-label>Location (ex: Activity Room)</mat-label>
                            <textarea matInput placeholder="Location (ex: Activity Room)" formControlName="location" 
                                [maxLength]="128" cdkTextareaAutosize cdkAutosizeMinRows="1">
                            </textarea>
                            <mat-hint align="end" *ngIf="eventGroup.controls['location'].value != null">
                                Characters: {{eventGroup.controls['location'].value.length}}/128
                            </mat-hint>
                        </mat-form-field>
                    </span>
                </div>

                <div class="form-row">
                    <span class="fill-row">
                        <mat-form-field appearance="fill">
                            <mat-label>Description (what you want to tell guests)</mat-label>
                            <textarea matInput placeholder="Description (what you want to tell guests)" formControlName="description" 
                                [maxLength]="4096" cdkTextareaAutosize cdkAutosizeMinRows="2">
                            </textarea>
                            <mat-hint align="end" *ngIf="eventGroup.controls['description'].value != null">
                                Characters: {{eventGroup.controls['description'].value.length}}/4096
                            </mat-hint>
                        </mat-form-field>
                    </span>
                </div>

                <div class="form-row">
                    <span class="fill-row">
                       <div class="flex-row flex-items">
                            <mat-form-field appearance="fill">
                                <mat-label>Budget ($)</mat-label>
                                <input matInput type="text" prefix="$ " mask="separator.2" thousandSeparator="," placeholder="Budget ($)" formControlName="budget" [maxLength]="16" >
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Actual Cost ($)</mat-label>
                                <input matInput type="text" prefix="$ " mask="separator.2" thousandSeparator="," placeholder="Budget ($)" formControlName="actualCost" [maxLength]="16" >
                            </mat-form-field>
                        </div>
                    </span>
                </div>
            </form>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <load-spinner inline *ngIf="submitting"></load-spinner>
        <button mat-stroked-button color="primary" (click)="closeWidget()">Cancel</button>
        <button mat-flat-button color="primary" (click)="saveEvent()" [disabled]="submitting || !eventGroup.valid">Submit</button>
    </mat-card-actions>
</mat-card>