<h1 mat-dialog-title>Communities</h1>
<mat-dialog-content>
  <!-- <mat-tree [dataSource]="companyTree"></mat-tree> -->
  <mat-selection-list #selectedCommunities>
    <mat-label>{{user()?.company?.companyName}}</mat-label>
    <mat-list-option *ngFor="let community of communities" [value]="community.communityId" [selected]="community.selected">
      {{community.communityName}}
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <load-spinner inline *ngIf="submitting"></load-spinner>
  <button mat-stroked-button color="primary" mat-dialog-close>Close</button>
  <button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="submitting || !selectedCommunities._value || selectedCommunities._value.length == 0">Submit</button>
</div>
