import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractControlOptions, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fieldsMatch } from '../../core/validators/field-match.validator';
import { Patterns } from '../../core/validators/patterns.validator';
import { ApiService } from 'src/app/core/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ValidationSnackBar } from 'src/app/core/components/validation-snack-bar/validation.snack-bar';
import { first } from 'rxjs';

@UntilDestroy()
@Component({
  templateUrl: "activateuser.component.html",
  styleUrls: ["userlogin.styles.scss"]
})
export class ActivateUserComponent {
    hide1:boolean = true;
    hide2:boolean = true;
    
    focus:"code"|"user"|"pwd" = "pwd";
    
    form:FormGroup;
    
    passwordMinLength = Patterns.minPasswordLength;
    passwordPattern = Patterns.passwordPattern;
    
    showPasswordErrors = false;
    submitting = false;

    codeCtrl:FormControl;
    userCtrl:FormControl;
    pwdCtrl:FormControl;
    pwdConfirmCtrl:FormControl;
    
    constructor(
        route:ActivatedRoute, 
        private router:Router,
        formBuilder:FormBuilder,
        private api:ApiService,
        private snackbar:MatSnackBar
        ) {

        this.codeCtrl = new FormControl("", [Validators.required]),
        this.userCtrl = new FormControl("", [Validators.required]),
        this.pwdCtrl = new FormControl("", Patterns.validatePasswordComponents),
        this.pwdConfirmCtrl = new FormControl("", [Validators.required]),
            
        this.form = formBuilder.group(
            {
                code:this.codeCtrl,
                username:this.userCtrl,
                password:this.pwdCtrl,
                passwordConfirm:this.pwdConfirmCtrl
            }, 
            {
                validators:[fieldsMatch("password", "passwordConfirm")]
            } as AbstractControlOptions
        );
        
        route.queryParams.pipe(untilDestroyed(this)).subscribe(async (p:any) => {
            if(p.code){
                this.form.controls["code"].setValue(p.code);
            }
            if(p.user){
                this.form.controls["username"].setValue(p.user);
            }
        });
        
        this.form.controls["password"].valueChanges.subscribe(v=>{
            if(v.length > 3){
                this.showPasswordErrors = true;
            }
        })
    }
    
    getConfirmPwdErrorMessage() {
      if (this.pwdConfirmCtrl.hasError('required')) {
        return 'You must enter a value';
      }

      return this.pwdConfirmCtrl.hasError('fieldsMismatch') ? 'Passwords do not match' : '';
    }
    
    onStrengthChanged(event: any) {
    }
    
    async submitPassword(){
        this.submitting = true;
        let obj = {
            userEmail:this.userCtrl.value,
            userPassword:this.pwdCtrl.value,
            activationCode:this.codeCtrl.value
        }
        try{
            //Set the csrf token before posting.
            await this.api.get("user/initActivation", {c:obj.activationCode,u:obj.userEmail});
        }catch(err){
            console.error(err);
        }
        let resp = await this.api.post("user/activate", obj);
        this.submitting = false;
        if(resp.message != "success"){
            let ref = this.snackbar.openFromComponent(ValidationSnackBar, {duration:7500});
            ref.instance.title = resp.message;
            ref.instance.validation = resp.errors.map((e:{user:String, message:String}) => e.message);
        } else {
            this.api.userInfo().pipe(first()).subscribe(u=>{
                this.router.navigate([""]);
            });
        }
    }
}
