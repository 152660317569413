import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

export interface DateRangeRequest{
    start:Date
    end:Date
}

@Injectable()
export class GraphApiService {
    constructor(private http: HttpClient, private api:ApiService) {
    }
  
    async getProspectCountForRange(companyId:string, requested:DateRangeRequest, comparison:DateRangeRequest, pendingOnly:boolean, communityIds:string[]){
        let cfg = {
            requested:{
                start:requested.start.getTime(),
                end:requested.end.getTime()
            },
            comparison:{
                start:comparison.start.getTime(),
                end:comparison.end.getTime()
            },
            ids:communityIds,
            filter:pendingOnly ? "pending" : ""
        }
        
        return this.api.post("/graph-data/prospect/count/"+companyId, cfg);
    }
  
    async getTopProspects(companyId:string, score:number, communityIds:string[]){        
        return this.api.post("/graph-data/top-prospect/count/"+companyId+"/"+score, {ids:communityIds});
    }
  
    async getTemperatureProspects(companyId:string, temperature:"hot"|"warm"|"cold"|"", communityIds:string[]){        
        return this.api.post("/graph-data/temperature-prospect/count/"+companyId+"/"+temperature, {ids:communityIds});
    }
  
    async getUncontactedProspects(companyId:string, communityIds:string[]){        
        return this.api.post("/graph-data/uncontacted-prospects/count/"+companyId, {ids:communityIds});
    }
  
    async getProspectsWithoutActivities(companyId:string, communityIds:string[]){        
        return this.api.post("/graph-data/no-activity-prospects/count/"+companyId, {ids:communityIds});
    }

    async getReferrerCountForRange(companyId:string, requested:DateRangeRequest, comparison:DateRangeRequest, communityIds:string[]){
        let cfg = {
            requested:{
                start:requested.start.getTime(),
                end:requested.end.getTime()
            },
            comparison:{
                start:comparison.start.getTime(),
                end:comparison.end.getTime()
            },
            ids:communityIds
        }
        
        return this.api.post("/graph-data/referrer/count/"+companyId, cfg);
    }

    async getUncontactedReferrers(companyId:string, communityIds:string[]){        
        return this.api.post("/graph-data/uncontacted-referrers/count/"+companyId, {ids:communityIds});
    }

    async getReferrersWithoutActivities(companyId:string, communityIds:string[]){        
        return this.api.post("/graph-data/no-activity-referrers/count/"+companyId, {ids:communityIds});
    }
}