import { DatePipe } from "@angular/common";
import { Component, Signal, computed } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EChartsOption } from "echarts";
import { Community } from "src/app/core/models/user.models";
import { OccupancyService } from "src/app/core/services/occupancy.service";
import { PersonService } from "src/app/core/services/person.service";
import { EChartTheme } from "src/app/graph.module";

@UntilDestroy()
@Component({
    selector: 'occupied-dash',
    templateUrl: './occupied.component.html',
    styleUrls: ['../graph-widget-style.scss']
  })
  export class OccupiedDashWidget {
    currentCommunity:Community|null = null;
    percent = computed(()=>{
      return this.occupancyService.occupancyPct();
    })
    count = computed(()=>{
      return this.occupancyService.occupancySummary().occupiedCount;
    })
    loading = computed(()=>{
      return this.occupancyService.loading()
    })
    
    tooltipText = computed(()=>{
      if(this.loading()){
        return "Occupancy count for selected community.";
      }else{
        return `${this.count()} units are occupied in ${this.currentCommunity?.nameDisplay}`;
      }
    });
    
    chartCfg:Signal<EChartsOption> = computed(()=>{
      let percent = this.occupancyService.occupancyPct();
      return {
        toolbox: {
          show: false
        },
        height:60,
        grid:{
          left: 6,
          right: 6,
          top:-1,
          bottom:0,
          width:26,
              
          containLabel:false
        },
        xAxis:[
          {
            type: 'category',
            data: ["count"]  
          }
        ],
        yAxis:[
          {
            type: 'value',
            min: 0,
            max: 100
          }
        ],
        series: [
          {
            name: 'Occupancy',
            type: 'bar',
            barWidth: 21,
            
            showBackground:true,
            // barMaxWidth:40,
            backgroundStyle:{
              opacity:.8,
              color: EChartTheme.cold
            },
            itemStyle: {
              color: EChartTheme.hot,
              barWidth: 17,
              
              // borderColor
            },
            // stack: 'count',
            // emphasis: {
            //   focus: 'series'
            // },
            data: [percent]
          },
          // {
          //   name: 'Total',
          //   type: 'bar',
          //   barWidth: 44,
          //   stack: 'count',
          //   // emphasis: {
          //   //   focus: 'series'
          //   // },
          //   data: [100 - summary.occupiedPct]
          // },
          // {
          //   type:'bar',
          //   barWidth: 45,
          //   colorBy: 'data',
          //   // barGap: '-45px',
          //   // radius: [15, 26],
          //   // center: ['50%', '50%'],
          //   // startAngle: 45,
          //   data: [
          //     { value: summary.occuppiedCount, name: 'Occupancy', label: {show:false}, selected:true, emphasis:{disabled:true}},
          //     { value: summary.unitCount, label: {show:false}, emphasis:{disabled:true} },
          //   ]
          // }
        ]
      };
    })
    
    constructor(
        personService:PersonService, 
        private occupancyService:OccupancyService){
          personService.currentCommunity.pipe(untilDestroyed(this)).subscribe(async community=>{
            this.currentCommunity = community;
          });
    }
  }