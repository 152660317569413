import { Component, EventEmitter, Input, OnInit, Output, ViewChild, WritableSignal, input, model, signal } from '@angular/core';
import { faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { InfiniteRequestData } from '../../directives/infinite-scroll-table.directive';
import { IPageable } from '../../models/user.models';


export interface SearchRequestData{
  search:string,
  page: IPageable|number|null
}
@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
    filter = model.required<SearchRequestData>();
    @Input() autoFocus?:boolean = false;
    @Input() debounce:number = 400;
    searchIcon = faSearch;
    
    @ViewChild('input', {static: false}) input ?: any;
    value:string = "";
    
    inputSub?:Subscription;
    
    // changeSearch(event:string){
    //   this.filter = event;
      
    //   this.filterChange.emit(this.filter);
    //   this.onSearch.emit(this.filter);
    // }
    ngOnInit(): void {
      this.value = this.filter().search;
    }
    ngAfterViewInit() {
      this.inputSub = this.input?.valueChanges
            .pipe(debounceTime(this.debounce))
            .pipe(distinctUntilChanged())
            .subscribe((model:string) => {
              // this.filter.set(model);
              if(this.filter().search != model){
                this.filter.update(d=>({...d, search:model, page: null} as SearchRequestData))
              }
            });

    }
    
    ngOnDestroy(): void {
      this.inputSub?.unsubscribe();
    }

    clearData(){
      this.value = '';
      // this.filter.set(this.value);
    }

    // setSearchData(value:any) {
    //   this.filter = value;
    //   this.filterChange.emit(this.filter);
    //   this.onSearch.emit(this.filter);
    // }
}