import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(private updates: SwUpdate, private snackbar: MatSnackBar) {
    this.checkForUpdates();
  }

  checkForUpdates() {
    if (this.updates.isEnabled) {
      // Listen for update events
      this.updates.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(evt => {
            //use a snackbar to show the user that a new version is available
            let ref = this.snackbar.open("A new version is available. Would you like to update?", "Yes", {duration: 30000, panelClass: "undo-snackbar" });
            let onaction = ref.onAction();
            onaction.subscribe(() => {
                //if the user clicks OK, reload the page to load the new version
                window.location.reload();
            });

        //   if (confirm('A new version is available. Would you like to update?')) {
        //     // Reload the page to load the new version
        //     window.location.reload();
        //   }
        });
    }
  }

  // Manually trigger update checks, e.g., on route navigation
  triggerUpdateCheck() {
    if (this.updates.isEnabled) {
      this.updates.checkForUpdate().then(() => {
        console.log('Service worker manually checked for updates.');
      });
    }
  }
}
