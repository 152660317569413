import { Component, HostBinding, signal, ViewChild, WritableSignal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs';
import { Person, Referrer, User } from 'src/app/core/models/user.models';
import { AppService } from 'src/app/core/services/app.service';
import { PersonService } from 'src/app/core/services/person.service';
import { UserActivityService } from 'src/app/core/services/user-activity.service';
import { UserActivitylistWidget } from 'src/app/core/widgets/stages/activity/user-activitylist.widget';

@UntilDestroy()
@Component({
  selector: 'app-placement-history',
  templateUrl: './placementhistoryview.component.html',
  styleUrls: ['../../app.component.scss'],
  host: {'class': 'twoColumnPage'}
})
export class PlacementHistoryViewComponent {
  title = 'MR-Vital-CRM';
  currentUser:User|null = null;

  @HostBinding('class.expand-main') expandMain:boolean = false;
  @HostBinding('class.expand-sub') expandSub:boolean = false;
  
  constructor(private appService:AppService){
    appService.selectWidget.pipe(untilDestroyed(this), debounceTime(100)).subscribe(widget =>{
      this.expandMain = false;
      this.expandSub = false;
      if(widget){
        let element = widget.widgetCard.nativeElement;
        let parent = element.closest('.mainContent');
        if(parent){
          this.expandMain = true;
        }else{
          parent = element.closest('.subContent');
          if(parent){
            this.expandSub = true;
          }
        }
      }
    })
  }
  
  // ngAfterViewInit(): void {
  //     this.appService.openWidget.set(this.collapseFirst?.collapseName);
  // }
}
