import { Component, computed, effect, input, signal, ViewChild, WritableSignal } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ExpandableWidget } from "../helpers/expandablewidget.component";
import { AbstractCommunity, Event, EventInvite, Influencer, OtherContact, Person, Prospect, Referrer, ReferrerCommunity, ReferrerStaff } from "../../models/user.models";
import { DataPosition, InfiniteRequestData } from "../../directives/infinite-scroll-table.directive";
import { ContactableConfig } from "../../components/contactable-select/contactable-select.component";
import { Page } from "../../models/spring.models";
import { ApiService } from "../../services/api.service";
import { AppService } from "../../services/app.service";
import { ActivatedRoute, Router } from "@angular/router";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CompanyCfgService } from "../../services/company-cfg.service";
import { IContactable } from "../../models/base.models";
import { EmailSendComponent } from "../../dialog/email-send/email-send.component";
import { MatDialog } from "@angular/material/dialog";
import { PersonService } from "../../services/person.service";

@UntilDestroy()
@Component({
  selector: 'event-invite',
  templateUrl: './event-invite.widget.html',
  styleUrls: ['./event-invite.widget.scss']
})
export class EventInviteWidget extends ExpandableWidget {
    sendIcon = faPaperPlane;
    filters:ContactableConfig[] = [];
    @ViewChild('tabSelection') private tabSelection!: any;
    canSend: boolean = true;

    constructor(
            protected override app:AppService,
            protected override route:ActivatedRoute, 
            protected override router:Router,
            private api:ApiService,
            private snackbar:MatSnackBar,
            private companyCfg:CompanyCfgService,
            private dialog:MatDialog,
            private personService:PersonService){
        super(app, route, router);

        this.filters = [
            this.prospectCfg
        ];

        // If this is a RCRM we need to add Staff to the filters at position 2.
        if(this.companyCfg != null && this.companyCfg != undefined && this.companyCfg.referrerCRM()) {
            this.filters.push(this.staffCfg);
        }

        this.filters.push(this.influencerCfg);
        this.filters.push(this.referrerCfg);
        this.filters.push(this.otherCfg);
    }

    event = input.required<Event>();
    
    alreadyContacted = signal<EventInvite[]>([]);
    selected = signal<IContactable[]>([]);
    selectionChange = (people:IContactable[]) => {
        this.selected.set(people);
    }

    clearSelections(){
        //loop through filters and reset them
        const updatedItems = [...this.filters];
        updatedItems.forEach((filter) => {
            filter.selected = [];
            filter.allSelected = false;
        });
        this.filters = updatedItems;
        this.selected.set([]);

         this.onProspectScroll(0, DataPosition.Clear);
         this.onInfluencerScroll(0, DataPosition.Clear);
         this.onReferrerScroll(0, DataPosition.Clear);
         this.onOtherScroll(0, DataPosition.Clear);
    }
    
    eventChange = effect(()=>{
        this.clearSelections();
        
         this.api.get(`events/canSend/${this.event().community?.communityId}`).then((result:any)=>{
            this.canSend = result?.message === "success";
         });
            this.api.get(`events/invited/${this.event().eventId}`).then((result:any[])=>{
                this.alreadyContacted.set(result.map(r=>EventInvite.fromJson(r) as EventInvite));
            });
    }, {allowSignalWrites:true});
    
    onProspectScroll = async (page:number, position:DataPosition):Promise<Page<Person>> => {
        let data = await this.api.getPage("prospect/marketable",
            Prospect.fromJson,
            null,
            page,
            this.prospectCfg.requestCfg.count,
            this.prospectCfg.requestCfg.search
        );
        let ids = await this.api.getPage("prospect/marketable",
            String,
            null,
            page,
            this.referrerCfg.requestCfg.count,
            this.referrerCfg.requestCfg.search,
            {idOnly: true}
        );
        var idList:string[] = [];
        if(ids != null && ids != undefined && ids.content != null && ids.content != undefined) {
            idList = ids.content;
        }
        
        this.setDataResults(position, data, this.prospectCfg, idList);

        if(this.tabSelection != null && this.tabSelection != undefined) {
            this.tabSelection.selectAllForTab("Prospects");
        }
        
        return data
    }

    onInfluencerScroll = async (page:number, position:DataPosition):Promise<Page<Person>> => {
        let data = await this.api.getPage("prospect/influencer/marketable",
            Influencer.fromJson,
            null,
            page,
            this.influencerCfg.requestCfg.count,
            this.influencerCfg.requestCfg.search
        );
        let ids = await this.api.getPage("prospect/influencer/marketable",
            String,
            null,
            page,
            this.referrerCfg.requestCfg.count,
            this.referrerCfg.requestCfg.search,
            {idOnly: true}
        );
        var idList:string[] = [];
        if(ids != null && ids != undefined && ids.content != null && ids.content != undefined) {
            idList = ids.content;
        }
        
        this.setDataResults(position, data, this.influencerCfg, idList);

        if(this.tabSelection != null && this.tabSelection != undefined) {
            this.tabSelection.selectAllForTab("Influencers");
        }
        
        return data
    }

    onReferrerScroll = async (page:number, position:DataPosition):Promise<Page<Person>> => {
        let data = await this.api.getPage("prospect/referrer/marketable",
            Referrer.fromJson,
            null,
            page,
            this.referrerCfg.requestCfg.count,
            this.referrerCfg.requestCfg.search
        );
        let ids = await this.api.getPage("prospect/referrer/marketable",
            String,
            null,
            page,
            this.referrerCfg.requestCfg.count,
            this.referrerCfg.requestCfg.search,
            {idOnly: true}
        );
        var idList:string[] = [];
        if(ids != null && ids != undefined && ids.content != null && ids.content != undefined) {
            idList = ids.content;
        }
        
        this.setDataResults(position, data, this.referrerCfg, idList);

        if(this.tabSelection != null && this.tabSelection != undefined) {
            this.tabSelection.selectAllForTab("Referrers");
        }
         
        return data
    }

    onOtherScroll = async (page:number, position:DataPosition):Promise<Page<Person>> => {
        let data = await this.api.getPage("prospect/other/marketable",
            OtherContact.fromJson,
            null,
            page,
            this.otherCfg.requestCfg.count,
            this.otherCfg.requestCfg.search
        );
        let ids = await this.api.getPage("prospect/other/marketable",
            String,
            null,
            page,
            this.referrerCfg.requestCfg.count,
            this.referrerCfg.requestCfg.search,
            {idOnly: true}
        );
        var idList:string[] = [];
        if(ids != null && ids != undefined && ids.content != null && ids.content != undefined) {
            idList = ids.content;
        }
        
        this.setDataResults(position, data, this.otherCfg, idList);

        if(this.tabSelection != null && this.tabSelection != undefined) {
            this.tabSelection.selectAllForTab("Others");
        }
        
        return data
    }

    onStaffScroll = async (page:number, position:DataPosition):Promise<Page<Person>> => {
        let data = await this.api.getPage("prospect/staff/marketable",
            ReferrerStaff.fromJson,
            null,
            page,
            this.staffCfg.requestCfg.count,
            this.staffCfg.requestCfg.search
        );
        let ids = await this.api.getPage("prospect/staff/marketable",
            String,
            null,
            page,
            this.referrerCfg.requestCfg.count,
            this.referrerCfg.requestCfg.search,
            {idOnly: true}
        );
        var idList:string[] = [];
        if(ids != null && ids != undefined && ids.content != null && ids.content != undefined) {
            idList = ids.content;
        }
        
        this.setDataResults(position, data, this.staffCfg, idList);

        if(this.tabSelection != null && this.tabSelection != undefined) {
            this.tabSelection.selectAllForTab("Staff");
        }
        
        return data
    }
    
    setDataResults(position:DataPosition, data: Page<IContactable>, cfg:ContactableConfig, ids:string[] = []){
        if (data){
            let bounces = data.content.filter(p => p.emailAddresses[0].hardBounce)
            
            if(data.totalElements){
                cfg.searchTotal = data.totalElements - bounces.length;
            }else{
                cfg.searchTotal = 0;
            }
        }
        /*if(data.totalElements){
            cfg.searchTotal = data.totalElements;
        }else{
            cfg.searchTotal = 0;
        }*/
        cfg.ids = ids;
        //loop through ids and subtract from searchTotal where alreadyContacted
        if(this.alreadyContacted() != null && this.alreadyContacted() != undefined) {
            this.alreadyContacted().forEach((contacted)=>{
                if(cfg.ids.includes(contacted.personId)){
                    if (cfg.searchTotal > 0)
                        cfg.searchTotal--;
                }
            });
        }
        switch(position){
        case DataPosition.Top:
            cfg.results = [...data.content, ...cfg.results];
            break;
        case DataPosition.Bottom:
            cfg.results = [...cfg.results, ...data.content];
            break;
        case DataPosition.Clear:
            cfg.results = data.content;
            break;
        }
    }
    
    prospectCfg:ContactableConfig = 
    {
        name: "Prospects", 
        searchTotal: 0,
        requestCfg:{count:20, page:null, search:"", ready:true}, 
        results:[],
        selected:[],
        dataLoad: this.onProspectScroll,
        ids: [],
        allSelected: false
    }

    influencerCfg:ContactableConfig =
    {
        name: "Influencers", 
        searchTotal: 0,
        requestCfg:{count:20, page:null, search:"", ready:true}, 
        results:[],
        selected:[],
        dataLoad: this.onInfluencerScroll,
        ids: [],
        allSelected: false
    }

    referrerCfg:ContactableConfig = 
    {
        name: "Referrers", 
        searchTotal: 0,
        requestCfg:{count:20, page:null, search:"", ready:true}, 
        results:[],
        selected:[],
        dataLoad: this.onReferrerScroll,
        ids: [],
        allSelected: false
    }

    otherCfg:ContactableConfig =
    {
        name: "Others", 
        searchTotal: 0,
        requestCfg:{count:20, page:null, search:"", ready:true}, 
        results:[],
        selected:[],
        dataLoad: this.onOtherScroll,
        ids: [],
        allSelected: false
    }

    staffCfg:ContactableConfig =
    {
        name: "Staff", 
        searchTotal: 0,
        requestCfg:{count:20, page:null, search:"", ready:true}, 
        results:[],
        selected:[],
        dataLoad: this.onStaffScroll,
        ids: [],
        allSelected: false
    }

    sendInvites(){
        if (!this.personService.hasSomePermission(['events:send-invite'])) {
            return;
          }    
        let people = this.selected().map(p=>p as Person);
        console.log(people);
        let d = this.dialog.open(EmailSendComponent,
            { data: {selectedPeople: people, itemType: "Event", eventMode: true, event: this.event()},
              disableClose: true, 
                   width:"calc(100vw - 40px)",
                   maxWidth:"900px"},
                   );
      
          // On dialog close, update the First/Last Contact columns by grabbing the data.
          d.afterClosed().subscribe(result=>{
              // Check if email successfully sent.
              if(result != null && result == "Success") {
                this.clearSelections();
                this.api.get(`events/invited/${this.event().eventId}`).then((result:any[])=>{
                    this.alreadyContacted.set(result.map(r=>EventInvite.fromJson(r) as EventInvite));
                });
              }
          });
    }
  
    comingSoon(){
      this.snackbar.open("Coming Soon!");
    }
}