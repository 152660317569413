import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { faMinus, faStar } from "@fortawesome/free-solid-svg-icons";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { lastValueFrom } from "rxjs";
import { PersonService } from "../../services/person.service";
import { HttpClient } from "@angular/common/http";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Address } from "../../models/base.models";
import { CompanyConfigMap } from "../../models/user.models";

@UntilDestroy()
@Component({ 
    selector: 'address-entry-list',
    templateUrl: './address-entry-list.component.html',
    styleUrls: ['./form-entry-list.component.scss']
})
export class AddressEntryList implements OnChanges {
    deleteIcon = faMinus;
    starIcon = faStar;
    @Input() addressList:Address[] = [];
    @Input() disabled:boolean = false;
    @Input() forSingle:boolean = false;
    addressGroup:FormGroup;
    data:Address[] = [];
    timeZoneList:String[] = ["Arizona", "Central", "Eastern", "Hawaii", "Mountain", "Pacific"];
    // @ViewChild("address1Trigger") address1Trigger !: MatMenuTrigger;
    // @ViewChild("address2Trigger") address2Trigger !: MatMenuTrigger;
    // @ViewChild("addressStateTrigger") addressStateTrigger !: MatMenuTrigger;
    // @ViewChild("addressCityTrigger") addressCityTrigger !: MatMenuTrigger;
    // @ViewChild("addressZipTrigger") addressZipTrigger !: MatMenuTrigger;
    @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;
    @Output() cityValueChanged = new EventEmitter<string>();
    
    smartyAuthId="081a7574-d87b-0f04-aaf9-26afcd42154a";
    smartyToken="Mlwk2JR5Jg8F72utE4SK";
    smartyKey="165782750436060095";
    companyCfg:CompanyConfigMap|null;
    
    smartySearch:any[] = [];
    
    constructor(private formBuilder:FormBuilder, 
        protected snackbar: MatSnackBar, 
        private personService:PersonService,
        private http:HttpClient){
        this.addressGroup = this.formBuilder.group({});
        this.companyCfg = null;
        this.personService.companyCfg.pipe(untilDestroyed(this)).subscribe(cfg=>{
          this.companyCfg = cfg;
        })
    }
    
    updateListData(){
        this.data = this.addressList.filter(v=>!v.deleted);
        this.addressGroup = this.buildAddressGroup(this.data);
        this.disableSingleForm(this.addressGroup, this.disabled);
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if(changes["disabled"]?.currentValue != null 
            && (changes["disabled"].firstChange 
                || changes["disabled"].currentValue != changes["disabled"].previousValue)){
            this.disableSingleForm(this.addressGroup, changes["disabled"].currentValue);
        }
        
        if(changes["addressList"].currentValue != null 
            && (changes["addressList"].firstChange 
                || changes["addressList"].currentValue != changes["addressList"].previousValue)){
            this.updateListData();
        }
    }
  
    activeIndex:number = 0;
    selectAddress(event:MatAutocompleteSelectedEvent){
        let index = this.activeIndex;
        let address:any = event.option.value;
        
        this.addressGroup.controls["address_1"+index].setValue(address.street_line);
        this.data[index].address_1 = address.street_line;
        
        this.addressGroup.controls["address_2"+index].setValue(address.secondary);
        this.data[index].address_2 = address.secondary;
        
        this.addressGroup.controls["address_city"+index].setValue(address.city);
        this.data[index].city = address.city;
        
        this.addressGroup.controls["address_state"+index].setValue(address.state);
        this.data[index].state = address.state;
        
        this.addressGroup.controls["address_zip"+index].setValue(address.zipcode);
        this.data[index].zip = address.zipcode;
        
        this.smartySearch = [];

        if(address.entries > 1){
            this.addressSearchCall(address.street_line + " " + address.secondary + " (" + address.entries + ") " + address.city + " " + address.state + " " + address.zipcode);
        }
        
        // this.address1Trigger.closeMenu();
    }

    showPanel = () : void => {
        this.autocomplete.openPanel();
    }
  
    addAddress(){
        this.addressList.push({ address_1:"", address_2:"", city:"", zip:"", state:"", is_primary:false, timezone: "", deleted:false } as Address);
        this.updateListData();
    }
    
    async changeAddress1(index:number){
        this.data[index].address_1 = this.addressGroup.controls["address_1"+index].value;
        this.addressSearch(index);
    }
    
    changeAddress2(index:number){
        this.data[index].address_2 = this.addressGroup.controls["address_2"+index].value;
    }
    
    changeAddressCity(index:number){
        this.data[index].city = this.addressGroup.controls["address_city"+index].value;
        this.cityValueChanged.emit(this.data[index].city); 
    }
    
    changeAddressState(index:number){
        this.data[index].state = this.addressGroup.controls["address_state"+index].value;
    }

    changeTimeZone(index:number){
        this.data[index].timezone = this.addressGroup.controls["address_timezone"+index].value;
    }
    
    async changeAddressZip(index:number){
        let zip = this.addressGroup.controls["address_zip"+index].value;
        this.data[index].zip = zip;
    }
    
    debounce:any;
    addressSearch(index:number){
        this.activeIndex = index;
        clearTimeout(this.debounce);
        this.debounce = setTimeout(()=>this.addressSearchCall(""), 500);
    }
    
    async addressSearchCall(secondary:string){
        let index = this.activeIndex;
        let street1 = this.addressGroup.controls["address_1"+index].value;
        let city = this.addressGroup.controls["address_city"+index].value;
        let state = this.addressGroup.controls["address_state"+index].value;
        let zip = this.addressGroup.controls["address_zip"+index].value;
        
        street1 = street1 != null ? street1.trim() : "";
        city = city != null ? city.trim() : "";
        state = state != null ? state.trim() : "";
        zip = zip != null ? zip.trim() : "";
        
        if(this.companyCfg?.use_smarty_address.value == 'Y' && street1.length > 3){
            let params:any = {
                "key" : this.smartyKey,
                "license" : 'us-autocomplete-pro-cloud',
                "search" : street1.trim(),
            }
            
            if(secondary != ""){
                params.selected = secondary;
            }else{
                if(zip.length >= 5){
                    params.prefer_zip_codes = zip;
                }else{
                    if(state.length ==2){
                        params.include_only_states = state;
                    }
                    if(city.length > 0){
                        params.prefer_cities = city;
                    }
                }
            }
            
            let resp:any = await lastValueFrom(this.http.get('https://us-autocomplete-pro.api.smartystreets.com/lookup', { params:params }));
            if(resp.suggestions){
                console.log(resp);
                this.smartySearch = resp.suggestions;
                if(this.smartySearch.length > 0){
                    this.autocomplete.openPanel();
                }
            }
        }
    }
    buildAddressDisplay(suggestion:any) {
        let whiteSpace = "";
        let secondary = ""
        if (suggestion.secondary) {
            if (suggestion.entries > 1) {
                secondary += " (" + suggestion.entries + " entries)";
            }else{
                secondary = suggestion.secondary;
            }
            whiteSpace = " ";
        }
        return suggestion.street_line + whiteSpace + secondary + " " + suggestion.city + ", " + suggestion.state + " " + suggestion.zipcode;
    }
    // let street1 = this.addressGroup.controls["address_1"+index].value;
    // if(this.companyCfg?.use_smarty_address.value == 'Y' && street1 != null && street1.trim().length > 3){
    //     let resp:any = await lastValueFrom(this.http.get('https://us-autocomplete-pro.api.smartystreets.com/lookup', {
    //         params: { 
    //             "key" : this.smartyKey, 
    //             "license" : 'us-autocomplete-pro-cloud',
    //             "search" : street1.trim()
    //         }
    //     }));
    //     if(resp.suggestions){
    //         this.smartySearch = resp.suggestions;
    //     }
    // }
    // if(this.companyCfg?.use_smarty_address.value == 'Y' && zip.length >= 5){
    //     let resp = await lastValueFrom(this.http.get('https://us-zipcode.api.smartystreets.com/lookup', {
    //         params: { 
    //             "auth-id" : this.smartyAuthId, 
    //             "auth-token" : this.smartyToken,
    //             "license" : 'us-autocomplete-pro-cloud',
    //             "zipcode" : zip 
    //         }
    //     }));
    //     console.log(resp);
    // }
  
    buildAddressGroup(addresss:Address[]){
        let addressGroup:any = {};
        addresss.forEach((address, index)=>{
            if (this.forSingle) {
                addressGroup['address_1'+index] = [
                    address.address_1, [Validators.required, Validators.minLength(1), Validators.maxLength(255)]
                ]
                addressGroup['address_2'+index] = [
                    address.address_2, [Validators.minLength(1), Validators.maxLength(255)]
                ]
                addressGroup['address_city'+index] = [
                    address.city, [Validators.required, Validators.minLength(1), Validators.maxLength(255)]
                ]
                addressGroup['address_state'+index] = [
                    address.state, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]
                ]
                addressGroup['address_zip'+index] = [
                    address.zip, [Validators.required, Validators.minLength(5), Validators.maxLength(10)]
                ]
                addressGroup['address_timezone'+index] = [
                    address.timezone, [Validators.required, Validators.minLength(1), Validators.maxLength(255)]
                ]
            } else {
                addressGroup['address_1'+index] = [
                    address.address_1, [Validators.minLength(1), Validators.maxLength(255)]
                ]
                addressGroup['address_2'+index] = [
                    address.address_2, [Validators.minLength(1), Validators.maxLength(255)]
                ]
                addressGroup['address_city'+index] = [
                    address.city, [Validators.minLength(1), Validators.maxLength(255)]
                ]
                addressGroup['address_state'+index] = [
                    address.state, [Validators.minLength(2), Validators.maxLength(2)]
                ]
                addressGroup['address_zip'+index] = [
                    address.zip, [Validators.minLength(5), Validators.maxLength(10)]
                ]
                addressGroup['address_timezone'+index] = [
                    address.timezone, [Validators.minLength(1), Validators.maxLength(255)]
                ]
            }
        });
        
        return this.formBuilder.group(addressGroup);
    }
  
    removeItem(item:any){
        let index = this.addressList.indexOf(item);
        this.addressList[index].deleted = true;
        this.updateListData();

        let ref = this.snackbar.open("Address removed.", "Undo", { duration: 4000, panelClass: "undo-snackbar" });
        ref.onAction().subscribe(() => {
            let index = this.addressList.indexOf(item);
            this.addressList[index].deleted = false;
            this.updateListData();
            ref.dismiss();
        });
    }
    
    changePrimary(item:Address){
        this.addressList.forEach(i=>i.is_primary = false);
        item.is_primary = true;
    }
    
    disableSingleForm(group:FormGroup|null|undefined, disableState:boolean){
      if(group){
        for (var control in group.controls) {
          if(disableState){
            group.controls[control].disable();
          }else{
            group.controls[control].enable();
          }
        }
      }
    }

}