<mat-tab-group>
    @for(filter of filters(); track $index){
        <mat-tab [label]="((referrerCRM() && filter.name == 'Referrers') ? 'Contacts' : filter.name)">
            <mat-card appearance="outlined">
                <mat-card-content>
                    <span class="flex-row search-row">
                        <search-input class="mr-auto" [(filter)]="filter.requestCfg" [matTooltip]="'Search for the name of the Contact.'"></search-input>
                        <button class="color-icon-button" mat-stroked-button (click)="toggleSelect(filter)"><fa-icon [icon]="checkIconDisplay(filter, selectionMap(), contactedMap())"></fa-icon> Select All ({{filter.searchTotal}})</button>
                    </span>
                </mat-card-content>
                <mat-card-content>
                    <mat-list role="list" infiniteScroll [onScroll]="filter.dataLoad" [filter]="filter.requestCfg">
                        <load-spinner variant="table"></load-spinner>
                        @if(filter.results.length <= 0){
                            <mat-list-item role="listitem" class="emptyItem">
                                <span *ngIf="filter.name == 'Communities'">No communities found with valid email addresses.</span>
                                <span *ngIf="filter.name != 'Communities'">No people found with valid email addresses.</span>
                            </mat-list-item>
                        }
                        @for(contactable of filter.results; track contactable.id){
                            <mat-list-item [disabled]="(this.contactedMap()[contactable.id] && this.contactedMap()[contactable.id].sent) || contactable.emailAddresses[0].hardBounce" [matRippleDisabled]="(this.contactedMap()[contactable.id] && this.contactedMap()[contactable.id].sent) || contactable.emailAddresses[0].hardBounce" role="list" matRipple (click)="selectPerson(contactable, filter)">
                                @if (this.contactedMap()[contactable.id]) {
                                    <span class="sent-icon" matTooltip="Sent">
                                        <fa-icon [icon]="emailedIcon"></fa-icon>
                                    </span>
                                }
                                @else if (contactable.emailAddresses[0].hardBounce){
                                    <span class="sent-icon" matTooltip="Bounced">
                                        <fa-icon [icon]="bounceIcon"></fa-icon>
                                    </span>
                                }
                                @else {
                                    <span>
                                        <mat-checkbox [checked]="selectionMap()[contactable.id] || filter.allSelected"></mat-checkbox>
                                    </span>
                                }
                                <span class="multiLine">
                                    <mat-label class="info">
                                        {{contactable.nameDisplay}}
                                    </mat-label>
                                    <div class="subText" *ngIf="contactable.emailAddresses[0]">
                                        {{contactable.emailAddresses[0].address}}
                                    </div>
                                </span>
                                @if (this.contactedMap()[contactable.id] && this.contactedMap()[contactable.id].delivered) {
                                    <span class="sent-icon" matTooltip="Delivered">
                                        <fa-icon [icon]="deliverIcon"></fa-icon>
                                    </span>
                                }
                                @if (this.contactedMap()[contactable.id] && this.contactedMap()[contactable.id].opened) {
                                    <span class="sent-icon" matTooltip="Opened">
                                        <fa-icon [icon]="openIcon"></fa-icon>
                                    </span>
                                }
                                @if (this.contactedMap()[contactable.id] && this.contactedMap()[contactable.id].clicked) {
                                    <span class="sent-icon" matTooltip="Clicked">
                                        <fa-icon [icon]="clickIcon"></fa-icon>
                                    </span>
                                }
                                @if (this.contactedMap()[contactable.id] && this.contactedMap()[contactable.id].bounced) {
                                    <span class="sent-icon" matTooltip="Bounced">
                                        <fa-icon [icon]="bounceIcon"></fa-icon>
                                    </span>
                                }
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        }
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </mat-tab>
    }
</mat-tab-group>