import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { faMinus, faStar } from "@fortawesome/free-solid-svg-icons";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Phone } from "../../models/base.models";

@Component({ 
    selector: 'phone-entry-list',
    templateUrl: './phone-entry-list.component.html',
    styleUrls: ['./form-entry-list.component.scss']
})
export class PhoneEntryList implements OnChanges {
    deleteIcon = faMinus;
    starIcon = faStar;
    @Input() phoneList:Phone[] = [];
    @Input() disabled:boolean = false; 
    @Input() hideHome:boolean = false;
    @Input() hideAllow:boolean = false;
    @Input() assessmentBlock:boolean = false;
    @Output() onChange:EventEmitter<any[]> = new EventEmitter<any[]>();
    
    phoneGroup:FormGroup;
    data:Phone[] = [];
    
    constructor(private formBuilder:FormBuilder, protected snackbar: MatSnackBar){
        this.phoneGroup = this.formBuilder.group({});
    }
    
    updateListData(){
        // Added this so submitting the assessments won't throw an error every time the obj gets turned into a JSON string.
        if(typeof this.phoneList === 'string'){
            return;
        }

        this.data = this.phoneList.filter(v=>!v.deleted);
        this.phoneGroup = this.buildPhoneGroup(this.data);
        this.disableSingleForm(this.phoneGroup, this.disabled);

        // KE: 08/26/2024: Bug 48019: Made changes so phone types properly disable and enable the different marketing and sales options based on what makes sense logically.
        // This code will handle the initial setting up of data.
        // If this is a fax unset all marketing and sales contact options and disable them. Nothing is going to be sent to a fax number through our system at this time.
        if(this.data != null && this.data != undefined) {
            this.data.forEach((phone, index) => {
                if(this.phoneGroup.controls["phone_type"+index].value != null && this.phoneGroup.controls["phone_type"+index].value != undefined && this.phoneGroup.controls["phone_type"+index].value == "F") {
                    this.phoneGroup.controls["allowMarketing"+index].disable();
                    this.phoneGroup.controls["allowSalesContact"+index].disable();
                    this.phoneGroup.controls["allowSalesContactCalls"+index].disable();
                } else if(this.phoneGroup.controls["phone_type"+index].value != null && this.phoneGroup.controls["phone_type"+index].value != undefined && (this.phoneGroup.controls["phone_type"+index].value == "H" || this.phoneGroup.controls["phone_type"+index].value == "O")) {
                    // Home and Office phones cannot text, so unset and disable that option.
                    this.phoneGroup.controls["allowSalesContact"+index].disable();
                }
            });
        }
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if(changes["disabled"]?.currentValue != null 
            && (changes["disabled"].firstChange 
                || changes["disabled"].currentValue != changes["disabled"].previousValue)){
            this.disableSingleForm(this.phoneGroup, changes["disabled"].currentValue);
        }
        if(changes["phoneList"].currentValue != null 
            && (changes["phoneList"].firstChange 
                || changes["phoneList"].currentValue != changes["phoneList"].previousValue)){
            this.updateListData();
        }
    }
  
    changePhone(index:number){
        this.data[index].number = this.phoneGroup.controls["phone"+index].value;
        this.onChange.emit(this.data);
    }
    
    changePhoneExt(index:number){
        this.data[index].extension = this.phoneGroup.controls["phone_ext"+index].value;
        this.onChange.emit(this.data);
    }

    changeAllowMarketing(index:number){
        this.data[index].allowMarketing = this.phoneGroup.controls["allowMarketing"+index].value;
        this.onChange.emit(this.data);
    }

    changeAllowSalesContact(index:number){
        this.data[index].allowSalesContact = this.phoneGroup.controls["allowSalesContact"+index].value;
        this.onChange.emit(this.data);
    }

    changeAllowSalesContactCalls(index:number){
        this.data[index].allowSalesContactCalls = this.phoneGroup.controls["allowSalesContactCalls"+index].value;
        this.onChange.emit(this.data);
    }
    
    changePhoneType(index:number){
        this.data[index].contact_type = this.phoneGroup.controls["phone_type"+index].value;

        // KE: 08/26/2024: Bug 48019: Made changes so phone types properly disable and enable the different marketing and sales options based on what makes sense logically.
        // If this is a fax unset all marketing and sales contact options and disable them. Nothing is going to be sent to a fax number through our system at this time.
        if(this.phoneGroup.controls["phone_type"+index].value != null && this.phoneGroup.controls["phone_type"+index].value != undefined && this.phoneGroup.controls["phone_type"+index].value == "F") {
            this.data[index].allowMarketing = false;
            this.data[index].allowSalesContact = false;
            this.data[index].allowSalesContactCalls = false;

            this.phoneGroup.controls["allowMarketing"+index].setValue(false);
            this.phoneGroup.controls["allowSalesContact"+index].setValue(false);
            this.phoneGroup.controls["allowSalesContactCalls"+index].setValue(false);
            
            this.phoneGroup.controls["allowMarketing"+index].disable();
            this.phoneGroup.controls["allowSalesContact"+index].disable();
            this.phoneGroup.controls["allowSalesContactCalls"+index].disable();
        } else if(this.phoneGroup.controls["phone_type"+index].value != null && this.phoneGroup.controls["phone_type"+index].value != undefined && (this.phoneGroup.controls["phone_type"+index].value == "H" || this.phoneGroup.controls["phone_type"+index].value == "O")) {
            // Home and Office phones cannot text, so unset and disable that option.
            this.data[index].allowSalesContact = false;
            this.phoneGroup.controls["allowSalesContact"+index].setValue(false);
            this.phoneGroup.controls["allowSalesContact"+index].disable();

            // Make sure to enable the marketing and phone calls options.
            this.phoneGroup.controls["allowMarketing"+index].enable();
            this.phoneGroup.controls["allowSalesContactCalls"+index].enable();
        } else {
            // Enable all options if this is a mobile phone or some other type.
            this.phoneGroup.controls["allowMarketing"+index].enable();
            this.phoneGroup.controls["allowSalesContact"+index].enable();
            this.phoneGroup.controls["allowSalesContactCalls"+index].enable();
        }

        this.onChange.emit(this.data);
    }
  
    addPhone(){
        this.phoneList.push({ number:"", allowMarketing:true, allowSalesContact: true, allowSalesContactCalls: true, is_primary:false, contact_type:"M", deleted:false } as Phone);
        this.updateListData();

        this.onChange.emit(this.data);
    }
  
    buildPhoneGroup(phones:Phone[]){
        let phoneGroup:any = {};
        phones.forEach((phone, index)=>{
            phoneGroup['phone'+index] = [
                phone.number, [Validators.required, Validators.pattern('[0-9]{10}')]
            ]
            phoneGroup['phone_ext'+index] = [
                phone.extension, [Validators.maxLength(30)]
            ]
            phoneGroup['phone_type'+index] = [
                phone.contact_type, [Validators.required, Validators.maxLength(1)]
            ]
            phoneGroup['allowMarketing'+index] = [
                phone.allowMarketing, []
            ]
            phoneGroup['allowSalesContact'+index] = [
                phone.allowSalesContact, []
            ]
            phoneGroup['allowSalesContactCalls'+index] = [
                phone.allowSalesContactCalls, []
            ]
        });
        
        return this.formBuilder.group(phoneGroup);
    }
  
    removeItem(item:any){
        let index = this.phoneList.indexOf(item);
        this.phoneList[index].deleted = true;
        this.updateListData();

        let ref = this.snackbar.open("Phone removed.", "Undo", { duration: 4000, panelClass: "undo-snackbar" });
        ref.onAction().subscribe(() => {
            let index = this.phoneList.indexOf(item);
            this.phoneList[index].deleted = false;
            this.updateListData();
            ref.dismiss();
            this.onChange.emit(this.data);
        });

        this.onChange.emit(this.data);
    }
    
    changePrimary(item:Phone){
        this.phoneList.forEach(i=>i.is_primary = false);
        item.is_primary = true;
        this.onChange.emit(this.data);
    }
    
    disableSingleForm(group:FormGroup|null|undefined, disableState:boolean){
      if(group){
        for (var control in group.controls) {
          if(disableState){
            group.controls[control].disable();
          }else{
            group.controls[control].enable();
          }
        }
      }
    }

    validPhones(){
        return this.phoneGroup.valid;
    }
}