import { Component, Inject } from '@angular/core';
import { ChatComponent } from '../../widgets/communication/chat/chat.component';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { EmailDialogData, EmailReadComponent } from '../email-read/email-read.component';
import { ContactService } from '../../services/communication.service';
import { ScheduledActivity } from '../../models/user.models';

@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss'],
})
export class ChatDialogComponent {
  constructor(
    private communication:ContactService ,
    private http: HttpClient, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<EmailReadComponent>,
    private dialog: MatDialog) { 
      console.log(data);
      this.communication.selectedMobile.next({contact:data.contact, phone:data.phone, itemType: data.itemType})
    }
    
  wasSent:boolean = false;
  messageSent = (status:boolean) => {
    this.wasSent = status;
  }
  
  onSubmit = () =>{
    this.dialogRef.close(this.wasSent);
  }
}
