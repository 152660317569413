import { AfterContentInit, Component, OnInit } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { GoogleApiService } from '../../core/services/google-api.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'oauth',
  templateUrl: './oauth.component.html',
  styleUrls: []
})
export class OauthComponent implements AfterContentInit {
  
  constructor(private api:ApiService, private googleService: GoogleApiService, private route: ActivatedRoute, private readonly http: HttpClient){
    console.log("Oauth");
    
  }

  async ngAfterContentInit(): Promise<void> {
    let oauthService = localStorage.getItem('oauthService');
    console.log(oauthService);
    this.route.queryParams.subscribe(params => {
      if (params['code']){
        if (this.googleService.googleWindow) {
          if (this.googleService.googleWindow) {
            this.http.post(environment.API_URL+"/communication/auth/"+oauthService+"/code",{code: params['code']}).subscribe(function(resp){
              console.log(resp);
              //window.location.href = "/prospect";
                //googleService.googleWindow.window.close();
                window.opener.my.namespace.publicFunc();
            });
          }
        }
      }
    })
  }
  
  
}
