import { E } from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { CloseReason } from '../../models/lookuptable.models';
import { Community, Prospect, Unit } from '../../models/user.models';
import { PersonService } from '../../services/person.service';

export interface CloseReasonDialogCfg{
    prospect:Prospect
    units:Unit[]
    closeReasons:CloseReason[]
    defaultToPreadmit:boolean
}

@Component({
    selector: 'close-prospect-dialog-component',
    templateUrl: 'close-prospect-dialog.component.html',
    styleUrls: ['close-prospect-dialog.component.scss']
})
export class CloseProspectDialogComponent {
    public selectedReason:any = "";
    public closeReasons:CloseReason[] = [];

    public prospect!:Prospect;//this is the id of the prospect
    public close_reason_id:string = "";
    public close_reason:string = "";
    public close_date:number = 0;
    public close_comments:string = "";

    public formisvalid:boolean = false;

    allCommunities:Community[] = [];
    selectedCommunity:Community[] = [];
    allUnits:Unit[] = [];
    communityReserved:Unit[] = [];
    selectedUnit:any = null;
    
    preadmit:boolean = true;
    compareUnitFunction = (o1: any, o2: any)=> o1.unitId===o2.unitId;

    textAreaMaxLength = 256;
    constructor(public dialogRef: MatDialogRef<CloseProspectDialogComponent>, private api:ApiService, @Inject(MAT_DIALOG_DATA) public data: CloseReasonDialogCfg) {
        this.closeReasons = data.closeReasons;
        this.allUnits = data.units;

        if(data.closeReasons != null && data.closeReasons != undefined && data.closeReasons.length > 0) {
            this.selectedReason = data.closeReasons[0];
        }

        // Bug 47434: Don't plan to preadmit through the Close Prospect action anymore.
        // if(data.defaultToPreadmit){
        //     let firstPreadmitReason = data.closeReasons.filter((d:CloseReason)=>d.preadmit)[0];
        //     if(firstPreadmitReason){
        //         this.selectedReason = firstPreadmitReason;
        //     }
        // }

        this.prospect = data.prospect;
    }

    onCancel(): void {
        this.dialogRef.close("cancel");
    }

    async onSubmit() {
        var saveObject:any = {};
        saveObject.personId = this.prospect.personId;
        saveObject.close_reason_id = this.selectedReason.guid
        saveObject.close_reason = this.selectedReason.name;
        saveObject.communityId = null;

        // Bug 47434: Don't plan to preadmit through the Close Prospect action anymore.
        // if(this.preadmit && this.selectedReason.preadmit){
        //     saveObject.preadmitCommunityId = this.selectedCommunity[0].communityId;
        //     if(this.selectedUnit && this.selectedUnit.length > 0){
        //         saveObject.reservedUnitId = this.selectedUnit[0].unitId;
        //     }else{
        //         saveObject.reservedUnitId = null;
        //     }
        // }
        
        var now = Date.now()
        saveObject.close_date = now;
        saveObject.close_comments = this.close_comments;
        
        
        //await this.api.closeProspect(saveObject); //ZH, 06/10,24, bug 47741: now make this call after confirmatioon in the "Are you sure?" dialog
        this.dialogRef.close(saveObject); //ZH, 06/10,24, bug 47741: now return saveObject.  This allows a second, "Are you sure?" dialog to appear after this one is closed.
    }

    // Bug 47434: Don't plan to preadmit through the Close Prospect action anymore.
    // selectCommunity(event:Community[]){
    //     let last = event.slice(-1);
    //     this.selectedCommunity = last;
       
    //     if(this.selectedCommunity.length > 0){
    //         this.communityReserved = this.allUnits.filter(u=>u.communityId == this.selectedCommunity[0].communityId)
    //         this.selectedUnit = [this.communityReserved[0]];
    //     }else{
    //         this.communityReserved = [];
    //         this.selectedUnit = null;
    //     }
    // }
    
    isValid() {
        let valid = true;
        if(this.selectedReason.notesRequired && this.close_comments.trim().length <= 0){
            valid = false;
        }
        
        // Bug 47434: Don't plan to preadmit through the Close Prospect action anymore.
        // if(this.selectedReason.preadmit && this.preadmit && (this.selectedCommunity.length <= 0 || this.selectedUnit == null || this.selectedUnit.length <= 0)){
        //     valid = false;
        // }
        
        return valid;
    }
}