<style>
    .formBody{
        display:grid;
        grid-template-columns: 800px 1fr;
        gap:20px
    }
    form{
        font-size: 16px;
        font-weight: 400;
        color:rgb(47, 47, 47);
        display:flex;
        flex-direction: column;
        gap: 20px;
        
        padding: 20px 80px;
    }
    h4{
        font-size:20px;
        border-bottom: 1px solid rgb(47, 47, 47);
        padding-bottom:4px;
        margin:30px 10px 10px 10px;
    }
    .grid-row{
        display:grid;
        grid-template-columns: auto 1fr;
        gap: 40px;
    }
    .grid-row.no-label{
        grid-template-columns: 1fr 1fr;
    }
    .grid-row .full-row{
        grid-column: 1 / -1;
    }
    .grid-row label{
        font-weight: 500;
        align-self: center;
    }
    .grid-row span {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 20px;
    }
    .grid-row span div {
        display: inline-flex;
        gap: 6px;
    }
    .grid-row span div input {
        margin:0px;
    }
    .grid-row span div label[for] {
        font-weight: 400;
    }
    
    input[type="text"], input[type="tel"], select, textarea{
        font-size: 16px;
        padding: 4px;
    }
    textarea{
        min-height: 100px;
        resize: vertical;
    }
    button{
        margin-left: auto;
        max-width: 160px;
    }
</style>
<div class="formBody">
    <form #f="ngForm" (ngSubmit)="submit(f)">
        <div class="grid-row">
            <label>Which locations are you interested in?</label>
            <span>
                <!-- <div *ngFor="let community of communities; let index = index">
                    <input type="radio" id="{{index}}-communityId" name="communityId" value="{{community.communityId}}" ngModel/>
                    <label for="{{index}}-communityId">{{community.communityName}}</label>
                </div> -->
                <div *ngFor="let community of communities; let index = index" >
                    <input type="checkbox" id="{{index}}-communityId" name="communityIds" [value]="community.communityId" (change)="onCommunityChange($event)"/>
                    <label for="{{index}}-communityId">{{community.communityName}}</label>
                </div>
            </span>
        </div>
        <div class="grid-row">
            <label for="relationId">Your relationship to Prospective Inquiree:</label>
            <span>
                <select id="relationId" name="relationId" ngModel>
                    <option value="" >Myself</option>
                    <option value="{{relation.guid}}" *ngFor="let relation of relations; let index = index">{{relation.name}}</option>
                </select>
            </span>
        </div>
        <div class="grid-row">
            <label>Reason for Inquiry:</label>
            <span>
                <div>
                    <input type="checkbox" id="reasonGeneral" name="reasonGeneral" ngModel/>
                    <label for="reasonGeneral">General Question</label>
                </div>
                <div>
                    <input type="checkbox" id="reasonTour" name="reasonTour" ngModel/>
                    <label for="reasonTour">Schedule Tour</label>
                </div>
            </span>
        </div>
        <h4>
            Contact Information
        </h4>
        <div class="grid-row no-label">
            <input type="text" id="firstname" name="firstname" placeholder="First Name" ngModel/>
            <input type="text" id="lastname" name="lastname" placeholder="Last Name" ngModel/>
        </div>
        <div class="grid-row no-label">
            <input type="text" id="email" name="email" placeholder="Email" ngModel/>
        </div>
        <div class="grid-row no-label">
            <input type="tel" id="phone" name="phone" mask="(000) 000-0000" placeholder="Phone Number" ngModel/>
        </div>
        <div class="grid-row no-label">
            <span class="full-row">
                <div>
                    <input type="checkbox" id="sms" name="sms" ngModel/>
                    <label for="sms">By checking this box, I agree to receive texts from Crossroads Northglenn regarding my application. Message and data rates may apply. Reply STOP at anytime to end messages.</label>
                </div>
            </span>
        </div>
        <div class="grid-row no-label">
            <input class="full-row" type="text" id="address1" name="address1" placeholder="Street Address" ngModel/>
        </div>
        <div class="grid-row no-label">
            <input class="full-row" type="text" id="address2" name="address2" placeholder="Address Line 2" ngModel/>
        </div>
        <div class="grid-row no-label">
            <input type="text" id="addressCity" name="addressCity" placeholder="City" ngModel/>
            <input type="text" id="addressState" name="addressState" placeholder="State" ngModel/>
        </div>
        <div class="grid-row no-label">
            <input type="text" id="addressZip" name="addressZip" placeholder="Zip Code" ngModel/>
        </div>
        <div class="grid-row no-label">
            <textarea class="full-row" placeholder="Your Message" name="notes" ngModel></textarea>
        </div>
        <button>Submit Form</button>
    </form>
    <div>
        {{objData}}
    </div>
</div>
