import { Component, Inject, OnInit, ViewChild, WritableSignal, effect, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Person, Prospect, Referrer, ReferrerStaff } from '../../models/user.models';
import { Subject, debounceTime } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataMapper } from '../../models/datamapper';
import { DataPosition, InfiniteRequestData } from '../../directives/infinite-scroll-table.directive';
import { SearchRequestData } from '../../components/search-input/search-input.component';
import { CompanyCfgService } from '../../services/company-cfg.service';
import { Page } from '../../models/spring.models';
import { AppService } from '../../services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonService } from '../../services/person.service';
import { MatSelectionList } from '@angular/material/list';

export interface ReferrerStaffSearchDialogData{
    communityId:string;
    title:string;
}

interface ReferrerStaffSearchRequest extends InfiniteRequestData{
    communityId?:string;
}

@UntilDestroy()
@Component({
    selector: 'referrerstaff-search-dialog',
    templateUrl: 'referrerstaff-search.dialog.html',
    styleUrls: ['referrerstaff-search.dialog.scss']
})
export class ReferrerStaffSearchDialog {
    allPeople:Person[] = [];
    searchData = signal<ReferrerStaffSearchRequest>({page:null, count:20, search:"", ready: false});
    
    title!:string;
    @ViewChild("selectedPerson") selectedPerson!:MatSelectionList;
    constructor(
        protected app: AppService,
        protected route: ActivatedRoute,
        protected router: Router,
        private personService: PersonService,
        private api: ApiService,
        protected snackbar: MatSnackBar,
        protected dialog: MatDialog,
        public dialogRef: MatDialogRef<ReferrerStaffSearchDialogData>, 
        private companyCfg:CompanyCfgService, 
        @Inject(MAT_DIALOG_DATA) public data: ReferrerStaffSearchDialogData,
    ) {
        this.title = data.title;
        this.searchData.update(d=>({...d, communityId: data.communityId, ready:true}))
    }
  
    personOnScroll = async (page:number, position:DataPosition):Promise<Page<Person>> => {
      let foundData = await this.personComData(this.searchData(), page, position);

      return foundData;
    }
    async personComData(request:ReferrerStaffSearchRequest, page:number, position:DataPosition): Promise<Page<ReferrerStaff>>{
      let data = await this.api.pageData("referrerstaff/infinite", ReferrerStaff.fromJson, page, request.count, request.search, {communityId: request.communityId, pageSize: request.count, pageNumber: page});
      // let data = await {content:[] as MarketingMaterial[]} as Page<MarketingMaterial>;
      // if (data.totalElements !== undefined && data.totalElements !== null) {
      //   this.searchTotal = data.totalElements;
      // }

      switch(position){
        case DataPosition.Top:
          this.allPeople = [...data.content, ...this.allPeople];
          break;
        case DataPosition.Bottom:
          this.allPeople = [...this.allPeople, ...data.content];
          break;
        case DataPosition.Clear:
          this.allPeople = data.content;
          break;
      }

      return data;
    }
    
    submit(){
        this.dialogRef.close(this.selectedPerson.selectedOptions.selected.map(o=>o.value));
    }
}