import { Component, computed } from "@angular/core";
import { AppService } from "../../services/app.service";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DashboardBannerCfg, DashboardService } from "../../services/dashboard.service";
import { CompanyCfgService } from "../../services/company-cfg.service";

@UntilDestroy()
@Component({
    selector: 'dashboard-banner',
    templateUrl: './dashboard.banner.html',
    styleUrls: ['./dashboard.banner.scss']
})
export class DashboardBanner {
    configIcon = faGear;
    
    cfg:DashboardBannerCfg = DashboardService.NoDashboard;
    referrerCRM = this.companyCfg.referrerCRM;
    useTemperature = computed(()=>{
        return this.companyCfg.prospectScoreType() == "Temperature";
    });
    
    constructor(public app:AppService, private dashboard:DashboardService, private snackbar:MatSnackBar, private router:Router, private companyCfg:CompanyCfgService){
        dashboard.cfg.pipe(untilDestroyed(this)).subscribe(t=>{
            if(t){
                this.cfg = t;
            }
        })
    }
    
    comingSoon(){
        this.snackbar.open("Coming Soon");
    }
}