<mat-card appearance="outlined" class="mat-elevation-z4" [matTooltip]="tooltipText">
    <mat-card-header class="subText">
        <span *ngIf="!referrerCRM()">Referrers Without Activities</span>
        <span *ngIf="referrerCRM()">Contacts Without Activities</span>
    </mat-card-header>
    <mat-card-content (click)="openList()" matRipple>
        <div echarts [options]="chartCfg" theme="hotCold" *ngIf="!loading"></div>
        <div class="centerAbsolute overChart" *ngIf="!loading">
            <mat-label>{{noActivities}}</mat-label>
        </div>
        <div class="center" *ngIf="loading">
            <load-spinner inline></load-spinner>
        </div>
    </mat-card-content>
</mat-card>