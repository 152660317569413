<mat-card appearance="outlined" class="mat-elevation-z4" [matTooltip]="tooltipText()">
    <mat-card-header class="subText">
        {{title}}
    </mat-card-header>
    <mat-card-content matRipple> 
        <div class="center" *ngIf="!loading()">
            <mat-label>
                {{value()}}
            </mat-label>
        </div>
        <div class="center" *ngIf="loading()">
            <load-spinner inline></load-spinner>
        </div>
    </mat-card-content>
</mat-card>