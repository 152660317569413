import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Observable, Subject } from 'rxjs';

export interface UserInfo {
  info: {
    sub: string
    email: string,
    name: string,
    picture: string
  }
}


@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {

  gmail = 'https://gmail.googleapis.com'
  userProfileSubject = new Subject<UserInfo>()
  googleWindow: { window: Window; } = {
    window: undefined as any
  };
  serviceType = 'google';

}
