import { Injectable, WritableSignal, signal } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Prospect, User, Referrer, ReferrerStaff, Community, Company, CompanyConfigMap, Floorplan, Organization, ReferrerCommunity, MarketingMaterial, Event, UploadFile, UploadFileReferrerCommunity } from '../models/user.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyCfgService } from './company-cfg.service';

@Injectable()
export class PersonService {
  reloadProspects: Subject<string[]>;
  reloadReferrers: Subject<string[]>;
  reloadReferrerStaff: Subject<string[]>;
  reloadProspectWidgets: Subject<string[]>;
  reloadReferrerWidgets: Subject<string[]>;
  widgetClickAction: Subject<string[]>;

  selectedProspect: ReplaySubject<Prospect|null>;
  currentUser: ReplaySubject<User|null>;
  //TODO: Experementing with new angular signals. If this works we should replace currentUser
  user: WritableSignal<User|null> = signal(null);
  currentCommunity: ReplaySubject<Community|null>;
  selectedOrganization: ReplaySubject<Organization|null>;
  selectedReferrer: ReplaySubject<Referrer|null>;
  selectedFloorplan: ReplaySubject<Floorplan|null>;
  selectedReferrerCommunity: ReplaySubject<ReferrerCommunity|null>;
  selectedReferrerStaff: ReplaySubject<ReferrerStaff|null>;
  // currentReferrerStaff: ReplaySubject<ReferrerStaff|null>;
  selectedMarketingMaterial: ReplaySubject<MarketingMaterial|null>;
  selectedUploadFile: ReplaySubject<UploadFile|null>;
  selectedUploadFileReferrerCommunity: ReplaySubject<UploadFileReferrerCommunity|null>;
  selectedEvent: ReplaySubject<Event|null>;

  companyCfg: ReplaySubject<CompanyConfigMap|null>;
  
  communities: ReplaySubject<Community[]>; 
  companies: ReplaySubject<Company[]>;
  
  constructor(private snackbar:MatSnackBar, compCfg:CompanyCfgService) {
    this.companyCfg = new ReplaySubject<CompanyConfigMap|null>(1);
    this.reloadProspects = new Subject<string[]>();
    this.reloadReferrers = new Subject<string[]>();
    this.reloadReferrerStaff = new Subject<string[]>();
    this.reloadProspectWidgets = new Subject<string[]>();
    this.reloadReferrerWidgets = new Subject<string[]>();
    this.widgetClickAction = new Subject<string[]>();
    this.selectedProspect = new ReplaySubject<Prospect|null>(1);
    this.currentUser = new ReplaySubject<User|null>(1);
    this.currentCommunity = new ReplaySubject<Community|null>(1);
    this.selectedOrganization = new ReplaySubject<Organization|null>(1);
    this.selectedReferrer = new ReplaySubject<Referrer|null>(1);
    this.selectedFloorplan = new ReplaySubject<Floorplan|null>(1);
    this.selectedReferrerCommunity = new ReplaySubject<ReferrerCommunity|null>(1);
    this.selectedReferrerStaff = new ReplaySubject<ReferrerStaff|null>(1);
    // this.currentReferrerStaff = new ReplaySubject<ReferrerStaff|null>(1);
    this.selectedMarketingMaterial = new ReplaySubject<MarketingMaterial|null>(1);
    this.selectedUploadFile = new ReplaySubject<UploadFile|null>(1);
    this.selectedUploadFileReferrerCommunity = new ReplaySubject<UploadFileReferrerCommunity|null>(1);
    this.selectedEvent = new ReplaySubject<Event|null>(1);
    // this.currentUser.subscribe(u=>this.user.set(u));

    this.communities = new ReplaySubject<Community[]>(1);
    this.companies = new ReplaySubject<Company[]>(1)
    
    this.currentCommunity.subscribe(c=>{
      if(c && c.communityId){
        localStorage.setItem("selectedCommunityId", c.communityId);
      }else{
        localStorage.removeItem('selectedCommunityId');
      }
    })
    this.companyCfg.subscribe(cfg=>{
      compCfg.cfg.set(cfg);
    });
    // this.selectedProspect.subscribe(p=>{
    //   this.prospect.set(p);
    // })
  }

  public hasSomePermission(names:string[], silent:boolean=false):boolean {
    let retVal = false;
    this.currentUser.subscribe(async user => {
      if (user?.authorities) {
        retVal = names.some(val => user?.authorities.includes(val))
      }
    });
    if (!retVal && !silent) {
      this.snackbar.open("Not permitted.");
    }
    return retVal;
  }

  public hasEveryPermission(names:string[]):boolean {
    let retVal = false;
    this.currentUser.subscribe(async user => {
      if (user?.authorities) {
        retVal = names.every(val => user?.authorities.includes(val))
      }
    });
    if (!retVal) {
      this.snackbar.open("Not permitted.");
    }
    return retVal;
  }

}