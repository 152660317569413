import { Component } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EChartsOption } from "echarts";
import { User } from "src/app/core/models/user.models";
import { CompanyCfgService } from "src/app/core/services/company-cfg.service";
import { DashboardService } from "src/app/core/services/dashboard.service";
import { GraphApiService } from "src/app/core/services/graphapi.service";
import { PersonService } from "src/app/core/services/person.service";

@UntilDestroy()
@Component({
    selector: 'no-activity-referrer-dash',
    templateUrl: './no-activity-referrer.component.html',
    styleUrls: ['../graph-widget-style.scss']
  })
  export class NoActivityReferrersDashWidget {
    noActivities:number = 0;
    count:number = 0;
    // prevMonth:number = 0;
    loading:boolean = true;
    chartCfg:EChartsOption = {};
    currentUser:User|null = null;
    referrerCRM = this.companyCfg.referrerCRM;
    
    public get tooltipText() {
      if(this.loading){
        if(this.referrerCRM()){
          return "Contacts with no activities scheduled.";
        } else {
          return "Referrers with no activities scheduled.";
        }
      }else{
        if(this.referrerCRM()){
          return (this.loading ? "" : this.noActivities + "/" + this.count + " ") + "Contacts have no activities scheduled.";
        } else {
          return (this.loading ? "" : this.noActivities + "/" + this.count + " ") + "Referrers have no activities scheduled.";
        }
      }
    }
    
    constructor(private snackbar:MatSnackBar, private graphApi:GraphApiService, private personService:PersonService, private router:Router, private companyCfg:CompanyCfgService){
      this.loading = false;
      personService.currentUser.pipe(untilDestroyed(this)).subscribe(async user=>{
        this.currentUser = user;
      });
      this.personService.reloadReferrerWidgets.pipe(untilDestroyed(this)).subscribe(async p=>{
        // Reload data when referrers are updated, since the value for this widget could change.
        if(this.currentUser?.company){
          this.loading = true;
          let resp = await graphApi.getReferrersWithoutActivities(this.currentUser.company.companyId, []); // Pass empty list for communities since they aren't used in server call anyway.

          // Only set the data if a proper value was returned.
          if(resp != null && resp.requestedCount != null && resp.comparisonCount != null) {
            this.noActivities = resp.requestedCount;
            this.count = resp.comparisonCount;
          }

          this.chartCfg = DashboardService.buildPie(this.noActivities, this.count - this.noActivities, "No Activities", true);
          this.loading = false;
        }
      });
      this.personService.reloadReferrers.pipe(untilDestroyed(this)).subscribe(async p=>{
        // Reload data when referrers are updated, since the value for this widget could change.
        if(this.currentUser?.company){
          this.loading = true;
          let resp = await graphApi.getReferrersWithoutActivities(this.currentUser.company.companyId, []); // Pass empty list for communities since they aren't used in server call anyway.

          // Only set the data if a proper value was returned.
          if(resp != null && resp.requestedCount != null && resp.comparisonCount != null) {
            this.noActivities = resp.requestedCount;
            this.count = resp.comparisonCount;
          }

          this.chartCfg = DashboardService.buildPie(this.noActivities, this.count - this.noActivities, "No Activities", true);
          this.loading = false;
        }
      });
      personService.communities.pipe(untilDestroyed(this)).subscribe(async communities =>{
        if(this.currentUser?.company){
          this.loading = true;
          let resp = await graphApi.getReferrersWithoutActivities(this.currentUser.company.companyId, communities.filter(c=>c.selected).map(c=>c.communityId));

          // Only set the data if a proper value was returned.
          if(resp != null && resp.requestedCount != null && resp.comparisonCount != null) {
            this.noActivities = resp.requestedCount;
            this.count = resp.comparisonCount;
          }

          this.chartCfg = DashboardService.buildPie(this.noActivities, this.count - this.noActivities, 'No Activities', true);
          this.loading = false;
        }
      })
    }

    
    openList(){
      // KE: 08/09/2024: Bug 47978: Clicking widgets need to filter table again.
      this.router.navigate(["referrer"], {
        queryParams: {
          referrernoactivities: true
        }
      });
      // Make small timout. So URL changes before calling the code to refresh.
      setTimeout(()=>{
        this.personService.widgetClickAction.next([]); // This will cause data to refresh.
      }, 100);
    }
  }