import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// https://github.com/angular/angular/issues/18859
@Injectable({
    providedIn: 'root'
})
export class XsrfInterceptor implements HttpInterceptor {

    constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestToForward = req;
        // console.log(req.url)
        let token = this.tokenExtractor.getToken() as string;
        // console.log(token)
        if (token !== null && (req.url.includes('docentt') || req.url.includes('localhost'))) {
            requestToForward = req.clone({ setHeaders: { "X-XSRF-TOKEN": token } });
        }
        // console.log(token)
        return next.handle(requestToForward);
    }
}