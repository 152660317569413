import { Component, input, Input } from '@angular/core';
import { MatMenu, MatMenuPanel } from '@angular/material/menu';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'floating-add-button',
  templateUrl: './floating-add-button.component.html',
  styleUrls: ['./floating-add-button.component.scss'],
//   inputs: ["person"]
})
export class FloatingAddButtonComponent {
    addIcon = faPlusCircle;
    menuPanel = input<MatMenuPanel|null>(null);
    
    // initials () : string{
    //     if(this.person){
    //         return this.person.firstname[0] + this.person.lastname[0];
    //     }else{
    //         return "";
    //     }
    // }
}