<h1 mat-dialog-title>Change Password</h1>
<mat-dialog-content>
        <form class="form-signin" method="post" [formGroup]="form">

                <mat-form-field>
                    <mat-label>Current Password</mat-label>
                    <input matInput type="password" name="code" formControlName="code" placeholder="Current Password" required [autofocus]="focus==='code'">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>New Password</mat-label>
                    <input matInput [type]="hide1 ? 'password' : 'text'" formControlName="password" name="new-password" autocomplete="new-password" placeholder="Password" required [autofocus]="focus==='pwd'">
                    <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                        <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-hint align="end" aria-live="polite">
                        {{pwdCtrl.value.length}} / {{passwordMinLength}}
                    </mat-hint>
                </mat-form-field>
                <mat-password-strength [min]="passwordMinLength" #passwordComponent class="green"
                    (onStrengthChanged)="onStrengthChanged($event)"
                    [password]="pwdCtrl.value">
                </mat-password-strength>
                <mat-form-field>
                    <mat-label>Confirm Password</mat-label>
                    <input matInput [type]="hide2 ? 'password' : 'text'" formControlName="passwordConfirm" name="confirm-password" autocomplete="new-password" placeholder="Confirm Password" required>
                    <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                        <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="pwdConfirmCtrl.invalid">{{getConfirmPwdErrorMessage()}}</mat-error>
                </mat-form-field>
           
        
                <mat-password-strength-info *ngIf="showPasswordErrors"
                  [passwordComponent]="passwordComponent">
                </mat-password-strength-info>
           
        </form>
        
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <load-spinner inline *ngIf="submitting"></load-spinner>
  <button mat-stroked-button color="primary" mat-dialog-close>Close</button>
  <button mat-flat-button color="primary" (click)="submitPassword()" [disabled]="form.invalid || submitting">Submit</button>
</div>