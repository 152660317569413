<mat-card appearance="outlined" class="mat-mr">
    <mat-card-title class="titleRow">
        <span>
            <span>Marketing Materials ({{searchTotal}})</span>
            <!-- <span matRipple [matRippleCentered]="true" [matRippleDisabled]="!currentlyPending" [class.deemphasize]="currentlyPending" class="clickable">Prospects</span> -->
            <!-- /<span matRipple [matRippleCentered]="true" [matRippleDisabled]="currentlyPending" [class.deemphasize]="!currentlyPending" (click)="switchView(true)" class="clickable">Pending</span> -->
        </span>
        <span>
        </span>
        <div class="widgetIcons">
            <fa-icon class="resize" [icon]="compressIcon" *ngIf="isExpanded" (click)="compressWidget()"></fa-icon>
            <fa-icon class="resize" [icon]="expandIcon" *ngIf="!isExpanded" (click)="expandWidget()"></fa-icon>
            <!-- <fa-icon [icon]="openIcon"></fa-icon> -->
        </div>
    </mat-card-title>
    <mat-card-content>
        <span class="flex-row search-row">
            <search-input [(filter)]="requestData" [matTooltip]="'Search for the title of the Marketing Material.'"></search-input>

            <!-- <reset-search (click)="comingSoon()"></reset-search>
            <advanced-search class="ml-auto" [sessionStorageName]="'referrerStaffAdvancedSearch'" [numberOfColumns]="4" [comingSoon]="true"></advanced-search> -->
        </span>
        <div infiniteScroll [onScroll]="onScroll" [filter]="requestData()">
            <load-spinner variant="table"></load-spinner>
            <table mat-table [dataSource]="dataSource" class="mat-mr" [trackBy]="trackMarketingMaterial">
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="multiLine">
                            <span>Title</span>
                            <span class="subText">Community</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let material" (click)="selectMarketingMaterial(material)">
                        <div>
                            <div>{{material.title}}</div>
                            <div class="clipLines subText">
                                {{this.communityName(material.communityId)}}
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="fileType">
                    <th mat-header-cell *matHeaderCellDef>File&nbsp;Type</th>
                    <td mat-cell *matCellDef="let material">
                        <div class="mobile-label">
                            File&nbsp;Type
                        </div>
                        <div>
                            {{material.fileType.name}}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="uploadUser">
                    <th mat-header-cell *matHeaderCellDef>Uploaded&nbsp;By</th>
                    <td mat-cell *matCellDef="let material">
                        <div class="mobile-label">
                            Uploaded&nbsp;By
                        </div>
                        <div>
                            {{material.uploadPerson.nameDisplay}}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="lastUpdate">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="multiLine">
                            <span>Last&nbsp;Update</span>
                            <span class="subText">Creation&nbsp;Date</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let material">
                        <div class="mobile-label">
                            <span>Last&nbsp;Update</span>
                            <span class="subText">Creation&nbsp;Date</span>
                        </div>
                        <div>
                            <div>
                                {{material.modifiedDate | date : 'shortDate'}}
                            </div>
                            <div class="subText">
                                {{material.creationDate | date : 'shortDate'}}
                            </div>
                        </div>
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="link">
                    <th mat-header-cell *matHeaderCellDef>Link</th>
                    <td mat-cell *matCellDef="let material">
                        <div class="mobile-label">
                            Link
                        </div>
                        <div>
                            @if(material.link){
                                {{material.link}}
                            }@else {
                                None
                            }
                        </div>
                    </td>
                </ng-container> -->
                <ng-container matColumnDef="allowAsEmailAttachment">
                    <th mat-header-cell *matHeaderCellDef>Email Attach</th>
                    <td mat-cell *matCellDef="let material">
                        <div class="mobile-label">
                            Email Attach
                        </div>
                        <div>
                            {{material.allowAsAttachment ? "Yes" : "No"}}
                        </div>
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="table-more">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let material">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="editMarketingMaterial(material)">
                                <fa-icon [icon]="editIcon"></fa-icon>
                                <span>Edit</span>
                            </button>
                            <button mat-menu-item (click)="deleteMarketingMaterial(material)">
                                <fa-icon [icon]="removeMaterialIcon"></fa-icon>
                                <span>Delete</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="table-edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let staff"> 
                        <fa-icon [icon]="editIcon" (click)="editReferrerStaff(staff)"></fa-icon>
                    </td>
                </ng-container> -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let material;" [class.selected]="selected()?.marketingMaterialId == material.marketingMaterialId"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                        <span>There are currently no Marketing Materials</span>
                    </td>
                </tr>
            </table>
        </div>
        <floating-add-button (clickOrKey)="newMarketingMaterial()"></floating-add-button>
        <!-- <load-spinner *ngIf="loading"></load-spinner> -->
    </mat-card-content>
    
</mat-card>