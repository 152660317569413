import { Component, ViewChild } from '@angular/core';
import { PersonService } from '../../services/person.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectionList } from '@angular/material/list';
import { Community, User } from '../../models/user.models';

@UntilDestroy()
@Component({
  selector: 'community-select-dialog',
  templateUrl: 'community-select.dialog.html',
  styleUrls: ['community-select.dialog.scss']
})
export class CommunitySelectDialog {
  public title:string = "";
  public content:string = "";
  public submitting:boolean = false;
  
  @ViewChild("selectedCommunities") selectedCommunities!: MatSelectionList;
  
  // company?:Company;
  communities:Community[] = []
  user = this.personService.user;
  
  // personId?:string;
  
  constructor(
    public dialogRef: MatDialogRef<CommunitySelectDialog>, 
    private personService:PersonService, 
    private api:ApiService,
    private snackbar:MatSnackBar
    ) {
      this.submitting = false;
      this.title = "Communities"
      // this.personService.currentUser.pipe(untilDestroyed(this)).subscribe(current=>{
      //   this.personId = current?.personId;
      //   this.company = current?.company;
      // })
      
      this.personService.communities.pipe(untilDestroyed(this)).subscribe(communities=>{
        this.communities = communities;
      });
  }

  async onSubmit() {
    let personId = this.user()?.personId;
    if(personId){
      this.submitting = true;
      let selection = this.selectedCommunities._value ? this.selectedCommunities._value : [];
      let resp = await this.api.saveCommunitiesForUser(personId, selection);
      this.personService.communities.next(resp);
      this.snackbar.open("Updated assigned Communities");
      this.submitting = false;
      this.dialogRef.close(true);
    }
  }
}