import { AfterViewInit, Directive } from '@angular/core';
import { ExpandableWidget } from './expandablewidget.component';

@Directive()
export abstract class ExpandedWidget extends ExpandableWidget implements AfterViewInit {

    override ngAfterViewInit():void {
        super.ngAfterViewInit();
        setTimeout(()=>this.expandWidget(),1);
    }
}