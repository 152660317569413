export interface LookupTableModel {
    guid:string
    name:string
    deleted:boolean
    editing?:boolean;
    lookup_order?:number;
    previousName?:string;
}

export class LookupTableUtil {
    public static sort = (a:LookupTableModel, b:LookupTableModel) => {
        if(a.lookup_order != null && b.lookup_order != null){
            return a.lookup_order - b.lookup_order;
        }else{
            return a.name.localeCompare(b.name);
        }
    };
}

export interface Relation extends LookupTableModel {
}

export interface LevelOfCare extends LookupTableModel {
    amount:number
}

export interface OtherContactType extends LookupTableModel {
}

export interface MarketingFileType extends LookupTableModel {
}

export interface Gender extends LookupTableModel{
    abbreviation:string
}

export interface Immunization extends LookupTableModel {
}

export interface ProductType extends LookupTableModel {
    score:number
}

export interface ExpectedStay extends LookupTableModel {
}

export interface MaritalStatus extends LookupTableModel {
}

export interface VeteranStatus extends LookupTableModel {
    weight:number;
}

export interface Readiness extends LookupTableModel {
    weight:number;
}

export interface Familiarity extends LookupTableModel {
    weight:number;
}

export interface LivingArrangements extends LookupTableModel {
}

export interface CareGiver extends LookupTableModel {
}

export interface Device extends LookupTableModel {
    type:any;
}

export interface MoveInTime extends LookupTableModel {
    weight:number;
    temperatureColor:string;
    temperature:"hot"|"warm"|"cold"|"";
}

export interface InquiryType extends LookupTableModel {
}

export interface DecisionCriteria extends LookupTableModel {
    score:number
}

export interface CareNeeds extends LookupTableModel {
}

export interface InterestsAndHobbies extends LookupTableModel {
}

export interface ReferralSourceCategory extends LookupTableModel {
}

export interface ReferralSource extends LookupTableModel {
    category:ReferralSourceCategory
}

export interface ReasonsAndTriggers extends LookupTableModel {
}

export interface Diagnosis extends LookupTableModel {
}

export interface FundingSource extends LookupTableModel {
}

export interface OrganizationType extends LookupTableModel {
}

export interface CloseReason extends LookupTableModel {
    notesRequired?:boolean;
    preadmit?:boolean;
}

export interface CommunityFitnessQualifiers extends LookupTableModel {
    yes_weight:number;
    no_weight:number;
    unknown_weight:number;
    lookup_order:number;
}

export interface ProspectCommunityFitnessQualifier{
    personId:string;
    lookup_guid:string;
    value:string;
}

export interface WorkflowStage extends LookupTableModel {
    order:number;
    status:"Complete"|"Partial"|"None"
    todos:StageTodo[];
}

export interface StageTodo extends LookupTableModel {
    workflowStageId:string;
    required:boolean;
}

export interface SalesActivity extends LookupTableModel {
    forProspect:boolean;
    forReferrer:boolean;
    updateLastContact:boolean;
    prospectAddCommunity:boolean;
    onlyLog:boolean;
    action:""|"text"|"email"|"emailNurses"|"phone"|"tourSurvey"|"deposit"|"note"|"appointmentNote"|"visitNote"|"meetupNote"|"unschVisitNote"
}

export interface UserRole extends LookupTableModel {
    userTypeId:string;
}

export interface ActivityCompletedReason extends LookupTableModel {
  isSuccess: boolean;
}