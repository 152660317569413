import { Component, ViewChild } from '@angular/core';
import { PersonService } from '../../services/person.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { MatSelectionList } from '@angular/material/list';
import { Company, User } from '../../models/user.models';

@UntilDestroy()
@Component({
  selector: 'company-select-dialog',
  templateUrl: 'company-select.dialog.html',
  styleUrls: ['company-select.dialog.scss']
})
export class CompanySelectDialog {
  public title:string = "";
  public content:string = "";
  public submitting:boolean = false;
  
  @ViewChild("selectedCompany") selectedCompany!: MatSelectionList;
  
  // company?:Company;
  companies:Company[] = []
  user = this.personService.user;
  
  companyId:any = "";
  // personId?:string;
  
  constructor(
    public dialogRef: MatDialogRef<CompanySelectDialog>, 
    private personService:PersonService, 
    private api:ApiService
    ) {
      this.submitting = false;
      this.title = "Companies";
      
      this.companyId = this.user()?.company.companyId;

      this.personService.companies.pipe(untilDestroyed(this)).subscribe(companies=>{
        this.companies = companies;
      });
  }

  async onSubmit() {
    let personId = this.user()?.personId;
    if(personId){
      this.submitting = true;
      let something = this.companyId;
      let resp = await this.api.updateCompanyForUser(this.companyId);
      this.submitting = false;
      if (resp != null) {
        window.location.reload();
        this.dialogRef.close(this.companyId);
      }
    }
  }
}