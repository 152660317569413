import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
  
export const fieldsMatch = (controlName: string, matchingControlName:string) : ValidatorFn => {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const control1 = formGroup.get(controlName);
        const control2 = formGroup.get(matchingControlName);
        
        if (!control1 || !control2) {
            return null;
        }
  
        if (control2.errors && !control2.errors["fieldsMismatch"]) {
            return null;
        }
  
        if (control1.value !== control2.value) {
            control2.setErrors({ fieldsMismatch: true });
            return { fieldsMismatch: true }
        } else {
            control2.setErrors(null);
            return null;
        }
      };
};
  