import { Component, Input, computed, input } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Community } from "src/app/core/models/user.models";
import { OccupancyService, OccupancySummary } from "src/app/core/services/occupancy.service";
import { PersonService } from "src/app/core/services/person.service";

@UntilDestroy()
@Component({
    selector: 'summary-stack-dash',
    templateUrl: './summary-stack.component.html',
    styleUrls: ['../graph-widget-style.scss']
  })
  export class SummaryStackDashWidget {
    @Input() topTitle!:string;
    @Input() topProp!:string;
    @Input() topPropTwo!:string;
    @Input() topPluralable:boolean = true;
    @Input() topAggregateType:"computed"|"monthly"|"yearly" = "computed";
    @Input() topTooltip!:string;
    topFormat = input<string>("");

    @Input() bottomTitle!:string;
    @Input() bottomProp!:string;
    @Input() bottomPropTwo!:string;
    @Input() bottomPluralable:boolean = true;
    @Input() bottomAggregateType:"computed"|"monthly"|"yearly" = "computed";
    @Input() bottomTooltip!:string;
    bottomFormat = input<string>("");
    
    @Input() aggregateField:boolean = false;
    @Input() mtdField:boolean = false;
    
    loading = computed(()=>{
      return this.occupancyService.loading()
    })
    tooltipTopText = computed(()=>{
      return this.tooltipText(this.loading(), this.topPluralable, this.topTitle, this.topValue(), this.topTooltip);
    });
    tooltipBottomText = computed(()=>{
      return this.tooltipText(this.loading(), this.bottomPluralable, this.bottomTitle, this.bottomValue(), this.bottomTooltip);
    });
    
    tooltipText = (loading:boolean, pluralable:boolean, title:string, value:any, text:string)=>{
      if(text){
        return text;
      }else{
        if(this.mtdField) {
          return `Day/Month Counts`;
        } else if(loading){
          return `Total ${title}s in ${this.currentCommunity?.nameDisplay}`;
        }else{
          if(pluralable){
            return `${value} ${title}(s) are in ${this.currentCommunity?.nameDisplay}`;
          }else{
            return `${value} ${title} in ${this.currentCommunity?.nameDisplay}`;
          }
        }
      }
    }
    
    currentCommunity:Community|null = null;
    
    topValue = computed(()=>{
      const summary = this.occupancyService.occupancySummary();
      let aggregate = this.occupancyService.aggregate();
      if(this.topAggregateType == "monthly"){
        aggregate = this.occupancyService.monthlyAggregate();
      }else if(this.topAggregateType == "yearly"){
        aggregate = this.occupancyService.yearlyAggregate();
      }
      return this.value(this.aggregateField ? aggregate : summary, this.topProp, this.topPropTwo);
    });
    bottomValue = computed(()=>{
      const summary = this.occupancyService.occupancySummary();
      let aggregate = this.occupancyService.aggregate();
      if(this.bottomAggregateType == "monthly"){
        aggregate = this.occupancyService.monthlyAggregate();
      }else if(this.bottomAggregateType == "yearly"){
        aggregate = this.occupancyService.yearlyAggregate();
      }
      return this.value(this.aggregateField ? aggregate : summary, this.bottomProp, this.bottomPropTwo);
    });
    
    value = (summary:any, prop:string, propTwo:string) => {
      const obj = (summary as any);
      var returnVal:any;
      if(obj && obj[prop] != null) {
        returnVal = obj[prop] as number;
      }else{
        returnVal = 0;
      }

      // Add the MTD values here for aggregate items.
      if(this.mtdField) {
        if(obj && obj[propTwo] != null) {
          returnVal += "/" + obj[propTwo] as unknown as number;
        }else {
          returnVal += "/" + 0;
        }
      }

      return returnVal;
    }
    
    constructor(
        personService:PersonService, 
        private occupancyService: OccupancyService){
      personService.currentCommunity.pipe(untilDestroyed(this)).subscribe(async community=>{
        this.currentCommunity = community;
      });
    }
  }