import { AfterContentInit, AfterViewInit, ContentChild, Directive, ElementRef, HostBinding, HostListener, Injector, Input, OnInit, Signal, ViewChild, ViewContainerRef, effect, input } from '@angular/core';
import { LoadSpinnerComponent } from '../components/load-spinner/load-spinner.component';
import { Page } from '../models/spring.models';
import { IPageable } from '../models/user.models';
import { SearchRequestData } from '../components/search-input/search-input.component';
import { DebounceService } from '../services/debounce.service';

export interface InfiniteRequestData extends SearchRequestData {
  page:IPageable|number|null
  ready:boolean
  search:string
  count:number
}
export enum DataPosition{
  Top, Bottom, Clear
}
// @UntilDestroy()
@Directive({
  selector: '[infiniteScroll]',
})
export class InfiniteScrollTableDirective implements OnInit, AfterViewInit, AfterContentInit{
    @HostBinding('class') elementClass = 'infinite-table';
    // @Input() requestSize:number = 20;
    @Input() onScroll!: (page:number, position:DataPosition) => Promise<Page<IPageable>>;
    // @Input() filter!:Signal<InfiniteRequestData>;
    filter = input.required<InfiniteRequestData>();
    
    @Input() requestScrollBuffer:number = 50;
    
    // @Input() requestScrollBufferUp:number = 5;
    
    lastScroll = 0;
    firstPage:Page<IPageable>|null = null;
    lastPage:Page<IPageable>|null = null;
    
    @ContentChild(LoadSpinnerComponent) loadingSpinner?:LoadSpinnerComponent;
    
    private _loading:boolean = false;
    get loading(){
      return this._loading;
    }
    set loading(val:boolean){
      this._loading = val;
      if(this.loadingSpinner){
        this.loadingSpinner.hidden.set(!this._loading);
      }
    }
    
    constructor(private element: ElementRef, private view: ViewContainerRef, private injector:Injector, debounce:DebounceService){
      this.element.nativeElement.scrollTop = 1;
      this.lastScroll = 1;
      this.firstPage = null;
      this.lastPage = null;
      
      // let component = this.view.createComponent(LoadSpinnerComponent);
      // this.loadingSpinner = component.instance;
      
      const callbackDebounce = debounce.from(async (page:any) => {
        // console.log(this.filter());
        if(page?.pageNumber != undefined){
          page = page.pageNumber;
        }
        if(!Number.isFinite(page)){
          page = null;
        }
            
        if(page == null){
          this.reset();
          page = 0;
          await this.reload(0, DataPosition.Clear);
        }else{
          let alreadyLoaded = false;
          page = page as number;
          if(this.firstPage != null && this.lastPage != null){
            alreadyLoaded = this.firstPage.number <= page && page <= this.lastPage.number
          }
              
          if(!alreadyLoaded){
            await this.reload(page, DataPosition.Clear);
            
            setTimeout(()=>{
              let selected = this.element.nativeElement.querySelector('.selected');
              // console.log(selected);
              if(selected && selected.offsetTop != null && selected.offsetHeight != null){
                this.lastScroll = selected.offsetTop - selected.offsetHeight;
                this.element.nativeElement.scrollTop = this.lastScroll;
              }
            })
          }
        }
      }, 200)
      effect(()=>{
        let filter = this.filter();
        if(filter.ready){
          callbackDebounce(filter.page);
        }
      })
    }
    
    ngAfterViewInit(): void {
      // this.element.nativeElement.appendChild(this.loadingSpinner);
      this.loading = this.loading;
    }
    
    ngOnInit(): void {
        this.lastScroll = 0;
    }
    
    ngAfterContentInit(): void {
        // toObservable(this.filter, {injector:this.injector}).pipe(
        //   debounceTime(100)
        // ).subscribe(v=>{
        // })
    }
    
    @HostListener("mousewheel", ["$event"]) async scrollHandler(event:any) {
        let total = this.element.nativeElement.scrollHeight - this.element.nativeElement.offsetHeight;
        let current = this.element.nativeElement.scrollTop;
        if(event.deltaY < 0){
          //Scroll up
          if(!this.loading && !this.firstPage?.first){
            let number = this.firstPage ? this.firstPage.number : 0;
            await this.reload(number - 1, DataPosition.Top);
          }
        }else if(event.deltaY > 0){
          //Scroll down
          if((total - current) < this.requestScrollBuffer && !this.loading && !this.lastPage?.last){
            let number = this.lastPage ? this.lastPage.number : 0;
            await this.reload(number + 1, DataPosition.Bottom);
          }
        }
        
        this.lastScroll = current;
    }
    
    public reset(){
      this.element.nativeElement.scrollTop = 0;
      this.lastScroll = 0;
      this.firstPage = null;
      this.lastPage = null;
    }
    
    public async reload(number:number, position:DataPosition){
      this.loading = true;
     
      try {
        const foundData = await this.onScroll(number, position);
        if (this.firstPage == null || this.lastPage == null) {
          this.firstPage = foundData;
          this.lastPage = foundData;
        } else if (foundData.pageable.pageNumber > this.lastPage.pageable.pageNumber) {
          this.lastPage = foundData;
        } else if (foundData.pageable.pageNumber < this.firstPage.pageable.pageNumber) {
          this.firstPage = foundData;
        }
        foundData.content.forEach(val => {
          val.pageNumber = number;
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
}