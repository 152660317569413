<div class="container">
    <div class="flex-row">
        <img class="center logo" src="assets/logo.png"/>
    </div>
    <mat-card appearance="outlined">
        <form class="form-signin" method="post" [formGroup]="form">
            <mat-card-content>
                <h2>Activate Account</h2>
                <mat-form-field>
                    <mat-label>Activation Code</mat-label>
                    <input matInput type="text" name="code" formControlName="code" placeholder="Activation Code" required [autofocus]="focus==='code'">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input matInput type="text" name="username" autocomplete="username" formControlName="username" placeholder="Username" required [autofocus]="focus==='user'">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide1 ? 'password' : 'text'" formControlName="password" name="new-password" autocomplete="new-password" placeholder="Password" required [autofocus]="focus==='pwd'">
                    <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                        <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-hint align="end" aria-live="polite">
                        {{pwdCtrl.value.length}} / {{passwordMinLength}}
                    </mat-hint>
                    <mat-error *ngIf="pwdCtrl.invalid">{{getConfirmPwdErrorMessage()}}</mat-error>
                </mat-form-field>
                <mat-password-strength [min]="passwordMinLength" #passwordComponent class="green"
                    (onStrengthChanged)="onStrengthChanged($event)"
                    [password]="pwdCtrl.value">
                </mat-password-strength>
                <mat-form-field>
                    <mat-label>Confirm Password</mat-label>
                    <input matInput [type]="hide2 ? 'password' : 'text'" formControlName="passwordConfirm" name="confirm-password" autocomplete="new-password" placeholder="Confirm Password" required/>
                    <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                        <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="pwdConfirmCtrl.invalid">{{getConfirmPwdErrorMessage()}}</mat-error>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions align="end">
                <load-spinner inline *ngIf="submitting"></load-spinner>
                <button mat-flat-button color="accent" type="submit" [disabled]="form.invalid || submitting" (click)="submitPassword()">Activate</button>
            </mat-card-actions>
            <mat-card-footer>
                <mat-password-strength-info *ngIf="showPasswordErrors"
                  [passwordComponent]="passwordComponent">
                </mat-password-strength-info>
            </mat-card-footer>
        </form>
        
    </mat-card>
</div>