<mat-card appearance="outlined" class="mat-mr">
    <mat-card-title class="titleRow">
        <span>
            <span>Aggregate Tables</span>
        </span>
        <!-- <span></span>
        <div class="widgetIcons">
            <fa-icon [icon]="expandIcon"></fa-icon>
        </div> -->
    </mat-card-title>
    <mat-card-content>
        <search-input [(filter)]="requestData"></search-input>
        <mat-selection-list multiple="false" hideSingleSelectionIndicator="true">
            <!-- <ng-template > -->
                <mat-list-option *ngFor="let item of (lookupTables | sortAndFilterByName:requestData().reverse:requestData().search)" (click)="viewTable(item)" [class.selected]="isSelected(item)">
                    <span>{{item.name}}</span>
                    <mat-divider inset="true"></mat-divider>
                </mat-list-option>
            <!-- </ng-template> -->
        </mat-selection-list>
    </mat-card-content>
    
</mat-card>