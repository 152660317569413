import { Component, AfterViewInit, Output, EventEmitter, Input, ɵunwrapWritableSignal, signal, WritableSignal } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { PersonService } from '../../services/person.service';
import { LookupTableReference } from 'src/app/view/utilities/lookuptables/lookupview.component';
import { InfiniteRequestData } from '../../directives/infinite-scroll-table.directive';
import { SearchRequestData } from '../../components/search-input/search-input.component';

interface LookupTableSearch extends SearchRequestData{
  reverse:boolean
}
@Component({
  selector: 'lookup-table-list-widget',
  templateUrl: './lookuptablelist.widget.html',
  styleUrls: ['./lookuptablelist.widget.scss']
})
export class LookupTableListWidget {
  @Input() lookupTables !: LookupTableReference[];
  selected:LookupTableReference|null = null;

  // @Output() filterChange =  new EventEmitter<LookupTableReference>();
  @Output() selectedTableChanged=  new EventEmitter<LookupTableReference>();
  requestData:WritableSignal<LookupTableSearch> = signal({ search: "", reverse: false, page:null, ready: true});
  
  viewTable(tableRef:LookupTableReference){
    this.selected = tableRef;
    this.selectedTableChanged.emit(tableRef);
  }
  
  isSelected(tableRef:LookupTableReference){
    return this.selected != null && this.selected.name === tableRef.name;
  }
}