<mat-form-field [formGroup]="parentForm" appearance="fill" [class.disabled]="disabled" [class.required-invalid]="required && selectedData.length < 1">
    <mat-label *ngIf="label" [class.required-label]="required">{{label}}</mat-label>
    <mat-chip-grid #chipList>
        <mat-chip-row *ngFor="let item of selectedData" (removed)="remove(item)" (auxclick)="onChipMiddleClick($event, item)">
            <span class="emphItem" *ngIf="emphasisField && item && item[emphasisField]" [matTooltip]="item[emphasisField]">
                {{item[emphasisField]}}
            </span>
            <span class="dispItem">
                {{item? item[displayField] : ""}}
            </span>
            <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
        </mat-chip-row>
        
        <input [placeholder]="placeholder + (required ? '*' : '')" #searchInput (focus)="showPanel()" [class.required-label]="required"
            formControlName="search" [matAutocomplete]="auto" [matChipInputFor]="chipList" (blur)="tryAdd($event, auto)"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event, auto)">
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [attr.disabled]="disabled">
        <mat-option class="selectRecipient" *ngFor="let item of disabled ? [] : filteredData" [value]="item[idField]">
            <span class="emphItem" *ngIf="emphasisField && item && item[emphasisField]">
                {{item[emphasisField]}}
            </span>
            <span class="dispItem">
                {{item? item[displayField] : ""}}
            </span>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>