import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { FindCommunitiesViewComponent } from './findcommunitiesview.component';
import { FindCommunitiesList } from 'src/app/core/widgets/findcommunities/findcommunitieslist.widget';


const routes:Routes = [
  { path: '', component: FindCommunitiesViewComponent,
  children: [
    {
      path: '',
      component: FindCommunitiesList,
      // data:{showAll:true}
    }
  ]}
]


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FindCommunitiesRoutingModule { }