import { Component, Input } from '@angular/core';

@Component({
  selector: 'change-display',
  template: '<span [ngClass]="getColor(count, threshold)"><span>{{getDisplay(count)}}</span></span>',
  styleUrls: ['./change-display.component.scss']
})
export class ChangeDisplayComponent {
    @Input() count!:number;
    @Input() threshold:number = 0;
    
    colorClassCount = 10;
    
    getValue(score:number, threshold:number){
      if(score > threshold){
        return "Hot";
      }if(score < -threshold){
        return "Cool";
      }else{
        return "Warm";
      }
    }
    
    getDisplay(score:number){
      if(score > 0){
        return "+" + score;
      }if(score < 0){
        return score;
      }else{
        return "=" + score;
      }
    }
    
    getColor (score:number, threshold:number) {
        let val = this.getValue(score, threshold);
        return "type" + val;
    }
}