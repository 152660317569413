<mat-card appearance="outlined" class="mat-mr">
    <mat-card-title class="titleRow">
        <span>
            <span>Events <span *ngIf="searchTotal != null">({{searchTotal}})</span></span>
            <!-- <span matRipple [matRippleCentered]="true" [matRippleDisabled]="!currentlyPending" [class.deemphasize]="currentlyPending" class="clickable">Prospects</span> -->
            <!-- /<span matRipple [matRippleCentered]="true" [matRippleDisabled]="currentlyPending" [class.deemphasize]="!currentlyPending" (click)="switchView(true)" class="clickable">Pending</span> -->
        </span>
        <span>
        </span>
        <div class="widgetIcons">
            <fa-icon class="resize" [icon]="compressIcon" *ngIf="isExpanded" (click)="compressWidget()"></fa-icon>
            <fa-icon class="resize" [icon]="expandIcon" *ngIf="!isExpanded" (click)="expandWidget()"></fa-icon>
            <!-- <fa-icon [icon]="openIcon"></fa-icon> -->
        </div>
    </mat-card-title>
    <mat-card-content>
        <span class="flex-row search-row">
            <search-input [(filter)]="requestData" [matTooltip]="'Search for the name of the Event.'"></search-input>

            <!-- <reset-search (click)="comingSoon()"></reset-search>
            <advanced-search class="ml-auto" [sessionStorageName]="'referrerStaffAdvancedSearch'" [numberOfColumns]="4" [comingSoon]="true"></advanced-search> -->
        </span>
        <div infiniteScroll [onScroll]="onScroll" [filter]="requestData()">
            <load-spinner variant="table"></load-spinner>
            <table mat-table [dataSource]="dataSource" class="mat-mr" [trackBy]="trackEvent">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="multiLine">
                            <span>Name</span>
                            <span class="subText">Community</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let item" (click)="selectEvent(item)">
                        <div>
                            <div>{{item.name}}</div>
                            <div class="clipLines subText">
                            @if(item.community != null){
                                {{item.community.nameDisplay}}
                            }@else {
                                All Communities
                            }
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let item">
                        <div class="mobile-label">
                            Date
                        </div>
                        <div>
                            {{item.eventDate | date : 'shortDate': item.timzone}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef>Time</th>
                    <td mat-cell *matCellDef="let item">
                        <div class="mobile-label">
                            Time
                        </div>
                        <div>
                            {{item.timeOnlyStartDisplay}}
                            <span *ngIf="(item.startTime !== null && item.startTime !== undefined) && (item.endTime !== null && item.endTime !== undefined)">-</span>
                            {{item.timeOnlyEndDisplay}}
                            <!-- <span *ngIf="(item.startTime !== null && item.startTime !== undefined) && (item.endTime !== null && item.endTime !== undefined) && (item.timezone !== null && item.timezone !== undefined)">{{item.timezone}}</span> -->
                            
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="budget">
                    <th mat-header-cell *matHeaderCellDef>Budget</th>
                    <td mat-cell *matCellDef="let item">
                        <div class="mobile-label">
                            Budget
                        </div>
                        <div>
                            <span *ngIf="item.budget != null && item.budget != undefined">{{item.budget | mask:"separator.2" : {thousandSeparator:",", decimalMarker: '.', prefix:"$"} }}</span>
                            <span *ngIf="!(item.budget != null && item.budget != undefined)">None</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actualCost">
                    <th mat-header-cell *matHeaderCellDef>Actual Cost</th>
                    <td mat-cell *matCellDef="let item">
                        <div class="mobile-label">
                            Actual Cost
                        </div>
                        <div>
                            <span *ngIf="item.actualCost != null && item.actualCost != undefined">{{item.actualCost | mask:"separator.2" : {thousandSeparator:",", decimalMarker: '.', prefix:"$"} }}</span>
                            <span *ngIf="!(item.actualCost != null && item.actualCost != undefined)">None</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="host">
                    <th mat-header-cell *matHeaderCellDef>Host</th>
                    <td mat-cell *matCellDef="let item">
                        <div class="mobile-label">
                            Host
                        </div>
                        <div>
                            <span>{{getPersonName(item.salesCounselor)}}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef>Location</th>
                    <td mat-cell *matCellDef="let item">
                        <div class="mobile-label">
                            Location
                        </div>
                        <div>
                            <span *ngIf="item.location != null && item.location != undefined">{{item.location}}</span>
                            <span *ngIf="!(item.location != null && item.location != undefined)">None</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="invitees">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                    <td mat-cell *matCellDef="let item">
                        <button mat-menu-item (click)="comingSoon()" [matTooltip]="'Invitees'"  style="width:50px">
                            <fa-icon [icon]="inviteeIcon"></fa-icon>
                        </button>
                    </td>
                </ng-container>      
                
                <ng-container matColumnDef="table-more">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let material">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <!-- <button mat-menu-item (click)="comingSoon()">
                                <fa-icon [icon]="inviteeIcon"></fa-icon>
                                <span>Send Invite</span>
                            </button> -->
                            <button mat-menu-item (click)="editEvent(material)">
                                <fa-icon [icon]="editIcon"></fa-icon>
                                <span>Edit</span>
                            </button>
                            <button mat-menu-item (click)="deleteEvent(material)">
                                <fa-icon [icon]="removeMaterialIcon"></fa-icon>
                                <span>Delete</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="table-edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let staff"> 
                        <fa-icon [icon]="editIcon" (click)="editReferrerStaff(staff)"></fa-icon>
                    </td>
                </ng-container> -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let material;" [class.selected]="selected()?.eventId == material.eventId"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                        <span>There are currently no Event</span>
                    </td>
                </tr>
            </table>
        </div>
        <floating-add-button (clickOrKey)="newEvent()"></floating-add-button>
        <!-- <load-spinner *ngIf="loading"></load-spinner> -->
    </mat-card-content>
    
</mat-card>